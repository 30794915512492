import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  paper: {
    alignSelf: 'flex-start',
    marginTop: '1em',
    marginLeft: '1em',
    padding: '1em',
  },
}));

export default useStyles;