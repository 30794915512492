// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import api from './../api/api';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
  LOAD_ACTIVE_USERS,
  SHOW_USER_INFO,
  HIDE_USER_INFO
} from './../constants/actionTypes';

// ------------------------------------------------------------------------ //
// Get active users
// ------------------------------------------------------------------------ //
export const getActiveUsers = () => {
  return (dispatch) => { 
    api.getActiveUsers()
      .then(function(response) {
        return dispatch({
          type: LOAD_ACTIVE_USERS,
          payload: response
        });
      });
  };
}

// ------------------------------------------------------------------------ //
// Get active users
// ------------------------------------------------------------------------ //
export const showUserInfo = (userId) => {
  return (dispatch) => { 
    api.getUserInfo(userId)
      .then(function(response) {
        return dispatch({
          type: SHOW_USER_INFO,
          payload: response
        });
      });
  };
}

export const hideUserInfo = () => {
  return (dispatch) => dispatch({
    type: HIDE_USER_INFO,
    payload: null
  });
}