import React, { useState, useContext } from 'react';
import { Typography, FormControlLabel, TextField, Button, Checkbox, CircularProgress } from '@material-ui/core';
import { ActivityTypeSelect } from '../globalComponents/ActivityTypeSelect';
import { TimezoneSelect } from '../globalComponents/TimezoneSelect';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import api from '../../api/api';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import {ActivityGroupSelect} from "../globalComponents/ActivityGroupSelect";

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  button: {
    marginTop: '0.5em',
  },
  title: {
    marginTop: '0.5em',
  },
  rowTimezone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  rowDateTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  colInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginRight: '1em',
  },
  inputTimezone: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '1em',
  },
  colDuration: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));

export const InputsManualActivity = (props) => {
  const { reload, setReload } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [activityGroupId, setActivityGroupId] = useState('');
  const [activityTypeId, setActivityTypeId] = useState('');
  const [specialActivityName, setSpecialActivityName] = useState('');
  const [timezoneId, setTimezoneId] = useState('');
  const [reinforcement, setReinforcement] = useState(false);
  const [startedAt, setStartedAt] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));
  const [endedAt, setEndedAt] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));

  const [manualHoursDuration, setManualHoursDuration] = useState(0);
  const [manualMinutesDuration, setManualMinutesDuration] = useState(0);
  const [negativeDurationAlert, setNegativeDurationAlert] = useState(false);

  const handleActivityTypeId = (event) => {
    setActivityTypeId(event.target.value);
  };

  const handleSpecialActivityName = (event) => {
    setSpecialActivityName(event.target.value);
  };

  const handleStart = (event) => {
    const selectedDateTime = new Date(event.target.value);
    const currentDateTime = new Date();
    if (selectedDateTime > currentDateTime) {
      alert('Vous ne pouvez pas choisir une date et une heure futures !');
      event.target.value = currentDateTime.toISOString().substring(0, 16);
    } else {
      setNegativeDurationAlert(false);
      const value = event.target.value;
      const end = dayjs(endedAt);
      const start = dayjs(value);
      const durationMinutes = end.diff(start, 'minute');

      if (durationMinutes < 0) {
        setNegativeDurationAlert(true);
      }
      var hours = Math.floor(durationMinutes / 60);
      var minutes = durationMinutes % 60;
      setManualHoursDuration(hours);
      setManualMinutesDuration(minutes);
      setStartedAt(value);
    }
  };

  const handleEnd = (event) => {
    const selectedDateTime = new Date(event.target.value);
    const currentDateTime = new Date();
    if (selectedDateTime > currentDateTime) {
      alert('Vous ne pouvez pas choisir une date et une heure futures !');
      event.target.value = currentDateTime.toISOString().substring(0, 16);
    } else {
      setNegativeDurationAlert(false);
      const value = event.target.value;
      const start = dayjs(startedAt);
      const end = dayjs(value);
      const durationMinutes = end.diff(start, 'minute');

      if (durationMinutes < 0) {
        setNegativeDurationAlert(true);
      }
      var hours = Math.floor(durationMinutes / 60);
      var minutes = durationMinutes % 60;
      setManualHoursDuration(hours);
      setManualMinutesDuration(minutes);
      setEndedAt(value);
    }
  };

  const handleTimezoneId = (event) => {
    setTimezoneId(event.target.value);
  };

  const handleReinforcement = (event) => {
    setReinforcement(event.target.checked);
  };

  const handleSave = async () => {
    const manual = true;
    const started_at = dayjs.tz(startedAt, 'Europe/Paris').unix();
    const ended_at = dayjs.tz(endedAt, 'Europe/Paris').unix();
    setIsLoading(true);
    try {
      await api.postActivity({
        startedAt: started_at,
        endedAt: ended_at,
        manual: manual,
        activityGroupId: activityGroupId,
        activityTypeId: activityTypeId,
        specialActivityName: specialActivityName,
        timezoneId: timezoneId,
        reinforcement: reinforcement,
      });
      setIsLoading(false);
      setStartedAt(dayjs().format('YYYY-MM-DDTHH:mm'));
      setEndedAt(dayjs().format('YYYY-MM-DDTHH:mm'));
      setActivityTypeId('');
      setSpecialActivityName('');
      setTimezoneId('');
      setReinforcement(false);
      setManualMinutesDuration(0);
      setManualHoursDuration(0);
      setReload(!reload);
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSave();
      } else {
        console.log({ error });
        setErrorAPI(true);
      }
    }
  };

  const maxDateTime = new Date().toISOString().substring(0, 16);

  return (
    <div className={classes.column}>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          "Un problème est survenu lors de l'enregistrement de l'activité, veuillez recharger la page ou réessayer plus tard."
        }
      />

      <Typography variant="h5" className={classes.title}>
        Saisir manuellement une activité
      </Typography>

      {/* SELECT GROUP */}
      <ActivityGroupSelect
        handleActivityGroupId={(event) => {
          setActivityGroupId(event.target.value);
        }}
        activityGroupId={activityGroupId}
      />

      <ActivityTypeSelect activityTypeId={activityTypeId} handleActivityTypeId={handleActivityTypeId} />
      {activityTypeId === 4 && (
        <TextField
          margin="dense"
          id="activityType"
          variant="outlined"
          label="Intitulé de l'activité"
          value={specialActivityName}
          onChange={handleSpecialActivityName}
          fullWidth
        />
      )}
      <div className={classes.rowTimezone}>
        <div className={classes.inputTimezone}>
          <TimezoneSelect timezoneId={timezoneId} handleTimezoneId={handleTimezoneId} />
        </div>
        <FormControlLabel
          control={
            <Checkbox checked={reinforcement} onChange={handleReinforcement} name="reinforcement" color="primary" />
          }
          label="Activité de renfort"
        />
      </div>
      <div className={classes.rowDateTime}>
        <div className={classes.colInput}>
          <TextField
            id="ActivityStart"
            label="Début de l'activté"
            type="datetime-local"
            margin="dense"
            variant="outlined"
            value={startedAt}
            onChange={handleStart}
            inputProps={{
              max: maxDateTime,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="ActivityEnd"
            label="Fin de l'activité"
            type="datetime-local"
            margin="dense"
            variant="outlined"
            inputProps={{
              max: maxDateTime,
            }}
            value={endedAt}
            onChange={handleEnd}
            error={negativeDurationAlert}
            helperText={negativeDurationAlert ? 'Fin inférieure au début' : ' '}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.colDuration}>
          {!negativeDurationAlert && manualHoursDuration !== 0 && manualMinutesDuration !== 0 && (
            <Typography variant="h6">
              soit {manualHoursDuration} h {manualMinutesDuration} min
            </Typography>
          )}
          {!negativeDurationAlert && manualHoursDuration !== 0 && manualMinutesDuration === 0 && (
            <Typography variant="h6">soit {manualHoursDuration} h</Typography>
          )}
          {!negativeDurationAlert && manualHoursDuration === 0 && (
            <Typography variant="h6">soit {manualMinutesDuration} min</Typography>
          )}
        </div>
      </div>

      {!isLoading ? (
        <Button
          variant="contained"
          margin="dense"
          color="secondary"
          fullWidth
          className={classes.button}
          disabled={negativeDurationAlert || !activityTypeId || !timezoneId}
          onClick={handleSave}
        >
          Enregistrer la saisie d'activité
        </Button>
      ) : (
        <CircularProgress className={classes.loading} />
      )}
    </div>
  );
};
