// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import api from './../api/api';
import dayjs from 'dayjs';
import Auth from './../services/Auth';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
    LOAD_TODAY_ACTIVITIES,
    LOAD_IN_PROGRESS_ACTIVITY,
    STOP_ACTIVITY, 
    START_ACTIVITY
} from './../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  Start current user activity
// ------------------------------------------------------------------------ //
export const getInProgressActivity = () => {
  return (dispatch) => { 
    api.getInProgressActivity()
      .then(function(response) {
        return dispatch({
          type: LOAD_IN_PROGRESS_ACTIVITY,
          payload: response
        });
      });
  };
}



// ------------------------------------------------------------------------ //
//  getInProgressActivity
// ------------------------------------------------------------------------ //
export const getTodayActivities = () => {
    return (dispatch) => { 
        const { user } = Auth.user();
        
        const params = {
            userId: user.id,
            startedAt: dayjs().tz('Europe/Paris').hour(0).minute(0).second(0).unix(),
            endedAt: dayjs().tz('Europe/Paris').hour(23).minute(59).second(59).unix(),
        };
        
        api.getActivitiesUser(params)
            .then(function(response) {
                return dispatch({
                    type: LOAD_TODAY_ACTIVITIES,
                    payload: response
                });
            });
    };
}

// ------------------------------------------------------------------------ //
//  Stop activity
// ------------------------------------------------------------------------ //
export const stopActivity = (id) => {

  const { user } = Auth.user();

  const params = {
    id: id,
    end: dayjs().unix()
  };
  
  return (dispatch) => { 
    api.patchActivity(params)
      .then(function(response) {

        api.putUserInfo({ 
          userId: user.id, 
          statusId: 1
        });

        return dispatch({
          type: STOP_ACTIVITY,
          payload: response
        });
      });
  };
}

// ------------------------------------------------------------------------ //
//  Start activity
// ------------------------------------------------------------------------ //
export const startActivity = (typeId, groupId, specialName, timezoneId, isReinforcment, isGuest) => {
  const { user } = Auth.user();

  const params = {
    startedAt: dayjs().unix(),
    manual: false,
    activityTypeId: typeId,
    activityGroupId : groupId,
    specialActivityName: specialName,
    timezoneId: timezoneId,
    reinforcement: isReinforcment,
    guest: isGuest,
  };
  
  return (dispatch) => { 
    api.postActivity(params)
      .then(function(response) {

        api.putUserInfo({ 
          userId: user.id, 
          statusId: 3 
        });

        return api.getInProgressActivity()
          .then(function(response) {
            return dispatch({
              type: START_ACTIVITY,
              payload: response
            });
          });
      });
  };
}


