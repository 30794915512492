import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { frFR } from '@material-ui/core/locale';
import App from './App';
import SignIn from './pages/SignIn';
import { Invitations } from './pages/Invitations';
import reportWebVitals from './reportWebVitals';
import store from './stores/store';
import { Provider } from 'react-redux';
import Auth from './services/Auth';
import * as Sentry from '@sentry/react';

// ------------------------------------------------------------------------ //
// FONT AWESOME
// ------------------------------------------------------------------------ //
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", process.env.REACT_APP_API_URL],
    }),
    new Sentry.Replay(),
      ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

});

// ------------------------------------------------------------------ //
// App Root component
// ------------------------------------------------------------------ //
const Root = () => isChrome ? (
  <Provider store={store}>
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <App />
        </PrivateRoute>
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/invitations" component={Invitations} />
      </Switch>
    </Router>
  </Provider>
) : 'Le tableau de board est pour l\'instant seulement disponible pour le navigateur Google Chrome';


// ------------------------------------------------------------------ //
// PrivateRoute component
// ------------------------------------------------------------------ //
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isAuthentificated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signIn",
              state: {
                from: location
              }
            }}
          />
        )
      }
    />
  );
}

// ------------------------------------------------------------------ //
// Configuration of the theme (?)
// ------------------------------------------------------------------ //
const theme = createTheme({
  palette: {
    primary: {
      main: '#213759',
    },
    secondary: {
      main: '#F8CC03',
    },
  },
}, frFR);


// ------------------------------------------------------------------ //
// RENDER
// ------------------------------------------------------------------ //
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Root />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

