import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
  Paper,
  Button,
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ErrorAPI } from '../components/globalComponents/ErrorAPI';
import { Loading } from '../components/globalComponents/Loading';
import refreshFunctionContext from '../context/refreshFunctionContext';
import BasicsContext from '../context/BasicsContext';
import api from '../api/api';
import useStyles from './styles/Settings';


export const Settings = (props) => {
  const classes = useStyles();
  const { setSettings } = props;
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [infoUser, setInfoUser] = useState();
  const [state, setState] = useState({
    alarmAll: true,
    alarmOnlyMe: false,
    alarmNoSound: false,
  });
  const { alarmAll, alarmOnlyMe, alarmNoSound } = state;

  useEffect(() => {
    const getUserInfo = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getUserInfo(user.id);
        if (response) {
          setInfoUser(response.user);
          setState({
            alarmAll: response.user.alarm_all,
            alarmOnlyMe: response.user.alarm_only_me,
            alarmNoSound: response.user.alarm_no_sound,
          });
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getUserInfo();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getUserInfo();
  }, [user, refresh]);

  const handleBack = () => {
    setSettings(false);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleSave = async (event) => {
    setErrorAPI(false);
    try {
      await api.putUserInfo({
        userId: user.id,
        alarmAll,
        alarmOnlyMe,
        alarmNoSound,
      });
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSave();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  //TODO : modification infos user ?
  //TODO : modification matière user ?
  //TODO : création de groupe de travail pour admin ?

  return (
    <div className={classes.column}>
      <div className={classes.row}>
        <IconButton aria-label="back" onClick={handleBack}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography variant="h4" className={classes.title}>
          Paramètres
        </Typography>
      </div>
      {infoUser ? (
        <Paper elevation={3} className={classes.paper}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gestion des alertes</FormLabel>
            <ErrorAPI
              errorAPI={errorAPI}
              setErrorAPI={setErrorAPI}
              message={
                "Un problème est survenu lors du chargement des préférences d'alarmes, veuillez recharger la page ou réessayer plus tard."
              }
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={alarmAll} color="primary" onChange={handleChange} name="alarmAll" />}
                label="M'alerter si une nouvelle requête arrive"
              />
              <FormControlLabel
                control={<Checkbox checked={alarmOnlyMe} color="primary" onChange={handleChange} name="alarmOnlyMe" />}
                label="M'alerter uniquement si cette requête me concerne"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={alarmNoSound} color="primary" onChange={handleChange} name="alarmNoSound" />
                }
                label="Alerte visuelle uniquement (pas sonore)"
              />
            </FormGroup>
            <Button variant="contained" margin="dense" color="secondary" onClick={handleSave}>
              Valider mes alarmes
            </Button>
          </FormControl>
        </Paper>
      ) : (
        <Loading />
      )}
    </div>
  );
};
