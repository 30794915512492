import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export const AlertSOS = (props) => {
  const { title, message, alertSOS, handleCloseAlertSOS, type, position } = props;

  return (
    <Snackbar
      open={alertSOS !== null}
      anchorOrigin={{ vertical: 'top', horizontal: position }}
      autoHideDuration={60000}
      onClose={handleCloseAlertSOS}
    >
      <Alert severity={type} variant="filled" onClose={handleCloseAlertSOS}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
