// ------------------------------------------------------------------------ //
//  INQUIRIES
// ------------------------------------------------------------------------ //
export const LOAD_WAITING_SWITCHMAN_INQUIRIES = 'LOAD_WAITING_SWITCHMAN_INQUIRIES';
export const LOAD_WITH_SWITCHMAN_INQUIRIES = 'LOAD_WITH_SWITCHMAN_INQUIRIES';
export const LOAD_WAITING_TEACHER_INQUIRIES = 'LOAD_WAITING_TEACHER_INQUIRIES';
export const LOAD_WITH_TEACHER_INQUIRIES = 'LOAD_WITH_TEACHER_INQUIRIES';
export const PROCESS_BROADCASTED_INQUIRY = 'PROCESS_BROADCASTED_INQUIRY';

export const LOAD_DIRECT_INQUIRIES = 'LOAD_DIRECT_INQUIRIES';
export const LOAD_PROCESSED_INQUIRIES = 'LOAD_PROCESSED_INQUIRIES';

// ------------------------------------------------------------------------ //
//  MEMO
// ------------------------------------------------------------------------ //
export const LOAD_USER_MEMO = 'LOAD_USER_MEMO';
export const CREATE_USER_MEMO = 'CREATE_USER_MEMO';
export const UPDATE_USER_MEMO = 'UPDATE_USER_MEMO';
export const DELETE_USER_MEMO = 'DELETE_USER_MEMO';

// ------------------------------------------------------------------------ //
//  USER
// ------------------------------------------------------------------------ //
export const LOAD_ACTIVE_USERS = 'LOAD_ACTIVE_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_IS_ACTIVE_USER = 'SET_IS_ACTIVE_USER';
export const SHOW_USER_INFO = 'SHOW_USER_INFO';
export const HIDE_USER_INFO = 'HIDE_USER_INFO';

// ------------------------------------------------------------------------ //
//  SUMMARY
// ------------------------------------------------------------------------ //
export const LOAD_SUMMARY_PROCESSED_INQUIRIES = 'LOAD_SUMMARY_PROCESSED_INQUIRIES';
export const LOAD_SUMMARY_DIRECT_INQUIRIES = 'LOAD_SUMMARY_DIRECT_INQUIRIES';

// ------------------------------------------------------------------------ //
//  ACTIVITIES
// ------------------------------------------------------------------------ //
export const LOAD_TODAY_ACTIVITIES = 'LOAD_TODAY_ACTIVITIES';
export const SET_REFRESHED_AT_ACTIVITIES = 'SET_REFRESHED_AT_ACTIVITIES';
export const LOAD_IN_PROGRESS_ACTIVITY = 'LOAD_IN_PROGRESS_ACTIVITY';
export const STOP_ACTIVITY = 'STOP_ACTIVITY';
export const START_ACTIVITY = 'START_ACTIVITY';
export const LOAD_RUNNING_ACTIVITIES = 'LOAD_RUNNING_ACTIVITIES';
