import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    alignSelf: 'stretch',
    marginRight: '1em',
    padding: '1em',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  input: {
    minWidth: 150,
  },
  margin: {
    marginRight: '1em',
  },
  title: {
    textAlign: 'center',
  },
}));

export const CalculationBox = () => {
  const classes = useStyles();
  const [nbUser, setNbUser] = useState(0);
  const [nbSlot, setNbSlot] = useState(0);
  const [quotient, setQuotient] = useState(0);
  const [reste, setReste] = useState(0);

  const handleUser = (event) => {
    setNbUser(event.target.value);
    if (event.target.value > 0 && nbSlot > 0) {
      setQuotient(Math.floor(nbSlot / event.target.value));
      setReste(nbSlot % event.target.value);
    } else {
      setQuotient(0);
      setReste(0);
    }
  };

  const handleSlot = (event) => {
    setNbSlot(event.target.value);
    if (event.target.value > 0 && nbUser > 0) {
      setQuotient(Math.floor(event.target.value / nbUser));
      setReste(event.target.value % nbUser);
    } else {
      setQuotient(0);
      setReste(0);
    }
  };

  return (
    <>
      <Paper elevation={1} className={classes.paper}>
        <Typography variant="h6" className={classes.title}>
          Calcul des répartitions
        </Typography>
        <div className={classes.row}>
          <TextField
            type="number"
            id="user"
            margin="dense"
            variant="outlined"
            value={nbUser}
            onChange={handleUser}
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            label="Nb d'enseignants"
            fullWidth
          />
          <div className={classes.margin} />
          <TextField
            type="number"
            id="slots"
            variant="outlined"
            margin="dense"
            value={nbSlot}
            onChange={handleSlot}
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            label="Nb de créneaux"
            fullWidth
          />
        </div>
        {reste === 0 ? (
          <Typography variant="subtitle2" className={classes.title}>
            {nbUser} invités {quotient} fois
          </Typography>
        ) : (
          <Typography variant="subtitle2" className={classes.title}>
            {reste} invité {quotient + 1} fois et {nbUser - reste} invités {quotient} fois
          </Typography>
        )}
      </Paper>
    </>
  );
};
