// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import api from './../api/api';
import Auth from './../services/Auth';
import dayjs from 'dayjs';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
  LOAD_USER_MEMO,
  CREATE_USER_MEMO,
  UPDATE_USER_MEMO,
  DELETE_USER_MEMO
} from './../constants/actionTypes';

// ------------------------------------------------------------------------ //
// GET CURRENT USER MEMO
// ------------------------------------------------------------------------ //
export const getUserMemo = () => {
  return (dispatch) => { 
    const { user } = Auth.user();

    api.getUserMemo(user.id).then(function(response) {
      return dispatch({
        type: LOAD_USER_MEMO,
        payload: response
      });
    });
  };
}

// ------------------------------------------------------------------------ //
// CREATE 
// ------------------------------------------------------------------------ //
export const createUserMemo = (date, description) => {
  return (dispatch) => { 
    const { user } = Auth.user();

    api.createUserMemo(user.id, dayjs.tz(date, 'Europe/Paris').unix(), description)
      .then(function(response) {
        return dispatch({
          type: CREATE_USER_MEMO,
          payload: response.data
        });
      });
  };
}

// ------------------------------------------------------------------------ //
// UPDATE 
// ------------------------------------------------------------------------ //
export const updateUserMemo = (id, date, description) => {
  return (dispatch) => { 
    api.updateUserMemo(id, dayjs.tz(date, 'Europe/Paris').unix(), description)
      .then(function(response) {
        return dispatch({
          type: UPDATE_USER_MEMO,
          payload: response.data
        });
      });
  };
}

// ------------------------------------------------------------------------ //
// DELETE 
// ------------------------------------------------------------------------ //
export const deleteUserMemo = (id) => {
  return (dispatch) => { 
    api.deleteUserMemo(id)
      .then(function(response) {
        return dispatch({
          type: DELETE_USER_MEMO,
          payload: {
            id: id
          }
        });
      });
  };
}
