import React, { useState, useEffect, useCallback, useContext } from 'react';
import api from '../api/api';
import { ErrorAPI } from '../components/globalComponents/ErrorAPI';
import { SimpleToolbar } from '../components/invitation/SimpleToolbar';
import { TimeSlotsCreationModal } from '../components/invitation/TimeSlotsCreationModal';
import { TimeSlotsDeleteModal } from '../components/invitation/TimeSlotsDeleteModal';
import { DayCalendar } from '../components/invitation/DayCalendar';
import { WeekInvitationTable } from '../components/invitation/WeekInvitationTable';
import { Inputs } from '../components/invitation/Inputs';
import { AvailableTeacherTable } from '../components/invitation/AvailableTeacherTable';
import { InvitedTeacherTable } from '../components/invitation/InvitedTeacherTable';
import { TeacherList } from '../components/invitation/TeacherList';
import { CommentBox } from '../components/invitation/CommentBox';
import { CalculationBox } from '../components/invitation/CalculationBox';
import refreshFunctionContext from '../context/refreshFunctionContext';
import dayjs from 'dayjs';
import { Loading } from '../components/globalComponents/Loading';
import useStyles from './styles/Invitations';

export const Invitations = () => {
  const classes = useStyles();
  const [tokenModified, setTokenModified] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [timeSlotId, setTimeSlotId] = useState('');
  const [selectedSlot, setSelectedSlot] = useState();
  const [subjectId, setSubjectId] = useState(1);
  const [levelId, setLevelId] = useState(0);
  const [workzoneId, setWorkzoneId] = useState(0);
  const [subjects, setSubjects] = useState('');
  const [levels, setLevels] = useState('');
  const [workzones, setWorkzones] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [reload, setReload] = useState(false);
  const [reloadSlot, setReloadSlot] = useState(false);
  const [reloadEvents, setReloadEvents] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [openSlots, setOpenSlots] = useState();
  const [activeHourSelected, setActiveHourSelected] = useState(false);

  const refreshToken = useCallback(async () => {
    try {
      const response = await api.getNewToken();
      if (response) {
        localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN, response.access_token);
        localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN, response.refresh_token);
        setTokenModified((tokenModified) => !tokenModified);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error.response);
      setErrorAPI(true);
    }
  }, []);

  useEffect(() => {
    const getLists = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getBasics();
        if (response) {
          setSubjects(response.subjects);
          setLevels(response.level_groups);
          setWorkzones(response.timezones);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getLists();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getLists();
    setTokenModified(false);
    setErrorAPI(false);
  }, [tokenModified, refresh]);

  useEffect(() => {
    const getOpenSlots = async () => {
      setErrorAPI(false);

      try {
        const slotDate = dayjs(date).format('YYYY-MM-DD');
        const response = await api.getOpenSlots({
          startDate: dayjs.tz(slotDate, 'Europe/Paris').hour(0).minute(0).second(0).unix(),
          endDate: dayjs.tz(slotDate, 'Europe/Paris').hour(23).minute(59).second(59).unix(),
        });
        if (response) {
          const slots = response.map((slot) => {
            slot.start = dayjs(slot.started_at).toDate();
            slot.end = dayjs(slot.ended_at).toDate();
            return slot;
          });
          setOpenSlots(slots);
          if (slots[0]) {
            setTimeSlotId(slots[0].id);
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getOpenSlots();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };
    getOpenSlots();
  }, [refresh, date, reloadSlot]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <refreshFunctionContext.Provider value={refreshToken}>
      <div>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu dans le tableau de bord Prof Express, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        <SimpleToolbar activeHourSelected={activeHourSelected} setActiveHourSelected={setActiveHourSelected} />
        <div className={classes.drawerHeader} />

        {openSlots ? (
          <div className={classes.inputRow}>
            <Inputs
              date={date}
              setDate={setDate}
              timeSlotId={timeSlotId}
              setTimeSlotId={setTimeSlotId}
              subjectId={subjectId}
              setSubjectId={setSubjectId}
              levelId={levelId}
              setLevelId={setLevelId}
              workzoneId={workzoneId}
              setWorkzoneId={setWorkzoneId}
              subjects={subjects}
              levels={levels}
              workzones={workzones}
              openModal={openModal}
              setOpenModal={setOpenModal}
              openSlots={openSlots}
            />
          </div>
        ) : (
          <Loading />
        )}
        {date && subjectId && openSlots && (
          <div className={classes.row}>
            <div className={classes.userColumn}>
              <TeacherList
                date={date}
                subjectId={subjectId}
                levelId={levelId}
                workzoneId={workzoneId}
                reload={reload}
              />
            </div>
            <div className={classes.userColumn}>
              <DayCalendar
                setOpenModal={setOpenDeleteModal}
                date={date}
                slotId={timeSlotId}
                setSlotId={setTimeSlotId}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                userId={selectedUserId}
                reloadEvents={reloadEvents}
                openSlots={openSlots}
              />
            </div>
            <div className={classes.invitColumn}>
              <div className={classes.row}>
                <div className={classes.invitColumn}>
                  <WeekInvitationTable
                    date={date}
                    setDate={setDate}
                    subjectId={subjectId}
                    levelId={levelId}
                    workzoneId={workzoneId}
                    reload={reload}
                  />
                  <div className={classes.rowComment}>
                    <CalculationBox />
                    <CommentBox />
                  </div>
                </div>
              </div>
              {subjectId && date && selectedSlot && (
                <div className={classes.rowInvit}>
                  <div className={classes.leftTable}>
                    <AvailableTeacherTable
                      date={date}
                      selectedSlot={selectedSlot}
                      subjectId={subjectId}
                      levelId={levelId}
                      workzoneId={workzoneId}
                      subjects={subjects}
                      levels={levels}
                      workzones={workzones}
                      reload={reload}
                      setReload={setReload}
                      setUserId={setSelectedUserId}
                      reloadEvents={reloadEvents}
                      setReloadEvents={setReloadEvents}
                    />
                  </div>
                  <div className={classes.rightTable}>
                    <InvitedTeacherTable
                      date={date}
                      selectedSlot={selectedSlot}
                      subjectId={subjectId}
                      levelId={levelId}
                      workzoneId={workzoneId}
                      subjects={subjects}
                      levels={levels}
                      workzones={workzones}
                      reload={reload}
                      setReload={setReload}
                      setUserId={setSelectedUserId}
                      reloadEvents={reloadEvents}
                      setReloadEvents={setReloadEvents}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <TimeSlotsCreationModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          reloadSlot={reloadSlot}
          setReloadSlot={setReloadSlot}
        />
        <TimeSlotsDeleteModal
          openModal={openDeleteModal}
          handleCloseModal={handleCloseDeleteModal}
          slotId={timeSlotId}
          setSlotId={setTimeSlotId}
          date={date}
          reloadSlot={reloadSlot}
          setReloadSlot={setReloadSlot}
          openSlots={openSlots}
        />
      </div>
    </refreshFunctionContext.Provider>
  );
};
