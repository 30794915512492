import Auth from './../services/Auth';
import dayjs from 'dayjs';
import { Inquiry } from './../models/Inquiry';
import { get } from 'lodash'

// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import {
  LOAD_WAITING_SWITCHMAN_INQUIRIES,
  LOAD_WITH_SWITCHMAN_INQUIRIES,
  LOAD_WAITING_TEACHER_INQUIRIES,
  LOAD_WITH_TEACHER_INQUIRIES,
  PROCESS_BROADCASTED_INQUIRY,
  LOAD_DIRECT_INQUIRIES,
  LOAD_PROCESSED_INQUIRIES
} from '../constants/actionTypes';


// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
  direct: {
    waitingSwitchman: [],
    withSwitchman: [],
    waitingTeacher: [],
    withTeacher: [],
  },
  processed: [],
};


// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {

  switch (action.type) {

    case LOAD_PROCESSED_INQUIRIES:
      return {
        ...state,
        processed: action.payload.map((inquiry) => {
          return parseProcessedInquiry(inquiry);
        }),
      };

    case LOAD_DIRECT_INQUIRIES:
      let inquiries = {
        waitingSwitchman: [],
        withSwitchman: [],
        waitingTeacher: [],
        withTeacher: [],
      };

      action.payload.map((obj) => {
        const inquiry = new Inquiry(obj);
        const status = inquiry.getStatusType();
        
        if(status === 'WAITING_TEACHER') {
          inquiries.waitingTeacher.push(parseInquiry(inquiry.get()));
        }

        if(status === 'WITH_TEACHER') {
          inquiries.withTeacher.push(parseInquiry(inquiry.get()));
        }

        if(status === 'WITH_SWITCHMAN') {
          inquiries.withSwitchman.push(parseInquiry(inquiry.get()));
        }

        if(status === 'WAITING_SWITCHMAN') {
          inquiries.waitingSwitchman.push(parseInquiry(inquiry.get()));
        }

        return null;
      });

      return {
        ...state,
        direct: inquiries
      };

    // ------------------------------------------------------------------------ //
    // PROCESS RECEIVED INQUIRY FROM BROADCAST
    // ------------------------------------------------------------------------ //
    case PROCESS_BROADCASTED_INQUIRY:
      const inquiry = new Inquiry(action.payload);
      const status = inquiry.getStatusType();

      console.log('********* BROACASTED INQUIRY *********', status);
      console.log('PAYLOAD', action.payload);
      console.log('STATUS', status);


      if(status === 'WAITING_TEACHER') {
        let waitingTeacher = [...state.direct.waitingTeacher].filter(o => o.id !== inquiry.getId());
        waitingTeacher.push(parseInquiry(inquiry.get()));

        let waitingSwitchman = [...state.direct.waitingSwitchman].filter(o => o.id !== inquiry.getId());
        let withSwitchman = [...state.direct.withSwitchman].filter(o => o.id !== inquiry.getId());
        let withTeacher = [...state.direct.withTeacher].filter(o => o.id !== inquiry.getId());

        return {
          ...state,
          direct: {
            waitingTeacher: waitingTeacher,
            waitingSwitchman: waitingSwitchman,
            withSwitchman: withSwitchman,
            withTeacher: withTeacher
          }
        };
      }

      if(status === 'WITH_TEACHER') {
        let withTeacher = [...state.direct.withTeacher].filter(o => o.id !== inquiry.getId());
        withTeacher.push(parseInquiry(inquiry.get()));

        let waitingSwitchman = [...state.direct.waitingSwitchman].filter(o => o.id !== inquiry.getId());
        let withSwitchman = [...state.direct.withSwitchman].filter(o => o.id !== inquiry.getId());
        let waitingTeacher = [...state.direct.waitingTeacher].filter(o => o.id !== inquiry.getId());

        return {
          ...state,
          direct: {
            waitingTeacher: waitingTeacher,
            waitingSwitchman: waitingSwitchman,
            withSwitchman: withSwitchman,
            withTeacher: withTeacher
          }
        };
      }

      if(status === 'WITH_SWITCHMAN') {
        let withSwitchman = [...state.direct.withSwitchman].filter(o => o.id !== inquiry.getId());
        withSwitchman.push(parseInquiry(inquiry.get()));

        let waitingSwitchman = [...state.direct.waitingSwitchman].filter(o => o.id !== inquiry.getId());
        let withTeacher = [...state.direct.withTeacher].filter(o => o.id !== inquiry.getId());
        let waitingTeacher = [...state.direct.waitingTeacher].filter(o => o.id !== inquiry.getId());

        return {
          ...state,
          direct: {
            waitingTeacher: waitingTeacher,
            waitingSwitchman: waitingSwitchman,
            withSwitchman: withSwitchman,
            withTeacher: withTeacher
          }
        };
      }

      if(status === 'WAITING_SWITCHMAN') {
        let waitingSwitchman = [...state.direct.waitingSwitchman].filter(o => o.id !== inquiry.getId());
        waitingSwitchman.push(parseInquiry(inquiry.get()));

        let withSwitchman = [...state.direct.withSwitchman].filter(o => o.id !== inquiry.getId());
        let withTeacher = [...state.direct.withTeacher].filter(o => o.id !== inquiry.getId());
        let waitingTeacher = [...state.direct.waitingTeacher].filter(o => o.id !== inquiry.getId());

        return {
          ...state,
          direct: {
            waitingTeacher: waitingTeacher,
            waitingSwitchman: waitingSwitchman,
            withSwitchman: withSwitchman,
            withTeacher: withTeacher
          }
        };
      }

      if(status === 'SUCCESS') {
        let withSwitchman = [...state.direct.withSwitchman].filter(o => o.id !== inquiry.getId());
        let withTeacher = [...state.direct.withTeacher].filter(o => o.id !== inquiry.getId());
        let waitingTeacher = [...state.direct.waitingTeacher].filter(o => o.id !== inquiry.getId());
        let waitingSwitchman = [...state.direct.waitingSwitchman].filter(o => o.id !== inquiry.getId());

        return {
          ...state,
          direct: {
            waitingTeacher: waitingTeacher,
            waitingSwitchman: waitingSwitchman,
            withSwitchman: withSwitchman,
            withTeacher: withTeacher
          }
        };
      }

      return {
        ...state
      };


    // ------------------------------------------------------------------------ //
    // LOAD FROM API
    // ------------------------------------------------------------------------ //
    case LOAD_WAITING_SWITCHMAN_INQUIRIES:
      return {
        ...state,
        waitingSwitchman: action.payload.map((inquiry) => {
          return parseInquiry(inquiry);
        }),
      };

    case LOAD_WITH_SWITCHMAN_INQUIRIES:
      return {
        ...state,
        withSwitchman: action.payload.map((inquiry) => {
          return parseInquiry(inquiry);
        }),
      };

    case LOAD_WAITING_TEACHER_INQUIRIES:
      return {
        ...state,
        waitingTeacher: action.payload.map((inquiry) => {
          return parseInquiry(inquiry);
        }),
      };

    case LOAD_WITH_TEACHER_INQUIRIES:
      return {
        ...state,
        withTeacher: action.payload.map((inquiry) => {
          return parseInquiry(inquiry);
        }),
      };

    // ------------------------------------------------------------------------ //
    // DEFAULT ACTION REDUCER
    // ------------------------------------------------------------------------ //
    default:
      return state;
  }
};


// ------------------------------------------------------------------------ //
//  HELPERS
// ------------------------------------------------------------------------ //
// FIXME : move to model ?
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const parseInquiry = (inquiry) => {

  const basics = Auth.basics();

  inquiry.studentInfo = capitalize(inquiry.student.first_name) + ' ' + capitalize(inquiry.student.last_name);
  inquiry.switchmanInfo = inquiry.switchman !== null ? capitalize(inquiry.switchman.first_name) + ' ' + capitalize(inquiry.switchman.last_name) : '';
  inquiry.teacherInfo = inquiry.teacher !== null ? capitalize(inquiry.teacher.first_name) + ' ' + capitalize(inquiry.teacher.last_name) : '';
  inquiry.type = get(basics.inquiry_types.find((x) => x.id === inquiry.type_id), 'name');
  inquiry.level_name = basics.levels.find((x) => x.id === inquiry.level_id).name;
  inquiry.level_group = basics.levels.find((x) => x.id === inquiry.level_id).group.name;
  inquiry.preferred_call_time = inquiry.preferred_call_start_time + '-' + inquiry.preferred_call_end_time;
  inquiry.subject = inquiry.subject_id !== 0 ? basics.subjects.find((x) => x.id === inquiry.subject_id).name : 'non renseigné';
  // inquiry.submitted_at = dayjs.tz(inquiry.submitted_at, 'Europe/Paris').format('YYYY-DD-MM HH:mm');

  if (inquiry.type_id === 1) {
    inquiry.expiration = 'créée ' + dayjs(inquiry.submitted_at).tz('Europe/Paris').fromNow();
  } else if (inquiry.type_id === 2) {

    let submissionDate =
      dayjs(inquiry.submitted_at).day() >= inquiry.delivery_day_id
        ? dayjs(inquiry.submitted_at).tz('Europe/Paris').add(7, 'day').day(inquiry.delivery_day_id)
        : dayjs(inquiry.submitted_at).tz('Europe/Paris').day(inquiry.delivery_day_id);

    inquiry.expiration =
      dayjs(submissionDate).fromNow().charAt(0) === 'i'
        ? 'EN RETARD'
        : 'à rendre ' + dayjs(submissionDate).fromNow();
  }

  return inquiry;
};


const parseProcessedInquiry = (inquiry) => {

  const basics = Auth.basics();
  inquiry.studentInfo = capitalize(inquiry.student.first_name) + ' ' + capitalize(inquiry.student.last_name);
  inquiry.switchmanInfo = inquiry.switchman !== null ? capitalize(inquiry.switchman.first_name) + ' ' + capitalize(inquiry.switchman.last_name) : '';
  inquiry.teacherInfo = inquiry.teacher ? capitalize(inquiry.teacher.first_name) + ' ' + capitalize(inquiry.teacher.last_name) : '';
  inquiry.type = basics.inquiry_types.find((x) => x.id === inquiry.type_id).name;
  inquiry.level_name = basics.levels.find((x) => x.id === inquiry.level_id).name;
  inquiry.level_group = basics.levels.find((x) => x.id === inquiry.level_id).group.name;

  if (inquiry.subject_id !== 0) {
    let subject = basics.subjects.find((x) => x.id === inquiry.subject_id);

    inquiry.subject = subject ? subject.name : 'non renseigné';
  } else {
    inquiry.subject = 'non renseigné';
  }

  if (inquiry.picked_by_switchman_time < 60) {
    inquiry.picked_by_switchman_time = inquiry.picked_by_switchman_time + 's';
  } else if (inquiry.picked_by_switchman_time >= 60 && inquiry.picked_by_switchman_time < 3600) {
    if (inquiry.picked_by_switchman_time % 60 !== 0) {
      inquiry.picked_by_switchman_time = Math.floor(inquiry.picked_by_switchman_time / 60) + 'm ' + (inquiry.picked_by_switchman_time % 60) + 's';
    } else {
      inquiry.picked_by_switchman_time = Math.floor(inquiry.picked_by_switchman_time / 60) + 'm';
    }
  }

  if (inquiry.picked_by_teacher_time < 60) {
    inquiry.picked_by_teacher_time = inquiry.picked_by_teacher_time + 's';
  } else if (inquiry.picked_by_teacher_time >= 60 && inquiry.picked_by_teacher_time < 3600) {
    if (inquiry.picked_by_teacher_time % 60 !== 0) {
      inquiry.picked_by_teacher_time = Math.floor(inquiry.picked_by_teacher_time / 60) + 'm ' + (inquiry.picked_by_teacher_time % 60) + 's';
    } else {
      inquiry.picked_by_teacher_time = Math.floor(inquiry.picked_by_teacher_time / 60) + 'm';
    }
  }

  if (inquiry.succeed_time < 60) {
    inquiry.succeed_time = inquiry.succeed_time + 's';
  } else if (inquiry.succeed_time >= 60 && inquiry.succeed_time < 3600) {
    if (inquiry.succeed_time % 60 !== 0) {
      inquiry.succeed_time = Math.floor(inquiry.succeed_time / 60) + 'm ' + (inquiry.succeed_time % 60) + 's';
    } else {
      inquiry.succeed_time = Math.floor(inquiry.succeed_time / 60) + 'm';
    }
  }

  return inquiry;
};


export default reducer;
