import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin: {
    marginTop: '1em',
    marginRight: '1em',
    marginBottom: '1em',
  },
  marginS: {
    marginTop: '1em',

    marginBottom: '1em',
    marginRight: '0.2em',
  },
}));

export const ModificationModal = (props) => {
  const { openModal, handleCloseModal, event } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [startEvent, setStartEvent] = useState(dayjs(event.start).format('YYYY-MM-DDTHH:mm'));
  const [endEvent, setEndEvent] = useState(dayjs(event.end).format('YYYY-MM-DDTHH:mm'));
  const [status, setStatus] = useState(event.status_id === 1 ? 'available' : 'reserved');
  const [reinforcement, setReinforcement] = useState(event.reinforcement === 1 ? true : false);
  const [alertMoreThanADay, setAlertMoreThanADay] = useState(false);
  const [alertNegativeDuration, setAlertNegativeDuration] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);

  const weekDay = () => {
    switch (dayjs(startEvent).weekday()) {
      case 0:
        return 'lundi';

      case 1:
        return 'mardi';

      case 2:
        return 'mercredi';

      case 3:
        return 'jeudi';

      case 4:
        return 'vendredi';

      case 5:
        return 'samedi';

      case 6:
        return 'dimanche';

      default:
        return 'dimanche';
    }
  };

  const handleStart = (event) => {
    const start = dayjs(event.target.value);
    const end = dayjs(endEvent);

    if (end.format('DD/MM/YYYY') !== start.format('DD/MM/YYYY')) {
      setAlertMoreThanADay(true);
    } else {
      setAlertMoreThanADay(false);
    }

    if (end.diff(start) <= 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setStartEvent(event.target.value);
  };

  const handleEnd = (event) => {
    const start = dayjs(startEvent);
    const end = dayjs(event.target.value);

    if (end.format('DD/MM/YYYY') !== start.format('DD/MM/YYYY')) {
      setAlertMoreThanADay(true);
    } else {
      setAlertMoreThanADay(false);
    }

    if (end.diff(start) <= 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setEndEvent(event.target.value);
  };

  const handleStatus = (props) => {
    setStatus(props);
  };

  const handleReinforcement = (event) => {
    setReinforcement(event.target.checked);
  };

  const handleModifyAndClose = async () => {
    const id = event.id;
    const startDate = dayjs.tz(startEvent, 'Europe/Paris').unix();
    const endDate = dayjs.tz(endEvent, 'Europe/Paris').unix();
    const statusId = status === 'available' ? 1 : 2;
    const reinfInt = reinforcement ? 1 : 0;

    try {
      const response = await api.putEvent({
        id: id,
        startDate: startDate,
        endDate: endDate,
        statusId: statusId,
        reinfInt: reinfInt,
      });
      if (response) {
        handleCloseModal();
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleModifyAndClose();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  const handleDeleteAndClose = async () => {
    const id = event.id;

    try {
      const response = await api.deleteEvent(id);
      if (response) {
        handleCloseModal();
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleDeleteAndClose();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Modifier ma disponibilité du {weekDay()} {dayjs(startEvent).format('DD MMMM')}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu lors de la modification de la disponibilité, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        {alertMoreThanADay && (
          <Alert
            onClose={() => {
              setAlertMoreThanADay(false);
            }}
            severity="warning"
          >
            <AlertTitle>Attention</AlertTitle>
            Une disponibilité ne peut pas s'étendre sur plusieurs jours. Veuillez utiliser l'outil de répétition pour
            les disponibilités récurrentes.
          </Alert>
        )}
        {alertNegativeDuration && (
          <Alert
            onClose={() => {
              setAlertNegativeDuration(false);
            }}
            severity="warning"
          >
            <AlertTitle>Attention</AlertTitle>
            La fin de votre disponibilité est inférieure au début de celle ci.
          </Alert>
        )}
        <DialogContent dividers>
          <div className={classes.row}>
            <TextField
              id="start"
              label="Début"
              type="datetime-local"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.margin}
              value={dayjs(startEvent).format('YYYY-MM-DDTHH:mm')}
              onChange={handleStart}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="end"
              label="Fin"
              type="datetime-local"
              margin="dense"
              variant="outlined"
              fullWidth
              value={dayjs(endEvent).format('YYYY-MM-DDTHH:mm')}
              onChange={handleEnd}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <Divider />
          <div className={classes.row}>
            <Button
              variant={status === 'available' ? 'contained' : 'outlined'}
              color={status === 'available' ? 'secondary' : 'primary'}
              margin="dense"
              className={classes.margin}
              onClick={() => handleStatus('available')}
            >
              Disponible
            </Button>
            <Button
              variant={status === 'reserved' ? 'contained' : 'outlined'}
              color={status === 'reserved' ? 'secondary' : 'primary'}
              margin="dense"
              onClick={() => handleStatus('reserved')}
            >
              Reserviste
            </Button>
            <FormControlLabel
              control={
                <Checkbox checked={reinforcement} onChange={handleReinforcement} name="reinforcement" color="primary" />
              }
              label="Renfort"
              className={classes.margin}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAndClose} color="primary">
            Supprimer cette disponibilité
          </Button>
          <Button onClick={handleModifyAndClose} disabled={alertMoreThanADay || alertNegativeDuration} color="primary">
            Modifier cette disponibilité
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
