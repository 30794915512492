import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rowDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  spacing: {
    marginRight: '2em',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export const DeleteModal = (props) => {
  const { openModal, handleCloseModal, activity } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);

  const handleSaveAndClose = async () => {
    try {
      await api.deleteActivity(activity[0]);
      handleCloseModal();
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSaveAndClose();
      } else {
        setErrorAPI(true);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Supprimer l'activité suivante :
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={'Un problème est survenu lors de la suppression, veuillez recharger la page ou réessayer plus tard.'}
        />
        <DialogContent dividers>
          {activity && (
            <DialogContentText>
              {activity[8]} du {dayjs(activity[1]).format('DD/MM/YYYY')} de {dayjs(activity[1]).format('HH:mm')} à{' '}
              {dayjs(activity[2]).format('HH:mm')}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSaveAndClose} color="primary">
            Supprimer définitivement
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
