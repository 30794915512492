import React, { useState, useEffect, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import dayjs from 'dayjs';
import { ModificationModal } from './ModificationModal';
import { DeleteModal } from './DeleteModal';
import BasicsContext from '../../context/BasicsContext';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  button: {},
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export const TableJournalUser = (props) => {
  const classes = useStyles();
  const { userId, date } = props;
  const basics = useContext(BasicsContext);
  const timezones = basics ? basics.timezones : null;
  const activityTypes = basics ? basics.board_activity_types : null;
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [openModificationModal, setOpenModificationModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState();
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'started_at',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'ended_at',
      label: 'Heure de fin',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'started_at',
      label: 'Heure de début',
      options: {
        filter: false,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('HH:mm'),
      },
    },

    {
      name: 'duration',
      label: 'Durée',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'manual',
      label: 'Type de saisie',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? (value = 'Manuelle') : (value = 'Directe')),
      },
    },
    {
      name: 'activity_type_id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'special_activity_name',

      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'activity_type',
      label: 'Intitulé',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'timezone_id',
      label: 'Zone de travail',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => timezones.find((x) => x.id === value).display_name,
      },
    },
    {
      name: 'reinforcement',
      label: 'Renfort',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'modify',
      label: 'Modifier',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              disabled={tableMeta.rowData[4] === 'en cours'}
              className={classes.button}
              onClick={() => {
                handleModificationModal(tableMeta);
              }}
            >
              <EditIcon />
            </Button>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Supprimer',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              disabled={tableMeta.rowData[4] === 'en cours'}
              className={classes.button}
              onClick={() => {
                handleDeleteModal(tableMeta);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    const getMonthActivityUserData = async () => {
      try {
        const response = await api.getActivitiesUser({
          userId: userId,
          startedAt: dayjs.tz(date, 'Europe/Paris').hour(0).minute(0).second(0).date(1).unix(),
          endedAt: dayjs.tz(date, 'Europe/Paris').hour(23).minute(59).second(59).date(31).unix(),
        });

        if (response) {
          const monthActivities = response.map((activity) => {
            const end = dayjs(activity.ended_at);
            const start = dayjs(activity.started_at);
            if (activity.ended_at === null) {
              activity.duration = 'en cours';
            } else {
              const duration = end.diff(start, 'minute');
              var hours = Math.floor(duration / 60);
              var minutes = duration % 60;
              if (hours === 0) {
                activity.duration = minutes + 'min';
              } else if (minutes === 0) {
                activity.duration = hours + 'h';
              } else {
                activity.duration = hours + 'h' + minutes;
              }
            }

            if (activity.activity_type_id === 4) {
              activity.activity_type = activity.special_activity_name;
            } else {
              const actionType = activityTypes.find((x) => x.id === activity.activity_type_id);
              if (actionType) {
                activity.activity_type = actionType.name;
              }
            }

            return activity;
          });
          setData(monthActivities);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getMonthActivityUserData();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getMonthActivityUserData();
  }, [userId, openModificationModal, openDeleteModal, refresh, activityTypes, date]);

  const handleModificationModal = (tableMeta) => {
    setSelectedRow(tableMeta.rowData);
    setOpenModificationModal(true);
  };

  const handleCloseModificationModal = (event) => {
    setOpenModificationModal(false);
    setSelectedRow();
  };

  const handleDeleteModal = (tableMeta) => {
    setSelectedRow(tableMeta.rowData);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (event) => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
        }
      />
      <Table title={"Journal d'activité"} data={data} columns={columns} />
      {selectedRow && (
        <ModificationModal
          openModal={openModificationModal}
          handleCloseModal={handleCloseModificationModal}
          activity={selectedRow}
        />
      )}
      <DeleteModal openModal={openDeleteModal} handleCloseModal={handleCloseDeleteModal} activity={selectedRow} />
    </>
  );
};

export const TableJournalGlobal = (props) => {
  const { userId, date } = props;
  const [errorAPI, setErrorAPI] = useState(false);
  const [data, setData] = useState();
  const refresh = useContext(refreshFunctionContext);
  const basics = useContext(BasicsContext);
  const timezones = basics ? basics.timezones : null;
  const activityTypes = basics ? basics.board_activity_types : null;

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'user_id',
      label: 'Utilisateur',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'started_at',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'started_at',
      label: 'Heure de début',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('HH:mm'),
      },
    },
    {
      name: 'duration',
      label: 'Durée',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'manual',
      label: 'Type de saisie',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? (value = 'Manuelle') : (value = 'Directe')),
      },
    },
    {
      name: 'activity_type',
      label: 'Intitulé',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'timezone_id',
      label: 'Zone de travail',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => timezones.find((x) => x.id === value).display_name,
      },
    },
    {
      name: 'reinforcement',
      label: 'Renfort',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
  ];

  useEffect(() => {
    const getMonthActivityAllData = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getActivitiesUser({
          userId: 0,
          startedAt: dayjs.tz(date, 'Europe/Paris').hour(0).minute(0).second(0).date(1).unix(),
          endedAt: dayjs.tz(date, 'Europe/Paris').hour(23).minute(59).second(59).date(31).unix(),
        });

        if (response) {
          const monthActivities = response.map((activity) => {
            const end = dayjs(activity.ended_at);
            const start = dayjs(activity.started_at);
            const duration = end.diff(start, 'minute');
            var hours = Math.floor(duration / 60);
            var minutes = duration % 60;
            if (hours === 0) {
              activity.duration = minutes + 'min';
            } else if (minutes === 0) {
              activity.duration = hours + 'h';
            } else {
              activity.duration = hours + 'h' + minutes;
            }

            if (activity.activity_type_id === 4) {
              activity.activity_type = activity.special_activity_name;
            } else {
              activity.activity_type = activityTypes.find((x) => x.id === activity.activity_type_id).name;
            }

            return activity;
          });
          setData(monthActivities);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getMonthActivityAllData();
        } else {
          setErrorAPI(true);
          console.log(error);
        }
      }
    };

    getMonthActivityAllData();
  }, [userId, refresh, activityTypes, date]);

  return (
    <>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
        }
      />
      <Table title={'Journal de tous les utilisateurs'} data={data} columns={columns} />
    </>
  );
};
