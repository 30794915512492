import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ActivityTypeSelect } from './ActivityTypeSelect';
import { makeStyles } from '@material-ui/core/styles';
import { GroupUserList } from './GroupUserList';

// ------------------------------------------------------------------------ //
// REDUX
// ------------------------------------------------------------------------ //
import {
  getActiveUsers,
  showUserInfo
} from './../../actions/users';

// ------------------------------------------------------------------------ //
// SYLES
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));


// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
const TDBDrawer = (props) => {

  const {
    getActiveUsers,
    activeUsers,
    showUserInfo
  } = props;

  const [typeId, setTypeId] = useState(1);
  const classes = useStyles();

  return (
    <div>
      {/*<div className={classes.row}>*/}
      {/*  <Tooltip title="Rafraichir">*/}
      {/*    <IconButton aria-label="refresh" onClick={() => getActiveUsers()}>*/}
      {/*      <RefreshIcon />*/}
      {/*    </IconButton>*/}
      {/*  </Tooltip>*/}
      {/*</div>*/}

      <GroupUserList
        activeUsers={activeUsers}
        typeId={typeId}
        onUserClick={(id) => {
          showUserInfo(id);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUsers: state.users.activeUsers
});

const mapDispatchToProps = dispatch => {
  return {
    getActiveUsers: () => dispatch(getActiveUsers()),
    showUserInfo: (userId) => dispatch(showUserInfo(userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TDBDrawer);
