import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Avatar,
  Toolbar, 
  Typography,
  Tooltip
} from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { TimzezoneHourSelect } from '../mainPage/TimezoneHourSelect';
import logoPE from '../../assets/logoPE.png';
import Auth from './../../services/Auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  avatarActive: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  avatarInactive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    flexGrow: 1,
    marginLeft: '1em',
  },
  logo: { height: '64px' },
}));

export const SimpleToolbar = (props) => {
  const classes = useStyles();
  const { 
    activeUser, 
    activeHourSelected, 
    setActiveHourSelected 
  } = props;
  const basics = Auth.basics();
  const user = basics ? basics.user : null;
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);


  const handleToggleMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar color="primary">
          <img src={logoPE} alt="Logo" className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            Gestion des invitations
          </Typography>

          <ScheduleIcon fontSize="large" />
          <TimzezoneHourSelect activeHourSelected={activeHourSelected} setActiveHourSelected={setActiveHourSelected} />

          {user && (
            <Tooltip title={user.first_name + ' ' + user.last_name + ' - ' + user.role}>
              <Avatar
                className={activeUser ? classes.avatarActive : classes.avatarInactive}
                ref={anchorRef}
                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggleMenu}
              >
                {user.first_name.charAt(0)}
                {user.last_name.charAt(0)}
              </Avatar>
            </Tooltip>
          )}

        </Toolbar>
      </AppBar>
    </>
  );
};
