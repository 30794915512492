import React, { 
  useState, 
  useEffect, 
  useCallback 
} from 'react';
import { 
  CssBaseline, 
  FormControlLabel, 
  Checkbox 
} from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';
import api from './api/api';
import { Settings } from './pages/Settings';
import dayjs from 'dayjs';
import useSound from 'use-sound';
import alertSoundShort from './sounds/alert_short.mp3';
import BasicsContext from './context/BasicsContext';
import refreshFunctionContext from './context/refreshFunctionContext';
import { UserInfoModal } from './components/mainPage/UserInfoModal';
import TDBToolbar from './components/mainPage/Toolbar';
import { TDBTabs } from './components/mainPage/Tabs';
import AvailabilityBlock from './components/mainPage/AvailabilityBlock';
import { ErrorAPI } from './components/globalComponents/ErrorAPI';
import { AlertUser } from './components/globalComponents/AlertUser';
import { AlertSOS } from './components/globalComponents/AlertSOS';
import Auth from './services/Auth';
import Broadcast from './services/Broadcast';
import useStyles from './App.style.js';

// ------------------------------------------------------------------------ //
// REDUX
// ------------------------------------------------------------------------ //
import {
  processBroadcastedInquiry,
  getDirectInquiries
} from './actions/inquiries';

import { 
  getUserMemo
} from './actions/memo';


import { 
  setIsUserActive,
} from './actions/user';

import { 
  getActiveUsers,
  hideUserInfo
} from './actions/users';

import { 
  getTodayActivities,
  getInProgressActivity
} from './actions/activities';

// ------------------------------------------------------------------------ //
// APP
// ------------------------------------------------------------------------ //
const App = (props) => {
    
  const classes = useStyles();
  const [playAlert] = useSound(alertSoundShort, { volume: 0.25 });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [initBroadcast, setInitBroadcast] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [tokenModified, setTokenModified] = useState(false);
  const [settings, setSettings] = useState(false);
  const [basics, setBasics] = useState();
  const [alertMemo, setAlertMemo] = useState(false);
  const [alertInquiry, setAlertInquiry] = useState(false);
  const [reloadInquiries, setReloadInquiries] = useState();
  const [reloadMemo, setReloadMemo] = useState();
  const [infoUser, setInfoUser] = useState();
  const [inChat, setInChat] = useState(false);
  const [infoSOS, setInfoSOS] = useState();
  const [withTest, setWithTest] = useState(false);
  const [activeHourSelected, setActiveHourSelected] = useState(false);
 
  // ------------------------------------------------------------------------ //
  // INIT BROADCAST
  // ------------------------------------------------------------------------ //
  if(initBroadcast === false) {
    Broadcast.init();

    Broadcast.inquiries((data) => {
      playAlert();
      props.processBroadcastedInquiry(data);
    });

    Broadcast.activities(() => {
      props.getActiveUsers();
    });
    
    setInitBroadcast(true);
  }

  // ------------------------------------------------------------------------ //
  //  Refresh token  
  // ------------------------------------------------------------------------ //
  const refreshToken = useCallback(async () => {
    try {
      const response = await api.getNewToken();
      if (response) {
        localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN, response.access_token);
        localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN, response.refresh_token);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error.response);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'The refresh token is invalid.'
      ) {
        localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN);
        localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN);
        setTokenModified((tokenModified) => !tokenModified);
      } else {
        setErrorAPI(true);
      }
    }
  }, []);

  // ------------------------------------------------------------------------ //
  //  SET basics AND SET isLogin
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    setBasics(Auth.basics());
  }, [tokenModified, refreshToken]);

  // ------------------------------------------------------------------------ //
  //  SET USER 
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    setInfoUser(Auth.user());
  }, [basics, refreshToken]);

  // ------------------------------------------------------------------------ //
  //  LOAD CONNECTED USER MEMO
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    props.getUserMemo();
  }, [reloadMemo, refreshToken]);

  // ------------------------------------------------------------------------ //
  //  PLAY SOUND WHEN IS MEMO TIME
  // ------------------------------------------------------------------------ //
  useEffect(() => {

    const notified = [];

    const isMemoTime = (memo) => {
      const diff = dayjs(memo.alarm_at).unix() - dayjs().unix();

      const hasBeenNotified = notified.find((m) => m.id === memo.id);

      if (diff <= 60 && diff >= 0 && !hasBeenNotified) {
        setAlertMemo(memo);
        console.log('MEMO ALERT....');
        playAlert();
        notified.push(memo);
      }
    };

    const interval = setInterval(() => props.memo.forEach(isMemoTime), 20000);
    
    return () => clearInterval(interval);

  }, [props.memo]);


  // ------------------------------------------------------------------------ //
  //  LOAD INQUIRIES
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    props.getDirectInquiries();
  }, [reloadInquiries, infoUser, withTest]);

  // ------------------------------------------------------------------------ //
  //  LOAD ACTIVITIES
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    props.getInProgressActivity();
    props.getTodayActivities();

    // Auto refresh activities
    setInterval(() => {
      console.log('Reload in progress activity');
      props.getTodayActivities();
    }, 10000); // 10 sec
  }, [refreshToken]);

  // useEffect(() => {
  //   props.setIsUserActive(inProgressActivity !== null ? true : false);
  // }, [inProgressActivity]);

  

  // ------------------------------------------------------------------------ //
  //  LOAD ACTIVE USERS & DETECT SOS AND PLAY ALERT
  // ------------------------------------------------------------------------ //
  useEffect(() => {
    // FIXME : Voir ou mettre ce truc de SOS 
    // if (response.user.sos.user_id !== 0) {
    //   setInfoSOS(response.user.sos);
    //   playAlert();
    // }
    props.getActiveUsers();
  }, [refreshToken]);


  // ------------------------------------------------------------------------ //
  //  HANDLERS
  // ------------------------------------------------------------------------ //
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleWithTest = (event) => {
    setWithTest(event.target.checked);
  };

  const handleCloseAlertSOS = async (event) => {
    setInfoSOS(null);
    try {
      const response = await api.putUserInfo({ userId: basics.user.id, sosFrom: 0 });
      if (response.status !== 200) {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refreshToken();
        handleCloseAlertSOS();
      } else {
        setErrorAPI(true);
      }
    }
  };

  const handleCloseAlertMemo = async (event) => {
    setAlertMemo(false);
  };

  const handleCloseAlertInquiry = async (event) => {
    setAlertInquiry(false);
  };

  // ------------------------------------------------------------------------ //
  //  RENDERING
  // ------------------------------------------------------------------------ //
  return (
      <BasicsContext.Provider value={basics}>
        <refreshFunctionContext.Provider value={refreshToken}>
          <div className={classes.root}>
            <CssBaseline />

            <TDBToolbar
              openDrawer={openDrawer}
              handleCloseDrawer={handleCloseDrawer}
              handleOpenDrawer={handleOpenDrawer}
              setSettings={setSettings}
              tokenModified={tokenModified}
              setTokenModified={setTokenModified}
              activeHourSelected={activeHourSelected}
              setActiveHourSelected={setActiveHourSelected}
            />

            <main
              className={clsx(classes.content, {
                [classes.contentShift]: openDrawer,
              })}
            >
              <div className={classes.drawerHeader} />

              <ErrorAPI
                errorAPI={errorAPI}
                setErrorAPI={setErrorAPI}
                message={
                  'Un problème est survenu dans le tableau de bord Prof Express, veuillez recharger la page ou réessayer plus tard.'
                }
              />

              {settings ? ( // OPEN SETTING PAGE
                <Settings setSettings={setSettings} />
              ) : ( // DISPLAY DASHBOARD
                <>
                  {alertMemo && (
                    <AlertUser
                      alertUser={true}
                      setAlertUser={handleCloseAlertMemo}
                      title={'Alert memo : ' + alertMemo.description}
                      type={'info'}
                      position={'right'}
                    />
                  )}

                  {infoSOS && (
                    <AlertSOS
                      alertSOS={infoSOS}
                      handleCloseAlertSOS={handleCloseAlertSOS}
                      title={infoSOS.first_name + ' ' + infoSOS.last_name + ' souhaite vous parler'}
                      message={'Connecter vous sur Skype pour voir ses messages'}
                      type={'warning'}
                      position={'right'}
                    />
                  )}

                  {alertInquiry && (
                    <AlertUser
                      alertUser={alertInquiry}
                      setAlertUser={handleCloseAlertInquiry}
                      title={'Nouvelle Requête !'}
                      type={'warning'}
                      position={'center'}
                    />
                  )}

                  <div className={classes.fixedRow}>
                    <AvailabilityBlock
                        inChat={inChat}
                        setInChat={setInChat}
                        startUnavailability={null}
                        endUnavailability={null}
                      />
                  </div>

                  {basics && basics.user.test_inquiries === 1 && (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={withTest} onChange={handleWithTest} name="test" color="primary" />}
                        label="Afficher les requêtes test"
                      />
                    </>
                  )}
                  <TDBTabs
                    setSettings={setSettings}
                    setReloadMemo={setReloadMemo}
                    reloadMemo={reloadMemo}
                    reloadInquiries={reloadInquiries}
                    setReloadInquiries={setReloadInquiries}
                    activeHourSelected={activeHourSelected}
                  />
                </>
              )}

              <UserInfoModal 
                userInfo={props.userInfo}
                handleCloseModal={() => props.hideUserInfo()}
              />
            </main>
          </div>
        </refreshFunctionContext.Provider>
      </BasicsContext.Provider>
  );
};

const mapStateToProps = (state) => ({ 
  inquiries: state.inquiries,
  memo: state.memo.data,
  notifiedMemo: state.memo.notified,
  activities: state.activities.today,
  inProgressActivity: state.activities.inProgress,
  userIsActive: state.user.isActive,
  runningActivities: state.activities.running,
  userInfo: state.users.userInfo
});

const mapDispatchToProps = dispatch => {
  return {
    getUserMemo: () => dispatch(getUserMemo()),
    processBroadcastedInquiry: inquiry => dispatch(processBroadcastedInquiry(inquiry)),
    getDirectInquiries: () => dispatch(getDirectInquiries()),
    getTodayActivities: () => dispatch(getTodayActivities()),
    getInProgressActivity: () => dispatch(getInProgressActivity()),
    setIsUserActive: (status) => dispatch(setIsUserActive(status)),
    getActiveUsers: () => dispatch(getActiveUsers()),
    hideUserInfo: () => dispatch(hideUserInfo()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
