import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import {
  TableWaitingSwitchmanRequest,
  TableSwitchmanRequest,
  TableWaitingTeacherRequest,
  TableTeacherRequest,
} from '../../components/direct/TablesDirect';

import {
  getDirectInquiries
} from './../../actions/inquiries';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rightRefresh: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItem: 'flex-end',
    justifyContent: 'flex-end',
  },
}));

const TabDirect = (props) => {
  
  const {
    waitingSwitchmanInquiries,
    switchmanInquiries,
    waitingTeacherInquiries,
    teacherInquiries,
    setSettings
  } = props;

  const classes = useStyles();

  const handleRedirectAlarm = () => {
    setSettings(true);
  };
  
  return (
    <div className={classes.column}>
      {/* Requêtes en attente d'aiguillage */}
      <div className={classes.row}>
        <Tooltip title="Paramètre d'alarme">
          <IconButton aria-label="alarm" onClick={handleRedirectAlarm}>
            <NotificationsActiveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Rafraichir">
          <IconButton aria-label="refresh" onClick={() => props.getDirectInquiries()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </div>
      <TableWaitingSwitchmanRequest waitingSwitchmanInquiries={waitingSwitchmanInquiries} />

      {/* Requêtes en cours d'aiguillage */}
      <Tooltip title="Rafraichir">
        <IconButton aria-label="refresh" onClick={() => props.getDirectInquiries()} className={classes.rightRefresh}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <TableSwitchmanRequest switchmanInquiries={switchmanInquiries} />

      {/* Requêtes en attente de traitement */}
      <Tooltip title="Rafraichir">
        <IconButton aria-label="refresh" onClick={() => props.getDirectInquiries()} className={classes.rightRefresh}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <TableWaitingTeacherRequest waitingTeacherInquiries={waitingTeacherInquiries} />

      {/* Requêtes en cours de traitement */}
      <Tooltip title="Rafraichir">
        <IconButton aria-label="refresh" onClick={() => props.getDirectInquiries()} className={classes.rightRefresh}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <TableTeacherRequest teacherInquiries={teacherInquiries} />

    </div>
  );
};

const mapStateToProps = (state) => ({ 
  waitingSwitchmanInquiries: state.inquiries.direct.waitingSwitchman,
  switchmanInquiries: state.inquiries.direct.withSwitchman,
  waitingTeacherInquiries: state.inquiries.direct.waitingTeacher,
  teacherInquiries: state.inquiries.direct.withTeacher,
});

const mapDispatchToProps = dispatch => {
  return {
    getDirectInquiries: () => {
      return dispatch(getDirectInquiries());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabDirect);
