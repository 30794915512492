import React, { useContext, useState, useEffect } from 'react';
import { TextField, Tooltip, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import refreshFunctionContext from '../../context/refreshFunctionContext';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
  },
}));

export const CommentBox = (props) => {
  const { userId } = props;
  const classes = useStyles();
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [userComment, setUserComment] = useState('');

  useEffect(() => {
    const getUserComment = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getUserInfo(userId);
        if (response) {
          setUserComment(response.user.invitation_comment);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getUserComment();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getUserComment();
  }, [refresh, userId]);

  const handleUserComment = (event) => {
    setUserComment(event.target.value);
  };

  const handleSaveComment = async (event) => {
    setErrorAPI(false);
    try {
      await api.putUserInfo({ userId: userId, invitationComment: userComment });
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSaveComment();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  return (
    <div className={classes.column}>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
        }
      />
      <div className={classes.row}>
        <TextField
          id="userCommentInput"
          label="Message pour les invitations"
          onChange={handleUserComment}
          multiline
          fullWidth
          rows={4}
          value={userComment}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Tooltip title={'Enregistrer'}>
          <IconButton color="primary" aria-label="save" onClick={handleSaveComment}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
