import { configureStore } from '@reduxjs/toolkit';
import inquiries from './../reducers/inquiries';
import memo from './../reducers/memo';
import users from './../reducers/users';
import summary from './../reducers/summary';
import activities from './../reducers/activities';
import user from './../reducers/user';

export default configureStore({
  reducer: {
    inquiries: inquiries,
    memo: memo,
    users: users,
    summary: summary,
    activities: activities,
    user: user,
  }
});