import dayjs from 'dayjs';

// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import { 
  LOAD_USER_MEMO,
  CREATE_USER_MEMO,
  UPDATE_USER_MEMO,
  DELETE_USER_MEMO
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
  data: [],
  notified: []
};

// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {
  switch (action.type) {  
    case LOAD_USER_MEMO:
      return {
        ...state,
        data: action.payload
      };

    case UPDATE_USER_MEMO:
      action.payload.alarm_at = dayjs(action.payload.alarm_at).format('YYYY-MM-DD HH:mm:ss');

      return {
        ...state,
        data: state.data.map((r) => r.id === action.payload.id ? action.payload : r)
      };

    case CREATE_USER_MEMO:
      action.payload.alarm_at = dayjs(action.payload.alarm_at).format('YYYY-MM-DD HH:mm:ss');
      
      let data = [...state.data];
      data.push(action.payload);    

      return {
        ...state,
        data: data
      };

    case DELETE_USER_MEMO: 
      return {
        ...state,
        data: state.data.filter((r) => r.id !== action.payload.id)
      };
  
    default:
      return state;
  }
};

export default reducer;