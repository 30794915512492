import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );
};
