// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import api from './../api/api';
import dayjs from 'dayjs';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
  LOAD_WAITING_SWITCHMAN_INQUIRIES,
  LOAD_WITH_SWITCHMAN_INQUIRIES,
  LOAD_WAITING_TEACHER_INQUIRIES,
  LOAD_WITH_TEACHER_INQUIRIES,
  PROCESS_BROADCASTED_INQUIRY,
  LOAD_PROCESSED_INQUIRIES,
  LOAD_DIRECT_INQUIRIES
} from './../constants/actionTypes';


// ------------------------------------------------------------------------ //
// processBroadcastedInquiry
// ------------------------------------------------------------------------ //
export const processBroadcastedInquiry = (data) => ({
  type: PROCESS_BROADCASTED_INQUIRY,
  payload: data.inquiry
})

// ------------------------------------------------------------------------ //
//  getDirectInquiries
// ------------------------------------------------------------------------ //
export const getDirectInquiries = () => {
  return (dispatch) => { 
    api.getDirectInquiries({
      timestampFrom: dayjs().subtract(2, 'day').unix(), // FIXME : 48h ?
    }).then(function(response) {
      return dispatch({
        type: LOAD_DIRECT_INQUIRIES,
        payload: response.data
      });
    });
  };
}

// ------------------------------------------------------------------------ //
//  getTreatedInquiries
// ------------------------------------------------------------------------ //
export const getProcessedInquiries = (timestampFrom) => {
  return (dispatch) => { 
    api.getProcessedInquiries({
      timestampFrom: timestampFrom
    }).then(function(response) {
      console.log('timestampFrom --->', timestampFrom);
      return dispatch({
        type: LOAD_PROCESSED_INQUIRIES,
        payload: response.data
      });
    });
  };
}


// ------------------------------------------------------------------------ //
// getWaitingSwitchmanInquiries
// ------------------------------------------------------------------------ //
export const getWaitingSwitchmanInquiries = () => {
  return (dispatch) => { 
    api.getInquiries({
      statusId: 4,
      timestampFrom: dayjs().subtract(2, 'day').unix(), // FIXME : 48h ?
      hasSwitchman: 0,
      hasTeacher: 0,
    }).then(function(response) {
      return dispatch({
        type: LOAD_WAITING_SWITCHMAN_INQUIRIES,
        payload: response
      });
    });
  };
}

// ------------------------------------------------------------------------ //
// getWithSwitchmanInquiries
// ------------------------------------------------------------------------ //
export const getWithSwitchmanInquiries = () => {
  return (dispatch) => { 
    api.getInquiries({
      statusId: 4,
      timestampFrom: dayjs().subtract(2, 'day').unix(), // FIXME : 48h ?
      hasTeacher: 0,
    }).then(function(response) {
      return dispatch({
        type: LOAD_WITH_SWITCHMAN_INQUIRIES,
        payload: response
      });
    });
  };
}

// ------------------------------------------------------------------------ //
// getWaitingTeacherInquiries
// ------------------------------------------------------------------------ //
export const getWaitingTeacherInquiries = () => {
  return (dispatch) => { 
    api.getInquiries({
        statusId: 7,
        timestampFrom: dayjs().subtract(2, 'day').unix(), // FIXME : 48h ?
        hasTeacher: 0,
    }).then(function(response) {
      return dispatch({
        type: LOAD_WAITING_TEACHER_INQUIRIES,
        payload: response
      });
    });
  };
}

// ------------------------------------------------------------------------ //
// getWithTeacherInquiries
// ------------------------------------------------------------------------ //
export const getWithTeacherInquiries = () => {
  return (dispatch) => { 
    api.getInquiries({
      statusId: 7,
      timestampFrom: dayjs().subtract(2, 'day').unix(), // FIXME : 48h ?
    }).then(function(response) {
      return dispatch({
        type: LOAD_WITH_TEACHER_INQUIRIES,
        payload: response
      });
    });
  };
}
