import axios from 'axios';
import dayjs from 'dayjs';

const api = {

  refreshTokenPromise: null,

  // ------------------------------------------------------------------ //
  // TOKEN
  // ------------------------------------------------------------------ //
  getAccessToken: () => {
    return localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN);
  },

  getRefreshToken: () => {
    return localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN);
  },

  setAccessToken: (token) => {
    localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN, token);
  },

  setRefreshToken: (token) => {
    localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN, token);
  },

  getNewToken: async () => {
    // we don't want to refresh the token multiple time simultaneously
    // We don't refresh token until promise is not resolved
    if (!api.refreshTokenPromise) {
      api.refreshTokenPromise = axios.post(process.env.REACT_APP_API_URL + '/oauth/token', {
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token: api.getRefreshToken(),
        scope: '*',
      });
    }

    const response = await api.refreshTokenPromise;

    api.refreshTokenPromise = null;

    return response.data;
  },

  // ------------------------------------------------------------------ //
  // USER_INFO
  // ------------------------------------------------------------------ //
  getBasics: async () => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-basics', options);
    return response.data;
    //return dataBasics;
  },

  getUserInfo: async (userId) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-user-infos/' + userId, options);

    return data;
  },

  putUserInfo: async ({
    userId,
    statusId,
    groupId,
    available,
    unavailableStartedAt,
    unavailableEndedAt,
    alarmAll,
    alarmOnlyMe,
    alarmNoSound,
    invitationComment,
    sosFrom,
  }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const response = await axios.patch(
      process.env.REACT_APP_API_URL + '/api_board/patch-user-infos/' + userId,
      {
        alarm_all: alarmAll,
        alarm_only_me: alarmOnlyMe,
        alarm_no_sound: alarmNoSound,
        group_id: groupId,
        status_id: statusId,
        available: available,
        unavailable_started_at: unavailableStartedAt,
        unavailable_ended_at: unavailableEndedAt,
        invitation_comment: invitationComment,
        sos_user_id: sosFrom,
      },
      options
    );
    console.log(response)
    return response;
  },

  // ------------------------------------------------------------------ //
  // HORODATEUR
  // ------------------------------------------------------------------ //
  getSummaryProcessedInquiries: async ({
    from,
    to,
    userId,
  }) => {
    const headers = {
      authorization: 'Bearer ' + api.getAccessToken(),
      accept: 'application/json',
    };

    if(to === null) {
      to = dayjs(from * 1000)
        .add(23, 'hour')
        .add(59, 'minute')
        .add(59, 'second')
        .unix();
    }

    let params = {
      timestamp_from: from,
      timestamp_to: to
    };

    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-user-infos/' + userId, {
      headers: headers
    });

    const {role, user} = response.data;

    if(role === "teacher") {
      params.teacher_id = user.teacher_id;
    }

    if(role === "switchman") {
      params.switchman_id = user.switchman_id;
    }

    return await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-horodateur-inquiries', {
      headers: headers,
      params: params
    });
  },


  getSummaryDirectInquiries: async ({
    from,
    to,
    userId,
  }) => {
    const headers = {
      authorization: 'Bearer ' + api.getAccessToken(),
      accept: 'application/json',
    };

    let params = {
      timestamp_from: from,
      timestamp_to: to
    };

    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-user-infos/' + userId, {
      headers: headers
    });

    const {role, user} = response.data;

    if(role === "teacher") {
      params.teacher_id = user.teacher_id;
    }

    if(role === "switchman") {
      params.switchman_id = user.switchman_id;
    }

    return await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-horodateur-inquiries', {
      headers: headers,
      params: params
    });
  },

  // ------------------------------------------------------------------ //
  // ACTIVITIES
  // ------------------------------------------------------------------ //
  getActivitiesUser: async ({ userId, startedAt, endedAt }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: startedAt,
        timestamp_to: endedAt,
      },
    };

    const url = process.env.REACT_APP_API_URL + '/api_board/get-user-activities/' + userId;

    const response = await axios.get(url, options);

    return response.data;
  },

  getInProgressActivity: async () => {
    const options = {
      headers: {
        authorization: 'Bearer ' + api.getAccessToken(),
        accept: 'application/json',
      },
    };

    const url = process.env.REACT_APP_API_URL + '/api_board/get-current-user-activity';

    const { data } = await axios.get(url, options);

    return data;
  },

  postActivity: async ({
    startedAt,
    endedAt,
    manual,
    activityTypeId,
    activityGroupId,
    specialActivityName,
    timezoneId,
    reinforcement,
  }) => {
    const localAccessToken = api.getAccessToken();

    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const params = {
      started_at: startedAt,
      ended_at: endedAt,
      manual: manual,
      activity_type_id: activityTypeId,
      activity_group_id: activityGroupId,
      special_activity_name: specialActivityName,
      timezone_id: timezoneId,
      reinforcement: reinforcement,
    };

    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + '/api_board/post-activity',
      params,
      options
    );

    return data;
  },

  patchActivity: async ({
    id,
    end,
    start,
    manual,
    activityTypeId,
    specialActivityLabel,
    timezoneId,
    reinforcement,
    userId,
  }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.patch(
      process.env.REACT_APP_API_URL + '/api_board/patch-activity/' + id,
      {
        ended_at: end,
        started_at: start,
        manual: manual,
        activity_type_id: activityTypeId,
        special_activity_name: specialActivityLabel,
        timezone_id: timezoneId,
        reinforcement: reinforcement,
      },
      options
    );
    return response.data;
  },

  deleteActivity: async (id) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    await axios.delete(process.env.REACT_APP_API_URL + '/api_board/delete-activity/' + id, options);
  },

  // ------------------------------------------------------------------ //
  //  DIRECT INQUIRIES
  // ------------------------------------------------------------------ //
  getDirectInquiries: async ({
    timestampFrom,
    timestampTo
  }) => {
    return await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-direct-inquiries', {
      headers: {
        authorization: 'Bearer ' + api.getAccessToken(),
        accept: 'application/json',
      },
      params: {
        timestamp_from: timestampFrom,
        timestamp_to: timestampTo
      },
    });
  },

  // ------------------------------------------------------------------ //
  //  PROCESSED INQUIRIES
  // ------------------------------------------------------------------ //
  getProcessedInquiries: async ({
    timestampFrom,
    timestampTo
  }) => {
    return await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-traitees-inquiries', {
      headers: {
        authorization: 'Bearer ' + api.getAccessToken(),
        accept: 'application/json',
      },
      params: {
        timestamp_from: timestampFrom,
        timestamp_to: timestampTo
      },
    });
  },


  // ------------------------------------------------------------------ //
  //  INQUIRIES
  // ------------------------------------------------------------------ //
  getInquiries: async ({ timestampFrom, timestampTo, statusId, teacherId, switchmanId, hasSwitchman, hasTeacher }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        status_id: statusId,
        timestamp_from: timestampFrom,
        timestamp_to: timestampTo,
        teacher_id: teacherId,
        switchman_id: switchmanId,
        has_switchman: hasSwitchman,
        has_teacher: hasTeacher,
      },
    };

    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-inquiries', options);
    return response.data;
  },

  postInquiry: async ({ typeId, statusId, adjustNote, invoicesWeight, switchmanId, teacherId }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/api_board/post-inquiry',
      {
        status_id: statusId,
        type_id: typeId,
        adjust_note: adjustNote,
        invoices_weight: invoicesWeight,
        switchman_id: switchmanId,
        teacher_id: teacherId,
      },
      options
    );
    return response.data;
  },

  // ------------------------------------------------------------------ //
  // SIGNIN
  // ------------------------------------------------------------------ //
  signIn: async (email, password) => {
    const options = {
      headers: {
        accept: 'application/json',
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/oauth/token',
      {
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: email,
        password: password,
        scope: '*',
      },
      options
    );
    return response.data;
  },

  // ------------------------------------------------------------------ //
  //  USERS
  // ------------------------------------------------------------------ //
  getUsers: async () => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-users', options);
    return response.data;
  },

  getActiveUsers: async () => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-board-users', options);
    return response.data;
  },

  getUsersRunningActivities: async () => {
    const localAccessToken = api.getAccessToken();

    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const url = process.env.REACT_APP_API_URL + '/api_board/get-current-activities';

    const { data } = await axios.get(url, options);

    return data;
  },

  // ------------------------------------------------------------------ //
  //  USER STATS
  // ------------------------------------------------------------------ //
  getStatsUser: async ({ userId, date, activityTypeId, timezoneId }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp: date,
        activity_type_id: activityTypeId,
        timezone_id: timezoneId,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-user-stats/' + userId, options);
    return response.data;
  },

  // ------------------------------------------------------------------ //
  //  MEMO
  // ------------------------------------------------------------------ //
  getUserMemo: async (userId) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-user-memos/' + userId, options);
    return response.data;
    //return dataMemoUser;
  },

  createUserMemo: async (userId, date, description) => {
    return await axios.post(process.env.REACT_APP_API_URL + '/api_board/post-memo', {
      alarm_at: date,
      description: description
    }, {
      headers: {
        authorization: 'Bearer ' + api.getAccessToken(),
        accept: 'application/json',
      },
    });
  },

  updateUserMemo: async (memoId, date, description) => {
    return await axios.patch(
      process.env.REACT_APP_API_URL + '/api_board/patch-memo/' + memoId, {
        memo_id: memoId,
        alarm_at: date,
        description: description
      }, {
        headers: {
          authorization: 'Bearer ' + api.getAccessToken(),
          accept: 'application/json',
        },
      });
  },

  deleteUserMemo: async (memoId) => {
    return await axios.delete(process.env.REACT_APP_API_URL + '/api_board/delete-memo/' + memoId, {
      headers: {
        authorization: 'Bearer ' + api.getAccessToken(),
        accept: 'application/json',
      },
    })
  },

  // ------------------------------------------------------------------ //
  //  AGENDA
  // ------------------------------------------------------------------ //
  getEventsUser: async ({ userId, startDate, endDate }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: startDate,
        timestamp_to: endDate,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/api_board/get-user-events/' + userId,

      options
    );
    return response.data;
  },

  postEvent: async (userId, startDate, endDate, statusId, reinfInt) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/api_board/post-event',
      { user_id: userId, started_at: startDate, ended_at: endDate, status_id: statusId, reinforcement: reinfInt },
      options
    );
    return response.data;
  },

  putEvent: async ({
    id,
    startDate,
    endDate,
    statusId,
    title,
    reinfInt,
    subjectId,
    levelId,
    workzoneId,
    invited_reinforcement,
  }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.patch(
      process.env.REACT_APP_API_URL + '/api_board/patch-event/' + id,
      {
        started_at: startDate,
        ended_at: endDate,
        status_id: statusId,
        title: title,
        reinforcement: reinfInt,
        invited_subject_id: subjectId,
        invited_level_group_id: levelId,
        invited_workzone_id: workzoneId,
        invited_reinforcement: invited_reinforcement,
      },

      options
    );
    return response.data;
  },

  deleteEvent: async (id) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const response = await axios.delete(process.env.REACT_APP_API_URL + '/api_board/delete-event/' + id, options);
    return response.data;
  },

  getOpenSlots: async ({ startDate, endDate }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: startDate,
        timestamp_to: endDate,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-slots', options);
    return response.data;
  },


  postOpenSlot: async (started_at, ended_at) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/api_board/post-slot',
      { started_at: started_at, ended_at: ended_at },
      options
    );
    return response.data;
  },

  deleteSlot: async (id) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };

    const response = await axios.delete(process.env.REACT_APP_API_URL + '/api_board/delete-slot/' + id, options);
    return response.data;
  },

  getWeekInvitation: async (start, subjectId, levelId, workzoneId) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: start,
        subject_id: subjectId,
        level_group_id: levelId,
        workzone_id: workzoneId,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-week-invitations', options);
    return response.data;
  },

  getAvailableTeacher: async ({ start, end, subjectId, levelId, workzoneId }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: start,
        timestamp_to: end,
        subject_id: subjectId,
        level_group_id: levelId,
        workzone_id: workzoneId,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-available-teachers-events', options);
    return response.data;
    //return dataAvailableTeacher;
  },

  getInvitedTeacher: async ({ start, end, subjectId, levelId, workzoneId }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: start,
        timestamp_to: end,
        subject_id: subjectId,
        level_group_id: levelId,
        workzone_id: workzoneId,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-invited-teachers-events', options);
    return response.data;
    //return dataInvitedTeacher;
  },

  getTeacherList: async ({ start, end, subjectId, levelId, workzoneId }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
      params: {
        timestamp_from: start,
        timestamp_to: end,
        subject_id: subjectId,
        level_group_id: levelId,
        workzone_id: workzoneId,
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-teachers', options);
    return response.data;
    //return dataTeacherList;
  },


  // ------------------------------------------------------------------ //
  //  BOARD SETTINGS
  // ------------------------------------------------------------------ //
  getBoardSettings: async () => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api_board/get-settings', options);
    return response.data;
  },

  patchBoardSettings: async ({ globalComment }) => {
    const localAccessToken = api.getAccessToken();
    const options = {
      headers: {
        authorization: 'Bearer ' + localAccessToken,
        accept: 'application/json',
      },
    };
    const response = await axios.patch(
      process.env.REACT_APP_API_URL + '/api_board/patch-settings',
      { calendar_general_message: globalComment },
      options
    );
    return response.data;
  },
};

export default api;
