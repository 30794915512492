import React from 'react';

import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const StartOutHourModal = (props) => {
  const { openModal, handleCloseModal, handleStartActivity } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Lancer une activité hors horaire
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {' '}
            Vous êtes sur le point de lancer une activité en dehors des horaires d'ouverture du tableau de bord Prof
            Express. Êtes vous sûr de vouloir continuer ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleStartActivity} color="primary">
            Lancer l'activité
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
