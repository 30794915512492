// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import Auth from '../services/Auth';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
    SET_CURRENT_USER,
    SET_IS_ACTIVE_USER
} from '../constants/actionTypes';


// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
export const setUser = () => {
    return (dispatch) => { 
        return dispatch({
            type: SET_CURRENT_USER,
            payload: Auth.user()
        });
    };
}

export const setIsUserActive = (value) => {
    return (dispatch) => { 
        return dispatch({
            type: SET_IS_ACTIVE_USER,
            payload: value
        });
    };
}
