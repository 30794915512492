import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { TimezoneSelect } from '../globalComponents/TimezoneSelect';
import { ActivityTypeSelect } from '../globalComponents/ActivityTypeSelect';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rowDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  colTimezone: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2em',
  },
  rowTimezone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export const ModificationModal = (props) => {
  const { openModal, handleCloseModal, activity } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [activityTypeId, setActivityTypeId] = useState(activity[6]);
  const [specialActivityName, setSpecialActivityName] = useState(activity[7]);
  const [startedAt, setStartedAt] = useState(dayjs(activity[1]).format('YYYY-MM-DDTHH:mm'));
  const [endedAt, setEndedAt] = useState(dayjs(activity[2]).format('YYYY-MM-DDTHH:mm'));
  const [timezoneId, setTimezoneId] = useState(activity[9]);
  const [reinforcement, setReinforcement] = useState(activity[10]);
  const [negativeDurationAlert, setNegativeDurationAlert] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);

  const handleActivityTypeId = (event) => {
    setActivityTypeId(event.target.value);
  };

  const handleSpecialActivityName = (event) => {
    setSpecialActivityName(event.target.value);
  };

  const handleStart = (event) => {
    setNegativeDurationAlert(false);
    const value = event.target.value;
    const end = dayjs(endedAt);
    const start = dayjs(value);
    const durationMinutes = end.diff(start, 'minute');
    if (durationMinutes < 0) {
      setNegativeDurationAlert(true);
    }
    setStartedAt(event.target.value);
  };

  const handleEnd = (event) => {
    setNegativeDurationAlert(false);
    const value = event.target.value;
    const start = dayjs(startedAt);
    const end = dayjs(value);
    const durationMinutes = end.diff(start, 'minute');

    if (durationMinutes < 0) {
      setNegativeDurationAlert(true);
    }
    setEndedAt(value);
  };

  const handleTimezoneId = (event) => {
    setTimezoneId(event.target.value);
  };

  const handleReinforcement = (event) => {
    setReinforcement(event.target.checked);
  };

  const handleSaveAndClose = async () => {
    var specialActivityLabel = specialActivityName;
    if (activityTypeId !== 4) {
      specialActivityLabel = '';
    }
    const start = dayjs.tz(startedAt, 'Europe/Paris').unix();
    const end = dayjs.tz(endedAt, 'Europe/Paris').unix();

    const manual = true;

    try {
      await api.patchActivity({
        id: activity[0],
        end: end,
        start: start,
        manual: manual,
        activityTypeId: activityTypeId,
        specialActivityLabel: specialActivityLabel,
        timezoneId: timezoneId,
        reinforcement: reinforcement,
      });
      handleCloseModal();
    } catch (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSaveAndClose();
      } else {
        setErrorAPI(true);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Modifier l'activité suivante
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu lors de la modification, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        <DialogContent dividers>
          <ActivityTypeSelect activityTypeId={activityTypeId} handleActivityTypeId={handleActivityTypeId} />
          {activityTypeId === 4 && (
            <TextField
              margin="dense"
              id="activityType"
              variant="outlined"
              label="Intitulé de l'activité"
              value={specialActivityName}
              onChange={handleSpecialActivityName}
              fullWidth
            />
          )}

          <div className={classes.rowDate}>
            <TextField
              id="ActivityStart"
              label="Début de l'activité"
              type="datetime-local"
              margin="dense"
              value={startedAt}
              onChange={handleStart}
              className={classes.spacing}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="ActivityEnd"
              label="Fin de l'activité"
              type="datetime-local"
              margin="dense"
              value={endedAt}
              onChange={handleEnd}
              error={negativeDurationAlert}
              helperText={negativeDurationAlert ? 'Fin inférieure au début' : ' '}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.rowTimezone}>
            <div className={classes.colTimezone}>
              <TimezoneSelect timezoneId={timezoneId} handleTimezoneId={handleTimezoneId} />
            </div>
            <FormControlLabel
              control={
                <Checkbox checked={reinforcement} onChange={handleReinforcement} name="reinforcement" color="primary" />
              }
              label="Activité de renfort"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSaveAndClose} disabled={negativeDurationAlert} color="primary">
            Enregistrer la correction
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
