import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export const AlertUser = (props) => {
  const { title, message, alertUser, setAlertUser, type, position } = props;

  return (
    <Snackbar
      open={alertUser}
      anchorOrigin={{ vertical: 'top', horizontal: position }}
      autoHideDuration={60000}
      onClose={setAlertUser}
    >
      <Alert severity={type} variant="filled" onClose={setAlertUser}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
