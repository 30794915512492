import Auth from './../services/Auth';
import _ from 'lodash';

// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import {
  LOAD_ACTIVE_USERS,
  SHOW_USER_INFO,
  HIDE_USER_INFO
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
  activeUsers: [],
  userInfo: null
};

// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {
  switch (action.type) {

    case LOAD_ACTIVE_USERS:
      const { board_activity_types } = Auth.basics();

      const activeUsers = action.payload;
      const without_activity = {
        'id': 0,
        'name': 'Sans activité',
        'users': activeUsers.filter(user => {
          return user.status_id != 3;
        })
      }

      let data = [...board_activity_types].map(type => {

        const groupIds = activeUsers.filter(user => {
          return user.activity_group_id !== null && user.status_id == 3;
        }).map(user => {
          return user.activity_group_id;
        });

        type.groups = _.uniq(groupIds).map(id => {
          let group = getGroupById(id);
          let users = activeUsers.filter(user => user.activity_type_id === type.id && user.activity_group_id === group.id);

          group.users = users ? users : [];

          return group;
        });

        return type;
      });

      data.push(without_activity)

      // const allType = data.reduce((acc, current) => {
      //   current.groups.forEach(group => {
      //     const foundGroup = acc.groups.find(g => g.id === group.id);
      //     if (foundGroup) {
      //       foundGroup.users = [...foundGroup.users, ...group.users];
      //     } else {
      //       acc.groups.push({...group});
      //     }
      //   });
      //   return acc;
      // }, {"id": 0, "name": "Tous type", "groups": []});
      //
      // data = [...data, allType];

      return {
        ...state,
        activeUsers: data
      };

    case HIDE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };

    case SHOW_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };

    default:
      return state;
  }
};


// ------------------------------------------------------------------------ //
//  HELPERS
// ------------------------------------------------------------------------ //
const getGroupById = (id) => {
  const { board_user_groups } = Auth.basics();

  return _.find(board_user_groups, {
    id: id
  })
};


export default reducer;
