import React, { useState, useContext, useEffect } from 'react';
import { Divider, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RequestAdjustModal } from './RequestAdjustModal';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import api from '../../api/api';
import dayjs from 'dayjs';
import { Loading } from '../globalComponents/Loading';
import { ActivityTypeSelect } from '../globalComponents/ActivityTypeSelect';
import { TimezoneSelect } from '../globalComponents/TimezoneSelect';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  rowSpace: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-around',
  },
  columnCenter: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2em',
  },
  margin: {
    marginBottom: '0.2em',
  },
  divider: { margin: '1em' },
  vertical: { height: '6em', marginTop: '2em' },
  button: {
    margin: '0.5em',
  },
  input: {
    display: 'flex',
    flexGrow: 0.9,
  },
}));

export const Stats = (props) => {
  const classes = useStyles();
  const { date, reload, setReload, user } = props;
  const [activityTypeId, setActivityTypeId] = useState('');
  const [timezoneId, setTimezoneId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState();
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [durationDay, setDurationDay] = useState(0);
  const [durationMonth, setDurationMonth] = useState(0);
  const [durationMonthWeeks, setDurationMonthWeeks] = useState(0);
  const [durationMonthWeekends, setDurationMonthWeekends] = useState(0);

  useEffect(() => {
    const getStats = async () => {
      setErrorAPI(false);
      try {
        let response = null;
        if (activityTypeId !== '' && timezoneId !== '') {
          response = await api.getStatsUser({
            userId: user.id,
            date: dayjs.tz(date, 'Europe/Paris').unix(),
            activityTypeId: activityTypeId,
            timezoneId: timezoneId,
          });
        } else if (activityTypeId === '' && timezoneId !== '') {
          response = await api.getStatsUser({
            userId: user.id,
            date: dayjs.tz(date, 'Europe/Paris').unix(),
            timezoneId: timezoneId,
          });
        } else if (activityTypeId !== '' && timezoneId === '') {
          response = await api.getStatsUser({
            userId: user.id,
            date: dayjs.tz(date, 'Europe/Paris').unix(),
            activityTypeId: activityTypeId,
          });
        } else {
          response = await api.getStatsUser({ userId: user.id, date: dayjs.tz(date, 'Europe/Paris').unix() });
        }

        if (response) {
          const durationDay = response.daily_activities_duration;
          var hours = Number.isInteger(durationDay / 3600) ? durationDay / 3600 : (durationDay / 3600).toFixed(2);
          setDurationDay(hours + 'h');

          const durationMonth = response.monthly_activities_duration;
          hours = Number.isInteger(durationMonth / 3600) ? durationMonth / 3600 : (durationMonth / 3600).toFixed(2);
          setDurationMonth(hours + 'h');

          const durationMonthWeek = response.weekdays_activities_duration;
          hours = Number.isInteger(durationMonthWeek / 3600)
            ? durationMonthWeek / 3600
            : (durationMonthWeek / 3600).toFixed(2);
          setDurationMonthWeeks(hours + 'h');

          const durationMonthWeekend = response.weekends_activities_duration;
          hours = Number.isInteger(durationMonthWeekend / 3600)
            ? durationMonthWeekend / 3600
            : (durationMonthWeekend / 3600).toFixed(2);
          setDurationMonthWeekends(hours + 'h');

          setData(response);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getStats();
        } else {
          setErrorAPI(true);
          console.log(error);
        }
      }
    };

    getStats();
  }, [date, refresh, reload, activityTypeId, timezoneId]);

  const handleActivityTypeId = (event) => {
    setActivityTypeId(event.target.value);
  };

  const handleTimezoneId = (event) => {
    setTimezoneId(event.target.value);
  };

  const handleOpenModal = (event) => {
    setOpenModal(true);
  };

  const handleCloseModal = (event) => {
    setOpenModal(false);
  };

  return (
    <div className={classes.column}>
      <Typography variant="h5" className={classes.margin}>
        Résumé d'activité
      </Typography>
      {data ? (
        <>
          <div className={classes.rowSpace}>
            <div className={classes.input}>
              <ActivityTypeSelect
                activityTypeId={activityTypeId}
                handleActivityTypeId={handleActivityTypeId}
                option={'all'}
              />
              <div className={classes.button}></div>
              <TimezoneSelect timezoneId={timezoneId} handleTimezoneId={handleTimezoneId} option={'all'} />
            </div>

            <Button variant="contained" color="secondary" className={classes.button} onClick={handleOpenModal}>
              Ajustement requêtes
            </Button>
          </div>
          <ErrorAPI
            errorAPI={errorAPI}
            setErrorAPI={setErrorAPI}
            message={
              'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
            }
          />
          <Typography variant="h6" className={classes.margin}>
            Totaux de la journée du date donnée
          </Typography>
          <div className={classes.rowSpace}>
            <Typography variant="subtitle1">Heures d'activité</Typography>
            <Typography variant="subtitle1">{durationDay}</Typography>
          </div>
          <div className={classes.rowSpace}>
            <Typography variant="subtitle1">Requêtes traitées</Typography>
            <Typography variant="subtitle1">{data.daily_inquiries_number}</Typography>
          </div>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.margin}>
            Totaux du mois de Mois de la date
          </Typography>
          <div className={classes.rowSpace}>
            <Typography variant="subtitle1">Heures d'activité</Typography>
            <Typography variant="subtitle1">{durationMonth}</Typography>
          </div>
          <div className={classes.rowSpace}>
            <Typography variant="subtitle1">Requêtes traitées</Typography>
            <Typography variant="subtitle1">{data.monthly_inquiries_number}</Typography>
          </div>
          <div className={classes.rowCenter}>
            <div className={classes.columnCenter}>
              <Typography variant="h6">En semaine</Typography>
              <div className={classes.rowSpace}>
                <Typography variant="subtitle1">Heures</Typography>
                <Typography variant="subtitle1">{durationMonthWeeks}</Typography>
              </div>
              <div className={classes.rowSpace}>
                <Typography variant="subtitle1">Requêtes</Typography>
                <Typography variant="subtitle1">{data.weekdays_inquiries_number}</Typography>
              </div>
            </div>
            <Divider orientation="vertical" className={classes.vertical} flexItem />
            <div className={classes.columnCenter}>
              <Typography variant="h6">En weekend</Typography>
              <div className={classes.rowSpace}>
                <Typography variant="subtitle1">Heures</Typography>
                <Typography variant="subtitle1">{durationMonthWeekends}</Typography>
              </div>
              <div className={classes.rowSpace}>
                <Typography variant="subtitle1">Requêtes</Typography>
                <Typography variant="subtitle1">{data.weekends_inquiries_number}</Typography>
              </div>
            </div>
          </div>
          <RequestAdjustModal
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            date={date}
            requestNb={data.daily_inquiries_treated}
            reload={reload}
            setReload={setReload}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
