import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export const ErrorAPI = (props) => {
  const { message, errorAPI, setErrorAPI } = props;

  return (
    <>
      {errorAPI && (
        <Alert
          onClose={() => {
            setErrorAPI(false);
          }}
          severity="error"
        >
          <AlertTitle>Erreur</AlertTitle>
          {message}
        </Alert>
      )}
    </>
  );
};
