import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Button,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import useSound from 'use-sound';
import alertSound from '../../sounds/alert_long.mp3';

import refreshFunctionContext from '../../context/refreshFunctionContext';
import api from '../../api/api';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em',
  },
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  text: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: 200,
  },
  button: { marginRight: theme.spacing(2) },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AvailabilityModal = (props) => {
  const { openModal, setAvailabilityEndDate, handleCloseModal, setAlertAvailability, userId, available } = props;
  const classes = useStyles();
  const [endDate, setEndDate] = useState();
  const [duration, setDuration] = useState(20);
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);

  const [loading, setLoading] = useState(false);

  const [playAlert] = useSound(alertSound, { volume: 0.25 });

  useEffect(() => {
    const getUserInfo = async () => {
      setErrorAPI(false);
      try {
        setLoading(true);
        const response = await api.getUserInfo(userId);
        if (response) {
          if (response.user.available === 0) {
            const endFormated = dayjs(response.user.unavailable_ended_at).format('YYYY-MM-DDTHH:mm');
            const nowFormated = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
            setEndDate(endFormated);
            setAvailabilityEndDate(endFormated);
            setDuration(dayjs(endFormated).diff(dayjs(nowFormated), 'minute'));
            setLoading(false);
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getUserInfo();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    if (!available) {
      getUserInfo();
      const interval = setInterval(() => {
        const nowFormated = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
        var diff = dayjs(endDate).diff(dayjs(nowFormated), 'minute');
        setDuration(diff);

        if (diff <= 2 && diff >= 0) {
          setAlertAvailability(true);
          // playAlert();
        }
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [openModal, endDate, available, setAlertAvailability, playAlert, userId, refresh]);

  const handleEndDateChange = (event) => {
    const nowFormated = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
    const end = dayjs(event.target.value);
    var diff = end.diff(dayjs(nowFormated), 'minute');
    setDuration(diff);
    if (diff <= 2 && diff >= 0) {
      setAlertAvailability(true);
      // playAlert();
    }
    setEndDate(event.target.value);
    handleTimeChange(event.target.value);
  };

  const handleDurationChange = (event) => {
    const newDuration = parseInt(event.target.value);
    const now = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
    var newEndDate = dayjs(now).add(newDuration, 'minute').format('YYYY-MM-DDTHH:mm');
    setEndDate(newEndDate);
    setDuration(newDuration);
    handleTimeChange(newEndDate);
  };

  const addDuration = (time) => {
    const newDuration = duration + time;
    const now = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
    const newEndDate = dayjs(now).add(newDuration, 'minute').format('YYYY-MM-DDTHH:mm');
    setEndDate(newEndDate);
    setDuration(newDuration);
    handleTimeChange(newEndDate);
  };

  const removeDuration = (time) => {
    const newDuration = duration - time;
    const now = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
    const newEndDate = dayjs(now).add(newDuration, 'minute').format('YYYY-MM-DDTHH:mm');
    setEndDate(newEndDate);
    setDuration(newDuration);
    handleTimeChange(newEndDate);
  };

  const handleTimeChange = async (end) => {
    try {
      const response = await api.putUserInfo({
        userId: userId,
        unavailableEndedAt: end,
      });
      if (response) {
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleTimeChange();
      } else {
        setErrorAPI(true);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Détail de la durée de l'activité
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {!loading ? (
          <DialogContent dividers>
            <ErrorAPI
              errorAPI={errorAPI}
              setErrorAPI={setErrorAPI}
              message={
                'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
              }
            />
            <div className={classes.text}>
              <DialogContentText>Statut actuel : Au téléphone</DialogContentText>
            </div>
            <div className={classes.buttonRow}>
              <TextField
                id="datetime-end"
                label="Heure de fin estimée"
                type="datetime-local"
                className={classes.textField}
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className={classes.buttonRow}>
              <TextField
                type="number"
                value={duration}
                onChange={handleDurationChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                }}
                label="Durée restante"
              />
            </div>
            <div className={classes.buttonRow}>
              <Button variant="outlined" onClick={() => removeDuration(5)}>
                -5 min
              </Button>
              <Button variant="outlined" className={classes.button} onClick={() => addDuration(5)}>
                + 5 min
              </Button>
            </div>
          </DialogContent>
        ) : (
          <div className={classes.buttonRow}>
            <CircularProgress />
          </div>
        )}
      </Dialog>
    </div>
  );
};
