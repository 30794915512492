import React, { useState, useContext, useEffect } from 'react';
import { Typography, FormControlLabel, Switch, Button } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles } from '@material-ui/core/styles';
import { AvailabilityModal } from './AvailabilityModal';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import api from '../../api/api';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
//import { AlertUser } from '../globalComponents/AlertUser';
import dayjs from 'dayjs';
import Auth from '../../services/Auth';
import { connect } from 'react-redux';

// ------------------------------------------------------------------------ //
// REDUX
// ------------------------------------------------------------------------ //
import { 
  getInProgressActivity,
  getTodayActivities,
  startActivity,
  stopActivity
} from './../../actions/activities';

import { 
  setIsUserActive
} from './../../actions/user';


// ------------------------------------------------------------------------ //
// STYLE
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  availableTextRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  chatCallRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chatCallColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  availabilityColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: '1em',
  },
  alert: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(8),
  },
}));

// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
const AvailabilityBlock = (props) => {
  const { setInChat, inChat, startUnavailability } = props;
  const classes = useStyles();
  const [inCall, setInCall] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [endDate, setEndDate] = useState();
  const [errorAPI, setErrorAPI] = useState(false);
  const { user } = Auth.user();

  const { 
    inProgressActivity
  } = props;

  useEffect(() => {
    
    const getUserInfo = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getUserInfo(user.id);
        if (response) {
          if (response.user.available === 0 && inChat === false) {
            setInCall(true);
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getUserInfo();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getUserInfo();
  }, [startUnavailability]);

  const handleChangeCall = (event) => {
    if (event.target.checked === true) {
      var start = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
      var end = dayjs().tz('Europe/Paris').add(20, 'minute').format('YYYY-MM-DDTHH:mm');
      handleUnavailable(start, end);
    }
    if (event.target.checked === false) {
      handleAvailable();
    }
    setInCall(event.target.checked);
  };

  const handleChangeChat = (event) => {
    if (event.target.checked === true) {
      var start = dayjs().tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
      var end = dayjs().tz('Europe/Paris').add(20, 'minute').format('YYYY-MM-DDTHH:mm');

      handleUnavailable(start, end);
    }
    if (event.target.checked === false) {
      handleAvailable();
    }
    setInChat(event.target.checked);
  };

  const handleOpenModal = (event) => {
    setOpenModal(true);
  };

  const handleCloseModal = (event) => {
    setOpenModal(false);
  };

  const handleUnavailable = async (start, end) => {
    try {
      setLoading(true);
      const response = await api.putUserInfo({
        userId: user.id,
        available: 0,
        unavailableStartedAt: start,
        unavailableEndedAt: end,
      });
      if (response) {
        setLoading(false);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleUnavailable();
      } else {
        setErrorAPI(true);
      }
    }
  };

  const handleAvailable = async () => {
    try {
      setLoading(true);
      const response = await api.putUserInfo({
        userId: user.id,
        available: 1,
        unavailableStartedAt: null,
        unavailableEndedAt: null,
      });
      if (response) {
        setLoading(false);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleAvailable();
      } else {
        setErrorAPI(true);
      }
    }
  };

  const available = !inCall && !inChat;
  
  return !inProgressActivity ? null : (
    <div className={classes.row}>

      {/* <AlertUser
        alertUser={alertAvailability}
        setAlertUser={() => {
          setAlertAvailability(false);
        }}
        message={"Veuillez l'arrêter ou la prolonger dans la fenêtre de détail d'indisponibilité"}
        title={'Votre indisponibilité se termine bientôt !'}
        type={'warning'}
        position={'left'}
      /> */}

      <div className={classes.chatCallColumn}>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu lors du changement de disponibilité, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        <div className={classes.chatCallRow}>
          <FormControlLabel
            control={<Switch checked={inCall} onChange={handleChangeCall} name="inCall" color="primary" />}
            label="Appel en cours"
          />
          <PhoneInTalkIcon color="primary" />
        </div>
        <div className={classes.chatCallRow}>
          <FormControlLabel
            control={<Switch checked={inChat} onChange={handleChangeChat} name="inChat" color="primary" />}
            label="Discussion en cours"
          />
          <QuestionAnswerIcon color="primary" />
        </div>
      </div>
      <div className={classes.availabilityColumn}>
        {!available ? (
          <div className={classes.availableTextRow}>
            <Typography variant="h6" color="error">
              {user.first_name} {user.last_name} : Indisponible jusqu'a {dayjs(endDate).format('HH[H]mm')}
            </Typography>
            <NotInterestedIcon color="error" />
          </div>
        ) : (
          <div className={classes.availableTextRow}>
            <Typography variant="h6" color="primary">
              {user.first_name} {user.last_name} : Disponible
            </Typography>
            <CheckIcon color="primary" />
          </div>
        )}

        <Button
          size="small"
          variant="contained"
          color="secondary"
          disabled={available || loading}
          className={classes.button}
          onClick={handleOpenModal}
        >
          Détail indisponibilité
        </Button>

        <AvailabilityModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          // setAlertAvailability={setAlertAvailability}
          setAlertAvailability={() => {}}
          userId={user.id}
          available={false}
          setAvailabilityEndDate={setEndDate}
        />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({ 
  inProgressActivity: state.activities.inProgress,
  isUserActive: state.user.isActive
});

const mapDispatchToProps = dispatch => {
  return {
    getInProgressActivity: () => dispatch(getInProgressActivity()),
    getTodayActivities: () => dispatch(getTodayActivities()),
    startActivity: (activityTypeId, specialActivityName, timezoneId, reinfInt) => dispatch(startActivity(activityTypeId, specialActivityName, timezoneId, reinfInt)),
    stopActivity: (id) => dispatch(stopActivity(id)),
    setIsUserActive: (status) => dispatch(setIsUserActive(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityBlock);