import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  margin: {
    marginRight: '1em',
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));

export const TimeSlotsCreationModal = (props) => {
  const { openModal, handleCloseModal, setReloadSlot, reloadSlot } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [alertNegativeDuration, setAlertNegativeDuration] = useState(false);
  const [start, setStart] = useState(dayjs());
  const [end, setEnd] = useState(dayjs());
  const [startHour, setStartHour] = useState('17:00');
  const [endHour, setEndHour] = useState('18:00');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = state;

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleStart = (event) => {
    const newStart = dayjs(event.target.value);

    if (dayjs(end).diff(newStart) < 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setStart(event.target.value);
  };

  const handleEnd = (event) => {
    const newEnd = dayjs(event.target.value);

    if (newEnd.diff(dayjs(start)) < 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setEnd(event.target.value);
  };

  const handleStartHour = (event) => {
    const newStartHour = event.target.value;
    const timeStart = newStartHour.split(':');
    const timeEnd = endHour.split(':');

    if (timeEnd[0] < timeStart[0]) {
      setAlertNegativeDuration(true);
    } else if (timeEnd[0] === timeStart[0] && timeEnd[1] < timeStart[1]) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setStartHour(event.target.value);
  };

  const handleEndHour = (event) => {
    const newEndHour = event.target.value;

    const timeStart = startHour.split(':');
    const timeEnd = newEndHour.split(':');

    if (timeEnd[0] < timeStart[0]) {
      setAlertNegativeDuration(true);
    } else if (timeEnd[0] === timeStart[0] && timeEnd[1] < timeStart[1]) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setEndHour(event.target.value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const startRange = dayjs(start);
    const endRange = dayjs(end);
    const timeStart = startHour.split(':');
    const timeEnd = endHour.split(':');
    const startHourEvent = timeStart[0];
    const startMinEvent = timeStart[1];
    const endHourEvent = timeEnd[0];
    const endMinEvent = timeEnd[1];
    var repeatDay = [];
    let started_at, ended_at;
    let date = dayjs(startRange);

    if (monday) {
      repeatDay.push(2);
    }
    if (tuesday) {
      repeatDay.push(3);
    }
    if (wednesday) {
      repeatDay.push(4);
    }
    if (thursday) {
      repeatDay.push(5);
    }
    if (friday) {
      repeatDay.push(6);
    }
    if (saturday) {
      repeatDay.push(0);
    }
    if (sunday) {
      repeatDay.push(1);
    }

    if (startRange.diff(endRange, 'day') === 0) {
      repeatDay = [0, 1, 2, 3, 4, 5, 6];
    }

    while (dayjs(endRange).diff(dayjs(date)) >= 0) {
      if (repeatDay.includes(date.weekday())) {
        const slotDate = dayjs(date).format('YYYY-MM-DD');
        started_at = dayjs.tz(slotDate, 'Europe/Paris').hour(startHourEvent).minute(startMinEvent).second(0).unix();
        ended_at = dayjs.tz(slotDate, 'Europe/Paris').hour(endHourEvent).minute(endMinEvent).second(0).unix();
        try {
          const response = await api.postOpenSlot(started_at, ended_at);
          if (response) {
            setReloadSlot(!reloadSlot);
            handleCloseModal();
            setIsLoading(false);
          }
        } catch (error) {
          if (
            error.response !== undefined &&
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            await refresh();
            handleSave();
          } else {
            console.log(error);
            setErrorAPI(true);
          }
        }
      }
      date = dayjs(date).add(1, 'day');
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Edition des créneaux d'ouverture
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            "Un problème est survenu lors de l'édition des créneaux, veuillez recharger la page ou réessayer plus tard."
          }
        />
        <DialogContent dividers>
          {alertNegativeDuration && (
            <Alert
              onClose={() => {
                setAlertNegativeDuration(false);
              }}
              severity="warning"
            >
              <AlertTitle>Attention</AlertTitle>
              La fin du créneau ne peut pas être antérieure au début de celui ci.
            </Alert>
          )}

          <div className={classes.row}>
            <TextField
              id="start"
              label="À partir du"
              type="date"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.margin}
              value={dayjs(start).format('YYYY-MM-DD')}
              onChange={handleStart}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: dayjs(end).format('YYYY-MM-DD'),
              }}
            />
            <TextField
              id="end"
              label="Jusqu'au"
              type="date"
              margin="dense"
              variant="outlined"
              fullWidth
              value={dayjs(end).format('YYYY-MM-DD')}
              onChange={handleEnd}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: dayjs(start).format('YYYY-MM-DD'),
              }}
            />
          </div>
          <div className={classes.row}>
            <TextField
              id="startHour"
              label="heure de début"
              type="time"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.margin}
              value={startHour}
              onChange={handleStartHour}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endHour"
              label="heure de fin"
              type="time"
              margin="dense"
              variant="outlined"
              fullWidth
              value={endHour}
              onChange={handleEndHour}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.row}>
            <FormControlLabel
              control={<Checkbox checked={monday} color="primary" onChange={handleChange} name="monday" />}
              label="Lu"
            />
            <FormControlLabel
              control={<Checkbox checked={tuesday} color="primary" onChange={handleChange} name="tuesday" />}
              label="Ma"
            />
            <FormControlLabel
              control={<Checkbox checked={wednesday} color="primary" onChange={handleChange} name="wednesday" />}
              label="Me"
            />
            <FormControlLabel
              control={<Checkbox checked={thursday} color="primary" onChange={handleChange} name="thursday" />}
              label="Je"
            />
            <FormControlLabel
              control={<Checkbox checked={friday} color="primary" onChange={handleChange} name="friday" />}
              label="Ve"
            />
            <FormControlLabel
              control={<Checkbox checked={saturday} color="primary" onChange={handleChange} name="saturday" />}
              label="Sa"
            />
            <FormControlLabel
              control={<Checkbox checked={sunday} color="primary" onChange={handleChange} name="sunday" />}
              label="Di"
            />
          </div>

          <div className={classes.row}>
            {!isLoading ? (
              <Button
                variant="contained"
                margin="dense"
                color="secondary"
                onClick={handleSave}
                disabled={alertNegativeDuration}
                className={classes.margin}
              >
                Ajouter ce créneau
              </Button>
            ) : (
              <CircularProgress color="secondary" className={classes.loading} />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
