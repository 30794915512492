// ------------------------------------------------------------------------ //
//  IMPORT
// ------------------------------------------------------------------------ //
import React, { useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import {
  updateUserMemo
} from './../../actions/memo';

// ------------------------------------------------------------------------ //
//  STYLES
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rowDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  colTimezone: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2em',
  },
  rowTimezone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));

// ------------------------------------------------------------------------ //
//  COMPONENTS
// ------------------------------------------------------------------------ //
const ModificationModal = (props) => {

  const { 
    openModal, 
    handleCloseModal, 
    memo
  } = props;

  const id = memo[0];
  const [date, setDate] = useState(dayjs(memo[1]).format('YYYY-MM-DDTHH:mm'));
  const [description, setDescription] = useState(memo[2]);
  const classes = useStyles();

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Modifier le mémo suivant
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            id="date"
            label="Date du mémo"
            type="datetime-local"
            margin="dense"
            variant="outlined"
            fullWidth
            value={date}
            onChange={(event) => {
              setDate(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            margin="dense"
            id="description"
            variant="outlined"
            label="Description du mémo"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>

          <Button onClick={() => {
            props.updateUserMemo(id, dayjs.tz(date, 'Europe/Paris'), description);
            handleCloseModal();
          }} disabled={!description} color="primary">
            Enregistrer la correction
          </Button>
        </DialogActions>
        
      </Dialog>
    </div>
  );
};

// ------------------------------------------------------------------------ //
//  REDUX
// ------------------------------------------------------------------------ //
const mapStateToProps = (state) => ({ 
  data: state.memo.data,
});

const mapDispatchToProps = dispatch => {
  return {
    updateUserMemo: (id, date, description) => {
      return dispatch(updateUserMemo(id, date, description));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModificationModal);