import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  rowAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin: {
    marginTop: '1em',
    marginRight: '1em',
    marginBottom: '1em',
  },
  marginS: {
    marginTop: '1em',

    marginBottom: '1em',
    marginRight: '0.2em',
  },
}));

export const CreationModal = (props) => {
  const { openModal, handleCloseModal, slot, userId, reload, setReload } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [startEvent, setStartEvent] = useState(dayjs(slot.start).format('YYYY-MM-DDTHH:mm'));
  const [endEvent, setEndEvent] = useState(dayjs(slot.end).format('YYYY-MM-DDTHH:mm'));
  const [status, setStatus] = useState('available');
  const [reinforcement, setReinforcement] = useState(false);
  const [repeat, setRepeat] = useState('never');
  const [startRepeat, setStartRepeat] = useState(slot.start);
  const [endRepeat, setEndRepeat] = useState(slot.start);
  const [alertMoreThanADay, setAlertMoreThanADay] = useState(false);
  const [alertNegativeDuration, setAlertNegativeDuration] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);

  const weekDay = () => {
    switch (dayjs(startEvent).weekday()) {
      case 0:
        return 'samedi';

      case 1:
        return 'dimanche';

      case 2:
        return 'lundi';

      case 3:
        return 'mardi';

      case 4:
        return 'mercredi';

      case 5:
        return 'jeudi';

      case 6:
        return 'vendredi';

      default:
        return 'samedi';
    }
  };

  const handleStart = (event) => {
    const start = dayjs(event.target.value);
    const end = dayjs(endEvent);

    if (end.format('DD/MM/YYYY') !== start.format('DD/MM/YYYY')) {
      setAlertMoreThanADay(true);
    } else {
      setAlertMoreThanADay(false);
    }

    if (end.diff(start) <= 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setStartEvent(event.target.value);
  };

  const handleEnd = (event) => {
    const start = dayjs(startEvent);
    const end = dayjs(event.target.value);

    if (end.format('DD/MM/YYYY') !== start.format('DD/MM/YYYY')) {
      setAlertMoreThanADay(true);
    } else {
      setAlertMoreThanADay(false);
    }

    if (end.diff(start) <= 0) {
      setAlertNegativeDuration(true);
    } else {
      setAlertNegativeDuration(false);
    }

    setEndEvent(event.target.value);
  };

  const handleStatus = (props) => {
    setStatus(props);
  };

  const handleReinforcement = (event) => {
    setReinforcement(event.target.checked);
  };

  const handleRepeat = (props) => {
    setRepeat(props);
  };

  const handleStartRepeat = (event) => {
    setStartRepeat(event.target.value);
  };

  const handleEndRepeat = (event) => {
    setEndRepeat(event.target.value);
  };

  const handleSaveAndClose = async () => {
    let reinfInt;
    if (reinforcement) {
      reinfInt = 1;
    } else {
      reinfInt = 0;
    }

    let statusId;
    if (status === 'available') {
      statusId = 1;
    } else {
      statusId = 2;
    }
    if (repeat === 'never') {
      try {
        const response = await api.postEvent(
          userId,
          dayjs.tz(startEvent, 'Europe/Paris').unix(),
          dayjs.tz(endEvent, 'Europe/Paris').unix(),
          statusId,
          reinfInt
        );
        if (response) {
          handleCloseModal();
          setReload(!reload);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        console.error(error);
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          handleSaveAndClose();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    } else if (repeat === 'everyday') {
      const startHourEvent = dayjs(startEvent).hour();
      const startMinEvent = dayjs(startEvent).minute();
      const endHourEvent = dayjs(endEvent).hour();
      const endMinEvent = dayjs(endEvent).minute();
      let start, end;

      let date = dayjs(startRepeat).format('YYYY-MM-DD');

      while (dayjs(endRepeat).diff(date) >= 0) {
        start = dayjs.tz(date, 'Europe/Paris').hour(startHourEvent).minute(startMinEvent).unix();
        end = dayjs.tz(date, 'Europe/Paris').hour(endHourEvent).minute(endMinEvent).unix();

        try {
          const response = await api.postEvent(userId, start, end, statusId, reinfInt);
          if (response) {
            handleCloseModal();
            setReload(!reload);
          } else {
            setErrorAPI(true);
          }
        } catch (error) {
          if (
            error.response !== undefined &&
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            await refresh();
            handleSaveAndClose();
          } else {
            console.log(error);
            setErrorAPI(true);
          }
        }

        date = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
      }
    } else if (repeat === 'everyweek') {
      const startHourEvent = dayjs(startEvent).hour();
      const startMinEvent = dayjs(startEvent).minute();
      const endHourEvent = dayjs(endEvent).hour();
      const endMinEvent = dayjs(endEvent).minute();
      let startDate, endDate;

      let weekday = dayjs(startEvent).weekday();

      let date = dayjs(startRepeat).weekday(weekday).format('YYYY-MM-DD');

      if (dayjs(date).diff(dayjs(startRepeat)) < 0) {
        date = dayjs(date).add(7, 'day').format('YYYY-MM-DD');
      }

      while (dayjs(endRepeat).diff(date) >= 0) {
        startDate = dayjs.tz(date, 'Europe/Paris').hour(startHourEvent).minute(startMinEvent).unix();
        endDate = dayjs.tz(date, 'Europe/Paris').hour(endHourEvent).minute(endMinEvent).unix();

        try {
          const response = await api.postEvent(userId, startDate, endDate, statusId, reinfInt);
          if (response) {
            handleCloseModal();
            setReload(!reload);
          } else {
            setErrorAPI(true);
          }
        } catch (error) {
          if (
            error.response !== undefined &&
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            await refresh();
            handleSaveAndClose();
          } else {
            console.log(error);
            setErrorAPI(true);
          }
        }

        date = dayjs(date).add(7, 'day').format('YYYY-MM-DD');
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Editer ma disponibilité du {weekDay()} {dayjs(startEvent).format('DD MMMM')}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            "Un problème est survenu lors de l'enregistrement de la disponibilité, veuillez recharger la page ou réessayer plus tard."
          }
        />
        {alertMoreThanADay && (
          <Alert
            onClose={() => {
              setAlertMoreThanADay(false);
            }}
            severity="warning"
          >
            <AlertTitle>Attention</AlertTitle>
            Une disponibilité ne peut pas s'étendre sur plusieurs jours. Veuillez utiliser l'outil de répétition pour
            les disponibilités récurrentes.
          </Alert>
        )}
        {alertNegativeDuration && (
          <Alert
            onClose={() => {
              setAlertNegativeDuration(false);
            }}
            severity="warning"
          >
            <AlertTitle>Attention</AlertTitle>
            La fin de votre disponibilité est inférieure au début de celle ci.
          </Alert>
        )}
        <DialogContent dividers>
          <div className={classes.row}>
            <TextField
              id="start"
              label="Début"
              type="datetime-local"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.margin}
              value={dayjs(startEvent).format('YYYY-MM-DDTHH:mm')}
              onChange={handleStart}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="end"
              label="Fin"
              type="datetime-local"
              margin="dense"
              variant="outlined"
              fullWidth
              value={dayjs(endEvent).format('YYYY-MM-DDTHH:mm')}
              onChange={handleEnd}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <Divider />
          <div className={classes.rowAlign}>
            <Button
              variant={status === 'available' ? 'contained' : 'outlined'}
              color={status === 'available' ? 'secondary' : 'primary'}
              margin="dense"
              className={classes.margin}
              onClick={() => handleStatus('available')}
            >
              Disponible
            </Button>
            <Button
              variant={status === 'reserved' ? 'contained' : 'outlined'}
              color={status === 'reserved' ? 'secondary' : 'primary'}
              margin="dense"
              onClick={() => handleStatus('reserved')}
            >
              Reserviste
            </Button>
            <FormControlLabel
              control={
                <Checkbox checked={reinforcement} onChange={handleReinforcement} name="reinforcement" color="primary" />
              }
              label="Renfort"
              className={classes.margin}
            />
          </div>

          <Divider />
          <Typography variant="h6" className={classes.title}>
            Répéter :
          </Typography>
          <div className={classes.rowCenter}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={repeat === 'everyday' ? true : false}
                  onChange={() => handleRepeat('everyday')}
                  name="repeatDay"
                  color="primary"
                />
              }
              label="tous les jours"
              className={classes.margin}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={repeat === 'everyweek' ? true : false}
                  onChange={() => handleRepeat('everyweek')}
                  name="repeatWeek"
                  color="primary"
                />
              }
              label={`tous les ${weekDay()}`}
              className={classes.margin}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={repeat === 'never' ? true : false}
                  onChange={() => handleRepeat('never')}
                  name="norepeat"
                  color="primary"
                />
              }
              label="ne pas répéter"
              className={classes.margin}
            />
          </div>
          {(repeat === 'everyday' || repeat === 'everyweek') && (
            <div className={classes.rowCenter}>
              <Typography variant="subtitle1" className={classes.marginS}>
                à partir du :{' '}
              </Typography>
              <TextField
                id="start"
                label="Début"
                type="date"
                margin="dense"
                variant="outlined"
                className={classes.marginS}
                value={dayjs(startRepeat).format('YYYY-MM-DD')}
                onChange={handleStartRepeat}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography variant="subtitle1" className={classes.marginS}>
                jusqu'au :{' '}
              </Typography>
              <TextField
                id="end"
                label="Fin"
                type="date"
                margin="dense"
                variant="outlined"
                value={dayjs(endRepeat).format('YYYY-MM-DD')}
                onChange={handleEndRepeat}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSaveAndClose} disabled={alertMoreThanADay || alertNegativeDuration} color="primary">
            Enregistrer cette disponibilité
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
