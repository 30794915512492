import Echo from 'laravel-echo';

window.io = require("socket.io-client");
window.Pusher = require('pusher-js');

class Broadcast {
  init(cb) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
      wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
      forceTLS: false,
      encrypted: false,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
  }

  inquiries(cb) {
    window.Echo
      .channel('inquiries')
      .listen('.created', (data) => {
        console.log('BROADCASTED INQUIRY CREATED =>', data);
        cb(data);
      })
      .listen('.updated', (data) => {
        console.log('BROADCASTED INQUIRY CREATED =>', data);
        cb(data);
      });
  }

  activities(cb) {
    window.Echo
      .channel('board_users')
      .listen('.created', (data) => {
        console.log('BROADCASTED ACTIVITY CREATED =>', data);
        cb(data);
      })
      .listen('.updated', (data) => {
        console.log('BROADCASTED ACTIVITY UPDATED =>', data);
        cb(data);
      });
  }
}

export default new Broadcast();
