import Auth from './../services/Auth';

// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import { 
  LOAD_SUMMARY_PROCESSED_INQUIRIES,
  LOAD_SUMMARY_DIRECT_INQUIRIES
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
  processed: [],
  direct: []
};

// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {
  switch (action.type) {  

    case LOAD_SUMMARY_DIRECT_INQUIRIES:
      return {
        ...state,
        direct: action.payload.map((inquiry) => parseInquiry(inquiry))
      };

    case LOAD_SUMMARY_PROCESSED_INQUIRIES:
      return {
        ...state,
        processed: action.payload.map((inquiry) => parseInquiry(inquiry))
      };

    default:
      return state;
  }
};

export default reducer;


// ------------------------------------------------------------------------ //
//  HELPERS
// ------------------------------------------------------------------------ //
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const parseInquiry = (inquiry) => {
  const basics = Auth.basics();
  let type = basics.inquiry_types.find((x) => x.id === inquiry.type_id);
  let level = basics.levels.find((x) => x.id === inquiry.level_id);

  inquiry.studentInfo = inquiry.student !== null 
    ? capitalize(inquiry.student.first_name) + ' ' + capitalize(inquiry.student.last_name) 
    : '';

  inquiry.teacherInfo = inquiry.teacher !== null 
    ? capitalize(inquiry.teacher.first_name) + ' ' + capitalize(inquiry.teacher.last_name) 
    : '';

  inquiry.type = type ? type.name : '';
  inquiry.level_name = level ? level.name : '';
  inquiry.level_group = level ? level.group.name : '';
  inquiry.subject = 'non renseigné';

  if (inquiry.subject_id !== 0) {
    let subject = basics.subjects.find((x) => x.id === inquiry.subject_id);

    if(subject) {
      inquiry.subject = subject.name;
    }
  } 

  return inquiry;
}