import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import api from '../../api/api';
import {
  Dialog,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import BasicsContext from '../../context/BasicsContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em',
  },
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  text: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: 200,
  },
  button: { marginRight: theme.spacing(2) },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));

export const RequestAdjustModal = (props) => {
  const { openModal, handleCloseModal, requestNb, date, reload, setReload } = props;
  const classes = useStyles();
  const [correction, setCorrection] = useState(0);
  const [note, setNote] = useState('');
  const [errorAPI, setErrorAPI] = useState(false);
  const basics = useContext(BasicsContext);
  const refresh = useContext(refreshFunctionContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleCorrection = (event) => {
    const value = parseInt(event.target.value);
    setCorrection(value);
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };

  const handleSaveAndClose = async () => {
    setErrorAPI(false);
    const typeId = 4;
    const statusId = 12;
    let teacherId, switchmanId;
    if (basics.user.role.includes('teacher')) {
      teacherId = basics.user.teacher_id;
      switchmanId = 0;
    } else if (basics.user.role.includes('switchman')) {
      teacherId = 0;
      switchmanId = basics.user.switchman_id;
    } else {
      teacherId = 0;
      switchmanId = 0;
    }
    try {
      setIsLoading(true);
      const response = await api.postInquiry({
        typeId: typeId,
        statusId: statusId,
        adjustNote: note,
        invoicesWeight: correction,
        teacherId: teacherId,
        switchmanId: switchmanId,
      });
      if (response) {
        setIsLoading(false);
        setReload(!reload);
        handleCloseModal();
        setCorrection(0);
        setNote('');
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSaveAndClose();
      } else {
        setErrorAPI(true);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Ajustement des requêtes du {dayjs(date).format('DD/MM/YYYY')}
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Nombre de requêtes enregistrées : {requestNb}</DialogContentText>
          <TextField
            type="number"
            id="correction"
            margin="dense"
            value={correction}
            onChange={handleCorrection}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: requestNb * -1,
              },
            }}
            label="Correction"
            fullWidth
          />
          <DialogContentText>Soit {requestNb + correction} requêtes en tout</DialogContentText>
          <TextField margin="dense" id="note" label="Note" value={note} onChange={handleNote} fullWidth />
        </DialogContent>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        {!isLoading ? (
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSaveAndClose} disabled={note === '' ? true : false} color="primary">
              Enregistrer la correction
            </Button>
          </DialogActions>
        ) : (
          <CircularProgress className={classes.loading} />
        )}
      </Dialog>
    </div>
  );
};
