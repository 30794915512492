import React from 'react';
import { Table } from '../globalComponents/Table';
import dayjs from 'dayjs';
import { SubjectTag } from '../globalComponents/SubjectTag';

export const TableTreatedRequest = (props) => {
  const { date } = props;

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'teacherInfo',
      label: 'Teacher',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <SubjectTag text={value} />,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
  ];

  return <Table title={'Requêtes traitées le ' + dayjs(date).format('DD/MM/YYYY')} data={props.data} columns={columns} />;
};

export const TableIngoingRequest = (props) => {

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'teacherInfo',
      label: 'Teacher',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
  ];

  return <Table title={'Requêtes en cours'} data={props.data} columns={columns} />;
};
