import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Auth from './../../services/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SubjectTag = (props) => {

  const { subjects } = Auth.basics();
  const filteredSubjects = subjects.filter(subject => props.text === subject.name);
  let subject = filteredSubjects[0] !== undefined ? filteredSubjects[0] : null;

  if(['Documentaliste', 'Grand Oral'].includes(props.text)) {
    subject = {
      color: props.text === "Documentaliste" ? "6600a7" : "6600a7",
      name: props.text,
      font_awesome_class: props.text === "Documentaliste" ? "fa-glasses" : "fa-chalkboard-teacher",
    }
  }

  const useStyles = makeStyles((theme) => ({
    tag: {
      backgroundColor: (subject !== null && subject.color !== undefined) ? '#' + subject.color : 'black',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 10,
      paddingRight: 10,
      color: 'white',
      borderRadius: 3
    },
    icon: {
      marginRight: 10
    }
  }));

  const classes = useStyles();

  return (
    <span className={classes.tag}>
      {subject.font_awesome_class !== undefined &&
        <FontAwesomeIcon icon={"fa-solid " + subject.font_awesome_class} className={classes.icon} />
      }
      {props.text}
    </span>
  );
};
