import React from 'react';
import { connect } from 'react-redux';
import { Table } from '../globalComponents/Table';
import dayjs from 'dayjs';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Activity } from '../../models/Activity';
import Auth from './../../services/Auth';

import {
  getTodayActivities
} from './../../actions/activities';

const TableActivity = (props) => {

  const {
    activities
  } = props;

  const basics = Auth.basics();
  const activityTypes = basics ? basics.board_activity_types : null;
  const timezones = basics ? basics.timezones : null;
  const data = activities.map(obj => new Activity(obj).get());

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'ended_at',
      label: 'Heure de fin',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'started_at',
      label: 'Début',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('HH:mm'),
      },
    },

    {
      name: 'duration',
      label: 'Durée',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          
          return data[index].is_running 
              ? 'En cours' //'Depuis ' + value
              : value;
        },
      },
    },
    {
      name: 'manual',
      label: 'Mode de saisie',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? (value = 'Manuelle') : (value = 'Directe')),
      },
    },
    {
      name: 'activity_type_id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'special_activity_name',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'activity_type_id',
      label: 'Activité',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const type = activityTypes.find((x) => x.id === value);

          return type ? type.name : null;
        },
      },
    },

    {
      name: 'timezone_id',
      label: 'Zone',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const timezone = timezones.find((x) => x.id === value);

          return timezone ? timezone.display_name : null;
        },
      },
    },
    {
      name: 'reinforcement',
      label: 'Renfort',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
  ];

  const optionsAll = {
    filter: true,
    filterType: 'checkbox',
    download: true,
    print: false,
    pagination: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [1, 5, 10, 20, 50, 100],
    selectableRows: 'none',
    // setRowProps: (row, dataIndex) => {
    //   if (data[dataIndex].is_running) {
    //     return {
    //       style: { 
    //         background: "#cceecc" 
    //       }
    //     };
    //   }
    // },
    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
      toolbar: {
        search: 'Rechercher',
        viewColumns: 'Colonnes visibles',
        filterTable: 'Filtres',
      },
      filter: {
        all: 'Tous',
        title: 'Filtres',
        reset: 'Effacer tous les filtres',
      },
      viewColumns: {
        title: 'Colonnes visibles',
        titleAria: 'Montrer/Cacher les colonnes',
      },
    },
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  return <Table 
    title={'Activitées du jour'} 
    data={data} 
    columns={columns} 
    options={optionsAll} 
  />;
}

const mapStateToProps = (state) => ({ 
  activities: state.activities.today,
  refreshedAt: state.activities.refreshedAt
});

const mapDispatchToProps = dispatch => {
  return {
    getTodayActivities: () => {
      return dispatch(getTodayActivities());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableActivity);
