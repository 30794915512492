import dayjs from 'dayjs';

export class Activity {

    constructor(attributes) {
        this.attributes = attributes;
    }

    getId() {
        return this.attributes.id;
    }

    get() {
        return {
            id: this.getId(),
            user_id: this.getUserId(),
            ended_at: this.getEndedAt(),
            started_at: this.getStartedAt(),
            manual: this.getIsManual(),
            duration: this.getDuration(),
            activity_type_id: this.getTypeId(),
            special_activity_name: this.getSpecialName(),
            timezone_id: this.getTimezoneId(),
            reinforcement: this.getIsReinforcement(),
            is_running: this.getIsRunning()
        };
    }

    getUserId() {
        return this.attributes.user_id;
    }

    getStartedAt() {
        return this.attributes.started_at;
    }

    getEndedAt() {
        return this.attributes.ended_at;
    }

    getIsManual() {
        return this.attributes.manual;
    }

    getIsRunning() {
        return this.getEndedAt() === null;
    }

    getDuration() {
        const ended_at = this.getEndedAt();
        const started_at = dayjs(this.getStartedAt());

        const end = ended_at === null ? dayjs() : dayjs(ended_at);
        const duration = end.diff(started_at, 'minutes');

        var hours = Math.floor(duration / 60);
        var minutes = duration % 60;

        if(hours === 0 && minutes === 0) {
            return end.diff(started_at, 'seconds') + 'sec';
        } else if (hours === 0) {
            return minutes + 'min';
        } else if (minutes === 0) {
            return hours + 'h';
        } else {
            return hours + 'h' + minutes + 'min'
        }
    }

    getTypeId() {
        return this.attributes.activity_type_id;
    }

    getSpecialName() {
        return this.attributes.special_activity_name;
    }

    getTimezoneId() {
        return this.attributes.timezone_id;
    }

    getIsReinforcement() {
        return this.attributes.reinforcement;
    }

}
