import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Auth from './../../services/Auth';

// ------------------------------------------------------------------------ //
// STYLES
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
  },
}));

// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
export const ActivityTypeSelect = (props) => {
  const { typeId, setTypeId, option } = props;
  const { board_activity_types } = Auth.basics();
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="activityTypeSelectLabel">Type d'activité</InputLabel>
      <Select
        labelId="activityTypeSelectLabel"
        id="activityTypeSelect"
        value={typeId}
        onChange={(event) => setTypeId(event.target.value)}
        label="Groupe de travail"
      >
        {option === 'all' && (
          <MenuItem key={0} value={0}>
            Tous types
          </MenuItem>
        )}
        {board_activity_types.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
