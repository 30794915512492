import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabDirect from '../../pages/tabs/Direct';
import TabMemo from '../../pages/tabs/Memo';
import TabSummary from '../../pages/tabs/Summary';
import TabProcessed from '../../pages/tabs/Processed';
import { TabActivity } from '../../pages/tabs/Activity';
import { TabCalendar } from '../../pages/tabs/Calendar';
import { TabJournal } from '../../pages/tabs/Journal';
import { Loading } from '../globalComponents/Loading';
import BasicsContext from '../../context/BasicsContext';
import Auth from '../../services/Auth';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const TDBTabs = (props) => {
  const {
    setSettings,
    setReloadMemo,
    reloadMemo,
    reloadInquiries,
    setReloadInquiries,
    activeHourSelected,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Activité" {...a11yProps(0)} value={0} />
          <Tab label="Horodateur" {...a11yProps(1)} value={1} />
          <Tab label="Journal" {...a11yProps(2)} value={2} />
          <Tab label="Direct" {...a11yProps(3)} value={3} />
          {Auth.hasRole('board_admin') && <Tab label="Traitées" {...a11yProps(4)} value={4} />}
          <Tab label="Agenda" {...a11yProps(5)} value={5} />
          <Tab label="Mémo" {...a11yProps(6)} value={6} />
          
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* Activité */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {user ? <TabActivity activeHourSelected={activeHourSelected} /> : <Loading />}
        </TabPanel>

         {/* Horodateur */}
         <TabPanel value={value} index={1} dir={theme.direction}>
          {user ? <TabSummary /> : <Loading />}
        </TabPanel>

        {/* Journal */}
        <TabPanel value={value} index={2} dir={theme.direction}>
          {user ? <TabJournal /> : <Loading />}
        </TabPanel>

        {/* Direct */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          {user ? (
            <TabDirect
              setSettings={setSettings}
              reloadInquiries={reloadInquiries}
              setReloadInquiries={setReloadInquiries}
            />
          ) : (
            <Loading />
          )}
        </TabPanel>

        {/* Traitées */}
        {Auth.hasRole('board_admin') ? (
          <TabPanel value={value} index={4} dir={theme.direction}>
            {user ? <TabProcessed /> : <Loading />}
          </TabPanel>
        ) : (
          <></>
        )}

        {/* Agenda */}
        <TabPanel value={value} index={5} dir={theme.direction}>
          {user ? <TabCalendar /> : <Loading />}
        </TabPanel>

        {/* Memo */}
        <TabPanel value={value} index={6} dir={theme.direction}>
          {user ? <TabMemo setReloadMemo={setReloadMemo} reloadMemo={reloadMemo} /> : <Loading />}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
