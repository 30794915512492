import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import BasicsContext from '../../context/BasicsContext';

const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: '100%',
  },
}));
export const TimezoneSelect = (props) => {
  const classes = useStyles();
  const basics = useContext(BasicsContext);
  const timezones = basics ? basics.timezones : null;

  const { timezoneId, handleTimezoneId, disabled, option } = props;

  return (
    <FormControl variant="outlined" margin="dense" className={classes.selectInput}>
      <InputLabel id="timezoneSelectLabel">Zone d'activité</InputLabel>
      {timezones && (
        <Select
          labelId="timezoneSelectLabel"
          id="timezoneSelect"
          value={timezoneId}
          defaultValue=""
          fullwidth="true"
          onChange={handleTimezoneId}
          disabled={disabled}
          label="Zone d'activité"
        >
          {option === 'all' && (
            <MenuItem key={0} value={0}>
              Toutes les zones
            </MenuItem>
          )}
          {timezones.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.display_name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
