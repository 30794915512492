import React, { useState } from 'react';
import { CssBaseline, Button, TextField, Typography, Container, Avatar, CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Redirect } from 'react-router-dom';
import Auth from './../services/Auth';
import { ErrorAPI } from '../components/globalComponents/ErrorAPI';
import logoPE from '../assets/logoPE.png';
import useStyles from './styles/SignIn';

export default function SignIn() {

  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [loginVerified, setLoginVerified] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const [errorUserInfo, setErrorUserInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setErrorAPI(false);
    setErrorUserInfo(false);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setErrorAPI(false);
    setErrorUserInfo(false);
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorAPI(false);
    setErrorUserInfo(false);

    const isLogged = await Auth.login(email, password);

    if(isLogged === true) {
      setLoginVerified(true);
      setLoading(false);
    } else {
      setLoading(false);
      setErrorAPI(true);
    }
  };

  if (loginVerified) {
    return <Redirect push to={`/`}></Redirect>;
  }

  return (
    <div className={classes.background}>
      <img src={logoPE} alt="img" className={classes.img} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={'Un problème est survenu dans le tableau de bord Prof Express, veuillez recharger la page ou réessayer plus tard.'}
        />

        <ErrorAPI
          errorAPI={errorUserInfo}
          setErrorAPI={setErrorUserInfo}
          message={'Veuillez vérifier que votre identifiant Prof Express et votre mot de passe sont corrects.'}
        />
        
        <div className={classes.paper}>
          
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          
          <Typography component="h1" variant="h5">
            Connectez-vous
          </Typography>

          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Identifiant (P.E)"
              name="email"
              value={email}
              onChange={handleEmail}
              autoComplete="email"
              autoFocus
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              value={password}
              onChange={handlePassword}
              autoComplete="current-password"
            />

            {loading ? (
              <CircularProgress className={classes.loading} />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={login}
              >
                Se connecter
              </Button>
            )}

          </form>
          
        </div>
      </Container>
    </div>
  );
}
