import api from './../api/api';

class Auth {

  isBoardSuperAdmin()
  {
    return this.hasRole('board_super_admin');
  }

  isBoardAdmin()
  {
    return this.hasRole('board_admin');
  }

  isSuperTeacher()
  {
    return this.hasRole('super_teacher');
  }

  isTeacher()
  {
    return this.hasRole('teacher');
  }

  isSuperSwitchman()
  {
    return this.hasRole('super_switchman');
  }

  isSwitchman()
  {
    return this.hasRole('switchman');
  }

  /**
   * @param  {} token
   */
  setRefreshToken(token) {
    localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN, token);
  }

  /**
   */
  getRefreshToken() {
    return localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN);
  }

  /**
   * @param  {} token
   */
  setAccessToken(token) {
    localStorage.setItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN, token);
  }
  /**
   */
  getAccessToken() {
    return localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN);
  }

  hasRole(role) {
    const user = this.user();
    const roles = user.role.split('|');

    return roles && roles.filter((r) => (r === role)).length > 0 ? true : false;
  }

  /**
   */
  user() {
    let user = localStorage.getItem('user');

    return user ? JSON.parse(user) : null;
  }
  /**
   */
  basics() {
    let basics = localStorage.getItem('basics');

    return basics ? JSON.parse(basics) : null;
  }

  /**
   */
  isAuthentificated() {
    return this.user() ? true : false;
  }

  /**
   * Remove all local session and request API for change user status
   */
  async logout() {
    let user = this.user().user;


    if (user.id !== undefined) {
      try {
        api.putUserInfo({
          userId: user.id,
          statusId: 2,
        });
      } catch (error) {
        // setErrorAPI(true);
      }
    }

    localStorage.clear();
  }

  /**
   * Sign user and create session
   *
   * @param  string email
   * @param  string password
   */
  async login(email, password) {
    const {access_token, refresh_token} = await api.signIn(email, password);

    if(access_token && refresh_token) {
      this.setRefreshToken(refresh_token);
      this.setAccessToken(access_token);

      const basics = await api.getBasics();

      if(!basics.user.id) {
        return false;
      }

      const user = await api.getUserInfo(basics.user.id);

      if(!user) {
        return false;
      }

      localStorage.setItem('basics', JSON.stringify(basics));
      localStorage.setItem('user', JSON.stringify(user));

      if (user.user.id !== undefined) {
        try {
          api.putUserInfo({
            userId: user.user.id,
            statusId: 1,
          });
        } catch (error) {
          // setErrorAPI(true);
        }
      }

      return true;
    }

    return false;
  }
}

export default new Auth();
