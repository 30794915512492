// ------------------------------------------------------------------------ //
//  IMPORT
// ------------------------------------------------------------------------ //
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import useStyles from './../styles/Memo';
import TableMemo from '../../components/memo/TableMemo';

import {
  getUserMemo,
  createUserMemo,
  updateUserMemo,
  deleteUserMemo
} from './../../actions/memo';

// ------------------------------------------------------------------------ //
//  COMPONENT
// ------------------------------------------------------------------------ //
const TabMemo = (props) => {
  const { memo, setReloadMemo, reloadMemo } = props;
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(dayjs().add(30, 'minute').format('YYYY-MM-DDTHH:mm'));

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Ajouter un nouveau mémo :
      </Typography>
      <div className={classes.row}>
        <TextField
          className={classes.inputDescription}
          margin="dense"
          id="description"
          variant="outlined"
          label="Description du mémo"
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
        <TextField
          className={classes.inputDate}
          id="date"
          label="Date du mémo"
          type="datetime-local"
          margin="dense"
          variant="outlined"
          value={date}
          onChange={(event) => {
            setDate(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" margin="dense" color="secondary" disabled={!description} onClick={() => props.createUserMemo(date, description)}>
          Ajouter
        </Button>
      </div>
      <TableMemo memo={memo} setReloadMemo={setReloadMemo} reloadMemo={reloadMemo} />
    </div>
  );
};

// ------------------------------------------------------------------------ //
//  REDUX
// ------------------------------------------------------------------------ //
const mapStateToProps = (state) => ({ 
  memo: state.memo.data,
});

const mapDispatchToProps = dispatch => {
  return {
    getUserMemo: () => {
      return dispatch(getUserMemo());
    },
    createUserMemo: (date, description) => {
      return dispatch(createUserMemo(date, description));
    },
    updateUserMemo: (id, date, description) => {
      return dispatch(updateUserMemo(id, date, description));
    },
    deleteUserMemo: (id) => {
      return dispatch(deleteUserMemo(id));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMemo);

