import React, { useState, useContext, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import Auth from './../../services/Auth';
import { Loading } from '../globalComponents/Loading';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import { CreationModal } from './CreationModal';
import { ModificationModal } from './ModificationModal';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Paris');
dayjs.locale('fr');
dayjs.updateLocale('fr', { weekStart: 6 });

const localizer = momentLocalizer(dayjs);

const messages = {
  allDay: 'Jour entier',
  previous: '<',
  next: '>',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Evenement',
  showMore: (total) => `+ (${total}) Evenements`,
};

export const MyCalendar = (props) => {
  const { userId, reload, setReload } = props;
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [startDate, setStartDate] = useState(dayjs().hour(0).minute(0).second(0).weekday(0));
  const [endDate, setEndDate] = useState(dayjs().hour(23).minute(59).second(59).weekday(6));
  const [events, setEvents] = useState();
  const hourMin = dayjs().startOf('day').add(9, 'hour').toDate();
  const hourMax = dayjs().startOf('day').add(23, 'hour').toDate();
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const [openModificationModal, setOpenModificationModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [openSlots, setOpenSlots] = useState();
  const [isSelectable, setSelectable] = useState(true);

  useEffect(() => {
    const { user } = Auth.user();
    if(user) {
      setSelectable(userId === user.id);
    }
  }, [refresh, userId, reload]);

  useEffect(() => {
    const getOpenSlots = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getOpenSlots({
          startDate: dayjs.tz(startDate, 'Europe/Paris').unix(),
          endDate: dayjs.tz(endDate, 'Europe/Paris').unix(),
        });
        if (response) {
          //TODO : map start et end comme pour event
          const slots = response.map((slot) => {
            slot.start = dayjs(slot.started_at).toDate();
            slot.end = dayjs(slot.ended_at).toDate();
            return slot;
          });
          setOpenSlots(slots);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getOpenSlots();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    const getEvents = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getEventsUser({
          userId: userId,
          startDate: dayjs.tz(startDate, 'Europe/Paris').unix(),
          endDate: dayjs.tz(endDate, 'Europe/Paris').unix(),
        });
        if (response) {
          const events = response.map((event) => {
            event.start = dayjs(event.started_at).toDate();
            event.end = dayjs(event.ended_at).toDate();
            return event;
          });
          setEvents(events);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getEvents();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getOpenSlots();
    getEvents();
  }, [refresh, startDate, endDate, userId, reload]);

  const eventStyleGetter = (event) => {
    let backgroundColor;
    if (event.status_id === 1 && event.reinforcement === 0) {
      backgroundColor = '#C8DF52';
    } else if (event.status_id === 1 && event.reinforcement === 1) {
      backgroundColor = '#6B8E23';
    } else if (event.status_id === 2 && event.reinforcement === 0) {
      backgroundColor = '#F8EA8C';
    } else if (event.status_id === 2 && event.reinforcement === 1) {
      backgroundColor = '#E1C340';
    } else {
      backgroundColor = '#e6e6e6';
    }

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    };
    return { style };
  };

  const slotStyleGetter = (date) => {
    let backgroundColor = '#efefef';
    let i = 0;
    const dateCheck = dayjs(date);
    let start;
    let end;

    if (openSlots) {
      while (i < openSlots.length && backgroundColor === '#efefef') {
        start = dayjs(openSlots[i].start);
        end = dayjs(openSlots[i].end);

        if (dateCheck.diff(start) >= 0 && dateCheck.diff(end) < 0) {
          backgroundColor = '#ffffff';
        }
        i++;
      }
    }

    var style = {
      backgroundColor: backgroundColor,
      opacity: 1,
      display: 'block',
    };
    return { style };
  };

  const onRangeChange = (range) => {
    if (range.start) {
      setStartDate(dayjs(range.start));
      setEndDate(dayjs(range.end).hour(23).minute(59).second(59));
    } else {
      setStartDate(dayjs(range[0]));
      setEndDate(dayjs(range[6]).hour(23).minute(59).second(59));
    }
  };

  const onSelectSlot = (slot) => {
    let startAt = dayjs(slot.start);
    let endAt = dayjs(slot.end);

    let daySlot = null;
    openSlots.forEach(el => {
      if(dayjs(el.start).format('YYYY-MM-DD') === dayjs(startAt).format('YYYY-MM-DD')) {
        daySlot = el;
      }
    });

    if(startAt.isBefore(dayjs(daySlot.start))) {
      slot.start = daySlot.start;
    }

    if(endAt.isAfter(dayjs(daySlot.end))) {
      slot.end = daySlot.end;
    }

    setSelectedSlot(slot);
    setOpenCreationModal(true);
  };

  const onSelectEvent = (event) => {
    setSelectedEvent(event);
    setOpenModificationModal(true);
  };

  const handleCloseModificationModal = (event) => {
    setReload(!reload);
    setOpenModificationModal(false);
    setSelectedEvent();
  };

  const handleCloseCreationModal = (event) => {
    setOpenCreationModal(false);
    setSelectedSlot();
  };

  return (
    <div>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des disponibilités, veuillez recharger la page ou réessayer plus tard. réessayer plus tard.'
        }
      />

      {events ? (
        <>
          <Calendar
            localizer={localizer}
            events={events}
            messages={messages}
            defaultView={Views.WEEK}
            views={{
              month: true,
              week: true,
            }}
            selectable={isSelectable}
            step={15}
            timeslots={4}
            onSelectSlot={onSelectSlot}
            onSelectEvent={onSelectEvent}
            onRangeChange={onRangeChange}
            eventPropGetter={eventStyleGetter}
            slotPropGetter={slotStyleGetter}
            min={hourMin}
            max={hourMax}
            style={{
              height: '550px',
            }}
          />
          {selectedSlot && (
            <CreationModal
              openModal={openCreationModal}
              handleCloseModal={handleCloseCreationModal}
              slot={selectedSlot}
              userId={userId}
              reload={reload}
              setReload={setReload}
            />
          )}
          {selectedEvent && (
            <ModificationModal
              openModal={openModificationModal}
              handleCloseModal={handleCloseModificationModal}
              event={selectedEvent}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
