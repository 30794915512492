import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { Loading } from '../globalComponents/Loading';
import Auth from '../../services/Auth';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '1em',
  },
  formControl: {
    minWidth: 200,
  },
  space: {
    flexGrow: 1,
  },
}));

export const Inputs = (props) => {
  const {
    date,
    setDate,
    subjectId,
    setSubjectId,
    levelId,
    setLevelId,
    workzoneId,
    setWorkzoneId,
    subjects,
    levels,
    workzones,
    timeSlotId,
    setTimeSlotId,
    setOpenModal,
    openSlots,
  } = props;

  const classes = useStyles();

  const handleDate = async (event) => {
    setDate(event.target.value);
  };

  const handleSlot = (event) => {
    setTimeSlotId(event.target.value);
  };

  const handleSubject = (event) => {
    setSubjectId(event.target.value);
  };

  const handleLevel = (event) => {
    setLevelId(event.target.value);
  };

  const handleWorkzone = (event) => {
    setWorkzoneId(event.target.value);
  };

  const handleOpenModal = (event) => {
    setOpenModal(true);
  };

  return (
    <>
      {subjects ? (
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="subjectSelectLabel">Matière</InputLabel>
          <Select
            labelId="subjectSelectLabel"
            id="subjectSelect"
            value={subjectId}
            className={classes.margin}
            onChange={handleSubject}
            label="Matière"
          >
            {subjects.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Loading />
      )}
      {levels ? (
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="levelSelectLabel">Niveau</InputLabel>
          <Select
            labelId="levelSelectLabel"
            id="levelSelect"
            className={classes.margin}
            value={levelId}
            onChange={handleLevel}
            label="Niveau"
          >
            <MenuItem key={0} value={0}>
              Tous les niveaux
            </MenuItem>
            {levels.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Loading />
      )}
      {workzones ? (
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="workzoneSelectLabel">Zone</InputLabel>
          <Select
            labelId="workzoneSelectLabel"
            id="workzoneSelect"
            className={classes.margin}
            value={workzoneId}
            onChange={handleWorkzone}
            label="Zone"
          >
            <MenuItem key={0} value={0}>
              Toutes les zones
            </MenuItem>
            {workzones.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Loading />
      )}

      <TextField
        id="date"
        label="Date"
        type="date"
        margin="dense"
        variant="outlined"
        className={classes.margin}
        value={dayjs(date).format('YYYY-MM-DD')}
        onChange={handleDate}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {openSlots ? (
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel id="slotSelectLabel">Créneau</InputLabel>
          <Select
            labelId="slotSelectLabel"
            id="slotSelect"
            value={timeSlotId}
            onChange={handleSlot}
            className={classes.margin}
            label="Créneau"
          >
            {openSlots.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {dayjs(option.start).format('HH:mm')} {'-'} {dayjs(option.end).format('HH:mm')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Loading />
      )}

      <div className={classes.space} />

      {(Auth.hasRole('board_super_admin')) && (
        <Button variant="contained" margin="dense" color="secondary" onClick={handleOpenModal}>
          Édition des créneaux
        </Button>
      )}
    </>
  );
};
