import React, { useState, useEffect, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import api from '../../api/api';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import { Loading } from '../globalComponents/Loading';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const TeacherList = (props) => {
  const { date, subjectId, levelId, workzoneId, reload } = props;
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const green = '#C8DF52';
  const lightGreen = '#b5c94b';
  const mediumDarkGreen = '#c9c536';
  const orangeGreen = '#c9ac36';
  const lightOrange = '#c99636';
  const orange = '#c98736';
  const darkOrange = '#d67520';
  const orangeRed = '#e36110';
  const red = '#ff4e47';

  const columns = [
    {
      name: 'user_id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'teacher_info',
      label: 'Enseignants',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_main_subject === false) {
            cellColor = red;
          } else {
            cellColor = green;
          }
          return {
            style: {
              paddingLeft: '3px',
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'left',
          };
        },
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
      },
    },
    {
      name: 'active_hour_bloc',
      label: 'Activité',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value <= 0) {
            cellColor = green;
          } else if (value > 0 && value <= 3) {
            cellColor = lightGreen;
          } else if (value > 3 && value <= 6) {
            cellColor = mediumDarkGreen;
          } else if (value > 6 && value <= 9) {
            cellColor = orangeGreen;
          } else if (value > 9 && value <= 12) {
            cellColor = lightOrange;
          } else if (value > 12 && value <= 15) {
            cellColor = orange;
          } else if (value > 15 && value <= 18) {
            cellColor = darkOrange;
          } else if (value > 18 && value <= 21) {
            cellColor = orangeRed;
          } else if (value > 21) {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
    {
      name: 'invited_hour_bloc',
      label: 'Invit.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value <= 0) {
            cellColor = green;
          } else if (value > 0 && value <= 1) {
            cellColor = lightGreen;
          } else if (value > 1 && value <= 2) {
            cellColor = mediumDarkGreen;
          } else if (value > 2 && value <= 3) {
            cellColor = orangeGreen;
          } else if (value > 3 && value <= 4) {
            cellColor = lightOrange;
          } else if (value > 4 && value <= 5) {
            cellColor = orange;
          } else if (value > 5 && value <= 6) {
            cellColor = darkOrange;
          } else if (value > 6 && value <= 7) {
            cellColor = orangeRed;
          } else if (value > 7) {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    pagination: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [1, 5, 10, 20, 50],
    selectableRows: 'none',

    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
    },
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
  };

  useEffect(() => {
    const getAvailableTeacher = async () => {
      setErrorAPI(false);
      try {
        setIsLoading(true);
        const start = dayjs.tz(date, 'Europe/Paris').weekday(0).hour(0).minute(0).second(0).unix();
        const end = dayjs.tz(date, 'Europe/Paris').weekday(6).hour(23).minute(59).second(59).unix();
        const response = await api.getTeacherList({
          start: start,
          end: end,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
        });
        if (response) {
          let teachersList;
          teachersList = response.map((teacher) => {
            teacher.teacher_info = capitalize(teacher.first_name) + ' ' + capitalize(teacher.last_name);
            teacher.active_hour_bloc = (teacher.active_hours / 3600 / 3).toFixed(2);
            teacher.invited_hour_bloc = (teacher.invited_hours / 3600 / 3).toFixed(2);
            return teacher;
          });

          setData(teachersList);
          setIsLoading(false);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getAvailableTeacher();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getAvailableTeacher();
  }, [refresh, date, subjectId, levelId, workzoneId, reload]);

  return (
    <>
      {data && !isLoading ? (
        <>
          <ErrorAPI
            errorAPI={errorAPI}
            setErrorAPI={setErrorAPI}
            message={
              'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
            }
          />

          <Table data={data} columns={columns} options={options} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
