// ------------------------------------------------------------------------ //
// IMPORT
// ------------------------------------------------------------------------ //
import api from '../api/api';

// ------------------------------------------------------------------------ //
//  CONSTANTS
// ------------------------------------------------------------------------ //
import {
  LOAD_SUMMARY_PROCESSED_INQUIRIES,
  LOAD_SUMMARY_DIRECT_INQUIRIES
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  getSummaryProcessedInquiries
// ------------------------------------------------------------------------ //
export const getSummaryProcessedInquiries = (
    from,
    to,
    userId
  ) => {
  return (dispatch) => {
    api.getSummaryProcessedInquiries({
      from: from,
      to: to,
      userId: userId
    }).then(function(response) {
      console.log(response)
      return dispatch({
        type: LOAD_SUMMARY_PROCESSED_INQUIRIES,
        payload: response.data
      });
    });
  };
}


// ------------------------------------------------------------------------ //
//  getSummaryProcessedInquiries
// ------------------------------------------------------------------------ //
export const getSummaryDirectInquiries = (
  from,
  to,
  userId
) => {
return (dispatch) => {
  api.getSummaryDirectInquiries({
    from: from,
    to: to,
    userId: userId
  }).then(function(response) {
    return dispatch({
      type: LOAD_SUMMARY_DIRECT_INQUIRIES,
      payload: response.data
    });
  });
};
}
