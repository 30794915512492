import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { TableJournalGlobal, TableJournalUser } from '../../components/journal/TableJournal';
import { UserSelect } from '../../components/globalComponents/UserSelect';
import BasicsContext from '../../context/BasicsContext';
import dayjs from 'dayjs';
import Auth from '../../services/Auth';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dateInput: {
    marginRight: '1em',
  },
}));

export const TabJournal = (props) => {
  const classes = useStyles();
  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [allUsers, setAllUsers] = useState(false);
  const [userId, setUserId] = useState(user.id);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));

  const handleAllUsers = (event) => {
    setAllUsers(event.target.checked);
  };

  const handleDate = (event) => {
    setDate(event.target.value);
  };

  return (
    <div className={classes.column}>
      <div className={classes.row}>
        <TextField
          id="date"
          label="Mois à afficher"
          type="date"
          margin="dense"
          variant="outlined"
          value={date}
          onChange={handleDate}
          className={classes.dateInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {(Auth.hasRole('board_super_admin') 
          || Auth.hasRole('board_admin')
          || Auth.hasRole('super_teacher')
          || Auth.hasRole('super_switchman')
        ) && (
          <FormControlLabel
            control={<Checkbox checked={allUsers} onChange={handleAllUsers} name="allUsers" color="primary" />}
            label="Afficher tous les utilisateurs"
          />
        )}
        <UserSelect userId={userId} setUserId={setUserId} />
      </div>

      {allUsers && <TableJournalGlobal date={date} />}
      {!allUsers && <TableJournalUser userId={userId} date={date} />}
    </div>
  );
};
