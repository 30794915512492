import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button } from '@material-ui/core';
import BasicsContext from '../../context/BasicsContext';
import { UserSelect } from '../../components/globalComponents/UserSelect';
import { MyCalendar } from '../../components/calendar/Calendar';
import { CommentBox } from '../../components/calendar/CommentBox';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import api from '../../api/api';
import { ErrorAPI } from '../../components/globalComponents/ErrorAPI';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import Auth from '../../services/Auth';

// ------------------------------------------------------------------------ //
// STYLES
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1em',
  },
  rowStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    width: '270px',
  },
  calendar: {
    flexGrow: 1,
    marginRight: '1em',
  },
  paperInfo: {
    padding: '1em',
    marginBottom: '2em',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  button: {
    marginBottom: '1em',
  },
  refresh: {
    alignSelf: 'flex-start',
  },
}));

// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
export const TabCalendar = () => {
  const classes = useStyles();
  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [userId, setUserId] = useState(user.id);
  const [reload, setReload] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [globalComment, setGlobalComment] = useState();

  useEffect(() => {
    const getGlobalComment = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getBoardSettings();
        if (response) {
          setGlobalComment(response[0].calendar_general_message);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getGlobalComment();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getGlobalComment();
  }, [refresh, userId]);

  const handleReload = () => {
    setReload(!reload);
  };

  return (
    <div>
      <div className={classes.rowCenter}>
        {Auth.hasRole('board_admin') && (
          <>
            <Typography variant="h6">Afficher l'agenda de : </Typography>
            <UserSelect userId={userId} setUserId={setUserId} />
          </>
        )}
      </div>
      <div className={classes.rowStart}>
        <div className={classes.calendar}>
          <MyCalendar userId={userId} reload={reload} setReload={setReload} />
        </div>

        <div className={classes.column}>
          <Tooltip title="Rafraichir">
            <IconButton aria-label="refresh" onClick={handleReload} className={classes.refresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {(Auth.hasRole('board_super_admin')) && (
            <Link
              className={classes.link}
              to="/invitations"
              target="_blank"
            >
              <Button variant="contained" margin="dense" color="secondary" className={classes.button}>
                Gérer les invitations
              </Button>
            </Link>
          )}
          <Paper className={classes.paperInfo} elevation={3}>
            <ErrorAPI
              errorAPI={errorAPI}
              setErrorAPI={setErrorAPI}
              message={
                "Un problème est survenu lors de l'affichage des informations générales, veuillez recharger la page ou réessayer plus tard."
              }
            />
            <Typography variant="h6">Informations générales :</Typography>

            <Typography variant="subtitle1">{globalComment}</Typography>
          </Paper>

          <CommentBox userId={userId} />
        </div>
      </div>
    </div>
  );
};
