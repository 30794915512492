// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import { 
  LOAD_TODAY_ACTIVITIES,
  LOAD_IN_PROGRESS_ACTIVITY,
  STOP_ACTIVITY,
  START_ACTIVITY,
  LOAD_RUNNING_ACTIVITIES
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
    inProgress: null,
    today: [],
    running: []
};
  
// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_TODAY_ACTIVITIES:
            return {
                ...state,
                today: action.payload //.map((attributes) => return new Activity(attributes))
            };

        case LOAD_IN_PROGRESS_ACTIVITY:
            return {
                ...state,
                inProgress: action.payload.id ? action.payload : null
            };

        case START_ACTIVITY: 
            return {
                ...state,
                inProgress: action.payload
            };

        case STOP_ACTIVITY: 
            return {
                ...state,
                inProgress: null
            };

        case LOAD_RUNNING_ACTIVITIES: 
            return {
                ...state,
                running: action.payload
            };
            
        default:
            return state;
    }
}

export default reducer;