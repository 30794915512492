import React, { useState, useContext, useEffect } from 'react';
import { TextField, IconButton, Tooltip, Paper } from '@material-ui/core';
import api from '../../api/api';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    padding: '1em',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
}));

export const CommentBox = () => {
  const [globalComment, setGlobalComment] = useState('');
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);

  useEffect(() => {
    const getComment = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getBoardSettings();
        if (response) {
          setGlobalComment(response[0].calendar_general_message);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getComment();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getComment();
  }, [refresh]);

  const handleComment = (event) => {
    setGlobalComment(event.target.value);
  };

  const handleSaveComment = async (event) => {
    setErrorAPI(false);
    try {
      await api.patchBoardSettings({
        globalComment: globalComment,
      });
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSaveComment();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  return (
    <div className={classes.column}>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          "Un problème est survenu lors de l'enregistrement du commentaire, veuillez recharger la page ou réessayer plus tard. réessayer plus tard."
        }
      />
      <Paper elevation={1} className={classes.paper}>
        <TextField
          id="globalCommentInput"
          label="Message d'information générale"
          onChange={handleComment}
          multiline
          fullWidth
          rows={4}
          value={globalComment}
          variant="outlined"
        />
        <Tooltip title={'Enregistrer'}>
          <IconButton color="primary" aria-label="save" onClick={handleSaveComment}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      </Paper>
    </div>
  );
};
