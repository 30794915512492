import React from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
//import { UserSelect } from '../globalComponents/UserSelect';
import { TableTreatedRequest } from '../summary/TablesSummary';

import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import PublicIcon from '@material-ui/icons/Public';
import GroupIcon from '@material-ui/icons/Group';
import ClassIcon from '@material-ui/icons/Class';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ComputerIcon from '@material-ui/icons/Computer';
import DescriptionIcon from '@material-ui/icons/Description';
import HistoryIcon from '@material-ui/icons/History';
import Auth from '../../services/Auth';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  center: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '0.5em',
  },
}));

export const UserInfoModal = (props) => {
  const { userInfo, handleCloseModal } = props;
  const classes = useStyles();
  const basics = Auth.basics();
  let hours, minutes;

  if (userInfo && userInfo.user.daily_activities_duration !== 0) {
    hours = Math.floor(userInfo.user.daily_activities_duration / 3600);
    minutes = userInfo.user.daily_activities_duration % 60;
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={userInfo !== null ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="form-dialog-title"
      >
        {userInfo ? (
          <>
            <DialogTitle disableTypography>
              <div className={classes.center}>
                <Typography variant="h6" className={classes.title}>
                  Informations de
                </Typography>
                {/* <UserSelect userId={id} setUserId={setId} /> */}
              </div>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.row}>

                <div className={classes.center}>
                  <Tooltip title={'id'}>
                    <PersonIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={'id'}>
                    <Typography variant="subtitle2">id n°{userInfo.user.id}</Typography>
                  </Tooltip>
                </div>

                <div className={classes.center}>
                  <Tooltip title={'rôle'}>
                    <BusinessCenterIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={'rôle'}>
                    <Typography variant="subtitle2">
                      {Auth.hasRole('board_admin')
                        ? 'Admin'
                        : Auth.hasRole('super_switchman')
                        ? 'Coordo aiguilleurs'
                        : Auth.hasRole('super_teacher')
                        ? 'Coordo enseignants'
                        : Auth.hasRole('switchman')
                        ? ' aiguilleurs'
                        : Auth.hasRole('teacher')
                        ? 'Enseignants'
                        : 'Rôle non défini'}
                    </Typography>
                  </Tooltip>
                </div>

                <div className={classes.center}>
                  <Tooltip title={'email'}>
                    <MailIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={'email'}>
                    <Typography variant="subtitle2">{userInfo.user.email}</Typography>
                  </Tooltip>
                </div>

                {userInfo.user && userInfo.user.phone !== '' && (
                  <div className={classes.center}>
                    <Tooltip title={'téléphone'}>
                      <CallIcon className={classes.marginRight} />
                    </Tooltip>
                    <Tooltip title={'téléphone'}>
                      <Typography variant="subtitle2">{userInfo.user.phone}</Typography>
                    </Tooltip>
                  </div>
                )}

                <div className={classes.center}>
                  <Tooltip title={'Groupe de travail'}>
                    <GroupIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={'Groupe de travail'}>
                    <Typography variant="subtitle2">
                      {/* FIXME */}
                      {/* {basics.board_user_groups.find((x) => x.id === userInfo.user.group_id).name} */}
                    </Typography>
                  </Tooltip>
                </div>

                <div className={classes.center}>
                  <Tooltip title={'Zone de travail'}>
                    <PublicIcon className={classes.marginRight} />
                  </Tooltip>
                  {userInfo.user.workzones.map((workzone, index) => (
                    <Tooltip title={'Zone de travail'}>
                      <Typography variant="subtitle2">&nbsp;{workzone.display_name.substr(0, 4)}
                      { index < userInfo.user.workzones.length - 1 ? ' - ' : ''}
                      </Typography>
                    </Tooltip>
                  ))}
                </div>

              </div>

              {Auth.hasRole('teacher') && (
                <div className={classes.row}>
                  <div className={classes.center}>
                    <Tooltip title={'Matières'}>
                      <ClassIcon className={classes.marginRight} />
                    </Tooltip>
                    {userInfo.user.subjects.map((subject) => (
                      <Tooltip title={'Matières'}>
                        <Typography variant="subtitle2"> {subject.name},</Typography>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogContent dividers>
              <div className={classes.row}>
                <div className={classes.center}>
                  {userInfo.user.status_id === 1 ? (
                    <ComputerIcon className={classes.marginRight} color="primary" />
                  ) : userInfo.user.status_id === 2 ? (
                    <ComputerIcon className={classes.marginRight} color="disabled" />
                  ) : (
                    <ComputerIcon className={classes.marginRight} color="secondary" />
                  )}
                  <Tooltip title={'Statut TDB'}>
                    <Typography variant="h6">
                      {basics.board_statuses.find((x) => x.id === userInfo.user.status_id).name}
                    </Typography>
                  </Tooltip>
                </div>
                <div className={classes.center}>
                  {userInfo.user.available === 1 ? (
                    <>
                      <CheckCircleIcon className={classes.marginRight} style={{ color: '#759824' }} />
                      <Tooltip title={'Disponibilité'}>
                        <Typography variant="h6"> Disponible </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <CancelIcon className={classes.marginRight} style={{ color: '#B73214' }} />
                      <Tooltip title={'Disponibilité'}>
                        <Typography variant="h6">
                          Indisponible depuis {dayjs(userInfo.user.unavailable_started_at).format('HH:mm')} jusqu'à{' '}
                          {dayjs(userInfo.user.unavailable_ended_at).format('HH:mm')}
                        </Typography>
                      </Tooltip>
                    </>
                  )}
                </div>
                <div className={classes.center}>
                  <Tooltip title={'nb de requêtes du jour'}>
                    <DescriptionIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={'nb de requêtes du jour'}>
                    <Typography variant="h6">
                      {userInfo.user.daily_inquiries_number !== 0 ? userInfo.user.daily_inquiries_number : 'Aucune'}{' '}
                      requêtes
                    </Typography>
                  </Tooltip>
                </div>
                <div className={classes.center}>
                  <Tooltip title={"temps d'activité du jour"}>
                    <HistoryIcon className={classes.marginRight} />
                  </Tooltip>
                  <Tooltip title={"temps d'activité du jour"}>
                    <Typography variant="h6">
                      {userInfo.user.daily_activities_duration !== 0
                        ? hours > 0
                          ? hours + 'h' + minutes + "min d'activité"
                          : minutes + "min d'activité"
                        : 'Aucune activité'}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className={classes.row}>
                <TableTreatedRequest date={dayjs().format('YYYY-MM-DD')} userId={userInfo.user.id} selectedUserInfo={userInfo} />
              </div>
            </DialogContent>
          </>
        ) : (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        )}
      </Dialog>
    </div>
  );
};


