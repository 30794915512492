import React, { useState, useContext, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { Loading } from '../globalComponents/Loading';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './customCalendar.css';
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.locale('fr');

const localizer = momentLocalizer(dayjs);

const messages = {
  allDay: 'Jour entier',
  previous: '<',
  next: '>',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Evenement',
  showMore: (total) => `+ (${total}) Evenements`,
};

export const DayCalendar = (props) => {
  const {
    date,
    setOpenModal,
    userId,
    slotId,
    setSlotId,
    selectedSlot,
    setSelectedSlot,
    reloadEvents,
    openSlots,
  } = props;
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const [events, setEvents] = useState([]);
  const hourMin = dayjs(date).startOf('day').add(9, 'hour').toDate();
  const hourMax = dayjs(date).startOf('day').add(23, 'hour').toDate();

  useEffect(() => {
    const getEvents = async () => {
      setErrorAPI(false);
      try {
        const slotDate = dayjs(date).format('YYYY-MM-DD');
        const response = await api.getEventsUser({
          userId: userId,
          startDate: dayjs.tz(slotDate, 'Europe/Paris').hour(0).minute(0).second(0).unix(),
          endDate: dayjs.tz(slotDate, 'Europe/Paris').hour(23).minute(59).second(59).unix(),
        });
        if (response) {
          const events = response.map((event) => {
            event.start = dayjs(event.started_at).toDate();
            event.end = dayjs(event.ended_at).toDate();
            return event;
          });
          setEvents(events);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getEvents();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    const select = openSlots.find((slot) => slot.id === slotId);
    setSelectedSlot(select);
    if (userId) {
      getEvents();
    }
  }, [refresh, date, userId, slotId, setSelectedSlot, reloadEvents, openSlots]);

  const eventStyleGetter = (event) => {
    let backgroundColor;
    if (event.status_id === 1 && event.reinforcement === 0) {
      backgroundColor = '#C8DF52';
    } else if (event.status_id === 1 && event.reinforcement === 1) {
      backgroundColor = '#6B8E23';
    } else if (event.status_id === 2 && event.reinforcement === 0) {
      backgroundColor = '#F8EA8C';
    } else if (event.status_id === 2 && event.reinforcement === 1) {
      backgroundColor = '#E1C340';
    } else {
      backgroundColor = '#e6e6e6';
    }

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    };
    return { style };
  };

  const slotStyleGetter = (date) => {
    let backgroundColor = 'white';
    let i = 0;
    const dateCheck = dayjs(date);
    let start;
    let end;

    if (openSlots) {
      if (selectedSlot && dateCheck.diff(selectedSlot.start) >= 0 && dateCheck.diff(selectedSlot.end) < 0) {
        backgroundColor = '#213759';
      } else {
        while (i < openSlots.length && backgroundColor === 'white') {
          start = dayjs(openSlots[i].start);
          end = dayjs(openSlots[i].end);

          if (dateCheck.diff(start) >= 0 && dateCheck.diff(end) < 0) {
            backgroundColor = '#a7c5f2';
          }
          i++;
        }
      }
    }

    var style = {
      backgroundColor: backgroundColor,
      opacity: 1,
      display: 'block',
    };
    return { style };
  };

  const onSelectSlot = (slot) => {
    if (slot.action === 'click') {
      const startSelect = dayjs(slot.start);
      let i = 0;
      let start, end;
      let done = false;
      if (openSlots) {
        while (i < openSlots.length && !done) {
          start = dayjs(openSlots[i].start);
          end = dayjs(openSlots[i].end);

          if (startSelect.diff(start) >= 0 && startSelect.diff(end) < 0) {
            setSlotId(openSlots[i].id);
            setSelectedSlot(openSlots[i]);
            done = true;
          }
          i++;
        }
      }
    }
    if (slot.action === 'doubleClick') {
      const startSelect = dayjs(slot.start);
      let i = 0;
      let start, end;
      let done = false;
      if (openSlots) {
        while (i < openSlots.length && !done) {
          start = dayjs(openSlots[i].start);
          end = dayjs(openSlots[i].end);

          if (startSelect.diff(start) >= 0 && startSelect.diff(end) < 0) {
            setSlotId(openSlots[i].id);
            setSelectedSlot(openSlots[i]);
            setOpenModal(true);
            done = true;
          }
          i++;
        }
      }
    }
  };

  return (
    <div>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des disponibilités, veuillez recharger la page ou réessayer plus tard. réessayer plus tard.'
        }
      />

      {openSlots ? (
        <>
          <Calendar
            localizer={localizer}
            events={events}
            messages={messages}
            defaultView={Views.DAY}
            date={dayjs(date).toDate()}
            views={{
              day: true,
            }}
            selectable
            step={15}
            timeslots={4}
            onSelectSlot={onSelectSlot}
            eventPropGetter={eventStyleGetter}
            slotPropGetter={slotStyleGetter}
            min={hourMin}
            max={hourMax}
            toolbar={false}
            style={{
              height: '650px',
              minWidth: '175px',
              marginRight: '1em',
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
