import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { Loading } from '../globalComponents/Loading';
import { ErrorAPI } from '../globalComponents/ErrorAPI';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBottom: {
    marginBottom: '1em',
  },
  formControl: {
    minWidth: 300,
    marginBottom: '1em',
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));

export const TimeSlotsDeleteModal = (props) => {
  const { openModal, handleCloseModal, slotId, setSlotId, date, setReloadSlot, reloadSlot, openSlots } = props;
  const classes = useStyles();
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [slotToDelete, setSlotToDelete] = useState(slotId);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSlotToDelete(slotId);
  }, [slotId]);

  const handleSlot = (event) => {
    setSlotToDelete(event.target.value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await api.deleteSlot(slotId);
      if (response) {
        setSlotId();
        setReloadSlot(!reloadSlot);
        handleCloseModal();
        setIsLoading(false);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleSave();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Suppression des créneaux d'ouverture
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ErrorAPI
          errorAPI={errorAPI}
          setErrorAPI={setErrorAPI}
          message={
            'Un problème est survenu lors de la suppression des créneaux, veuillez recharger la page ou réessayer plus tard.'
          }
        />
        <DialogContent dividers>
          <div className={classes.col}>
            <Typography variant="subtitle1" className={classes.marginBottom}>
              Créneaux disponibles pour le {dayjs(date).format('DD/MM/YYYY')}
            </Typography>

            {openSlots ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="slotSelectLabel">Créneau à supprimer</InputLabel>
                <Select
                  labelId="slotSelectLabel"
                  id="slotSelect"
                  margin="dense"
                  fullWidth
                  value={slotToDelete}
                  onChange={handleSlot}
                  label="Créneau à supprimer"
                >
                  {openSlots.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {dayjs(option.start).format('HH:mm')} {'-'} {dayjs(option.end).format('HH:mm')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Loading />
            )}
            {!isLoading ? (
              <Button
                variant="contained"
                margin="dense"
                color="secondary"
                onClick={handleSave}
                className={classes.margin}
              >
                Supprimer ce créneau
              </Button>
            ) : (
              <CircularProgress color="secondary" className={classes.loading} />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
