import React, { useState, useEffect } from 'react';
import { Typography, FormControlLabel, TextField, Button, Checkbox } from '@material-ui/core';
import { ActivityTypeSelect } from '../globalComponents/ActivityTypeSelect';
import { ActivityGroupSelect } from '../globalComponents/ActivityGroupSelect';
import { TimezoneSelect } from '../globalComponents/TimezoneSelect';
import { makeStyles } from '@material-ui/core/styles';
import { StartOutHourModal } from './StartOutHourModal';
import { StopOutHourModal } from './StopOutHourModal';
import { connect } from 'react-redux';

// ------------------------------------------------------------------------ //
// REDUX
// ------------------------------------------------------------------------ //
import {
  getInProgressActivity,
  getTodayActivities,
  startActivity,
  stopActivity
} from './../../actions/activities';

import {
  setIsUserActive
} from './../../actions/user';

// ------------------------------------------------------------------------ //
// STYLE
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  button: {
    marginTop: '0.5em',
  },
  rowTimezone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputTimezone: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '1em',
  },
  inputReinforcement: {
    display: 'flex',
    alignSelf: 'center',
  },
  loading: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'center',
  },
}));



// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
const InputsDirectActivity = (props) => {
  const { activeHourSelected } = props;
  const classes = useStyles();

  // const [activityId, setActivityId] = useState('');
  const [activityTypeId, setActivityTypeId] = useState('');
  const [activityGroupId, setActivityGroupId] = useState('');
  const [specialActivityName, setSpecialActivityName] = useState('');
  const [timezoneId, setTimezoneId] = useState('');
  const [reinforcement, setReinforcement] = useState(false);
  const [guest, setGuest] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);

  const {
    inProgressActivity
  } = props;

  useEffect(() => {
    // if (activeHourSelected === false && started) {
    //   setOpenStopModal(true);
    // }
  }, [activeHourSelected]);

  const handleCloseStopModal = (event) => {
    setOpenStopModal(false);
  };

  const handleStartActivity = async (event) => {
    props.startActivity(activityTypeId, activityGroupId, specialActivityName, timezoneId, reinforcement ? 1 : 0, guest ? 1 : 0);
    props.setIsUserActive(true);
    props.getTodayActivities();
    setOpenStartModal(false);
  };

  const handleStopActivity = async (event) => {
    const { id } = inProgressActivity;
    props.stopActivity(id);
    props.setIsUserActive(false);
    props.getTodayActivities();
    setActivityTypeId('');
    setActivityGroupId('');
    //setActivityId('');
    setTimezoneId('');
    setReinforcement(false);
    setGuest(false);
    setSpecialActivityName('');
    setOpenStopModal(false);
  };

  return (
    <div className={classes.column}>
      <Typography component={'span'} variant="h5">Lancer une activité en direct</Typography>

      {/* SELECT GROUP */}
      <ActivityGroupSelect
        handleActivityGroupId={(event) => {
          setActivityGroupId(event.target.value);
        }}
        activityGroupId={activityGroupId}
        disabled={inProgressActivity !== null ? true : false}
      />

      {/* SELECT TYPE */}
      <ActivityTypeSelect
        activityTypeId={activityTypeId}
        handleActivityTypeId={(event) => {
          setActivityTypeId(event.target.value);
        }}
        disabled={inProgressActivity !== null ? true : false}
      />

      {/* INPUT TEXT ACTIVITY NAME */}
      {activityTypeId === 4 && (
        <TextField
          margin="dense"
          id="activityType"
          variant="outlined"
          label="Intitulé de l'activité"
          value={specialActivityName}
          disabled={inProgressActivity !== null ? true : false}
          onChange={(event) => {
            setSpecialActivityName(event.target.value);
          }}
          fullWidth
        />
      )}

      {/* SELECT TIMEZONE */}
      <TimezoneSelect
        timezoneId={timezoneId}
        handleTimezoneId={(event) => {
          setTimezoneId(event.target.value);
        }}
        disabled={inProgressActivity !== null ? true : false}
      />

      {/* CHECKBOX RENFORT */}
      {activityTypeId === 1 && (
        <FormControlLabel
          label="Activité de renfort"
          control={
            <Checkbox
              checked={reinforcement}
              onChange={(event) => {
                setReinforcement(event.target.checked);
              }}
              disabled={inProgressActivity !== null ? true : false}
              name="reinforcement"
              color="primary"
            />
          }
        />
      )}

      {/* CHECKBOX GUEST */}
      {activityTypeId === 1 && (
        <FormControlLabel
          label="Invité"
          control={
            <Checkbox
              checked={guest}
              onChange={(event) => {
                setGuest(event.target.checked);
              }}
              disabled={inProgressActivity !== null ? true : false}
              name="guest"
              color="primary"
            />
          }
        />
      )}

      {/* SUBMIT BUTTON */}
      {inProgressActivity ? (
        <Button
          variant="contained"
          margin="dense"
          color="secondary"
          fullWidth
          className={classes.button}
          onClick={(event) => {
            setOpenStopModal(true);
          }}
          // onClick={handleStopActivity}
        >
          Arret de l'activité
        </Button>
      ) : (
        <Button
          variant="contained"
          margin="dense"
          color="primary"
          fullWidth
          disabled={!activityTypeId || !timezoneId}
          className={classes.button}
          onClick={activeHourSelected ? handleStartActivity : (event) => {
            setOpenStartModal(true);
          }}
        >
          Lancer l'activité
        </Button>
      )}

      <StartOutHourModal
        openModal={openStartModal}
        handleCloseModal={(event) => {
          setOpenStartModal(false);
          setActivityTypeId('');
          setTimezoneId('');
          setReinforcement(false);
          setGuest(false);
          setSpecialActivityName('');
        }}
        handleStartActivity={handleStartActivity}
      />

      <StopOutHourModal
        openModal={openStopModal}
        handleCloseModal={handleCloseStopModal}
        handleStopActivity={handleStopActivity}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  inProgressActivity: state.activities.inProgress,
  isUserActive: state.user.isActive
});

const mapDispatchToProps = dispatch => {
  return {
    getInProgressActivity: () => dispatch(getInProgressActivity()),
    getTodayActivities: () => dispatch(getTodayActivities()),
    startActivity: (activityTypeId, specialActivityName, timezoneId, reinfInt) => dispatch(startActivity(activityTypeId, specialActivityName, timezoneId, reinfInt)),
    stopActivity: (id) => dispatch(stopActivity(id)),
    setIsUserActive: (status) => dispatch(setIsUserActive(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputsDirectActivity);
