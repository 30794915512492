// ------------------------------------------------------------------------ //
//  ACTIONS TYPES
// ------------------------------------------------------------------------ //
import { 
  SET_CURRENT_USER,
  SET_IS_ACTIVE_USER,
} from '../constants/actionTypes';

// ------------------------------------------------------------------------ //
//  INITIAL STATE
// ------------------------------------------------------------------------ //
const initialState = {
  user: null,
  isActive: null,
};

// ------------------------------------------------------------------------ //
//  ACTIONS
// ------------------------------------------------------------------------ //
const reducer = (state = initialState, action) => {
  switch (action.type) {  

    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload
      };

    case SET_IS_ACTIVE_USER:
      return {
        ...state,
        isActive: action.payload
      };

    default:
      return state;
  }
};

export default reducer;