import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableActivity from '../../components/activity/TableActivity';
import InputsDirectActivity from '../../components/activity/InputsDirectActivity';
import { InputsManualActivity } from '../../components/activity/InputsManualActivity';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  columnInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  columnTable: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dividerVertical: {
    marginLeft: '1em',
    marginRight: '1em',
  },
  dividerHorizontal: { marginTop: '1em' },
}));

export const TabActivity = (props) => {
  const { activeHourSelected } = props;
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  
  return (
    <div className={classes.row}>
      <div className={classes.columnInput}>
        <InputsDirectActivity
          reload={reload}
          setReload={setReload}
          activeHourSelected={activeHourSelected}
        />
        <Divider className={classes.dividerHorizontal} />
        <InputsManualActivity reload={reload} setReload={setReload} />
      </div>
      <Divider className={classes.dividerVertical} orientation="vertical" flexItem />
      <div className={classes.columnTable}>
        <TableActivity reload={reload} />
      </div>
    </div>
  );
};
