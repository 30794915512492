import React, { useState, useEffect, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import { Button, IconButton, Typography } from '@material-ui/core';
import api from '../../api/api';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import { Loading } from '../globalComponents/Loading';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const InvitedTeacherTable = (props) => {
  const {
    date,
    subjectId,
    levelId,
    workzoneId,
    reload,
    setReload,
    subjects,
    levels,
    workzones,
    setUserId,
    selectedSlot,
    reloadEvents,
    setReloadEvents,
  } = props;
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [data, setData] = useState();
  const green = '#C8DF52';
  const [localReload, setLocalReload] = useState(false);
  const [reinforcementNb, setReinforcementNb] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const lightGreen = '#b5c94b';
  const mediumDarkGreen = '#c9c536';
  const orangeGreen = '#c9ac36';
  const lightOrange = '#c99636';
  const orange = '#c98736';
  const darkOrange = '#d67520';
  const orangeRed = '#e36110';
  const red = '#ff4e47';

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'user_id',
      label: 'id prof',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'teacher_info',
      label: 'Enseignants',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="body1"
            onClick={() => {
              handleUser(tableMeta);
            }}
          >
            {value}
          </Typography>
        ),
      },
    },
    {
      name: 'is_main_subject',
      label: 'Princi.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_main_subject === true) {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'reserved',
      label: 'Reserv.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].reserved === false) {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'active_hour_bloc',
      label: 'Activité',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value <= 0) {
            cellColor = green;
          } else if (value > 0 && value <= 3) {
            cellColor = lightGreen;
          } else if (value > 3 && value <= 6) {
            cellColor = mediumDarkGreen;
          } else if (value > 6 && value <= 9) {
            cellColor = orangeGreen;
          } else if (value > 9 && value <= 12) {
            cellColor = lightOrange;
          } else if (value > 12 && value <= 15) {
            cellColor = orange;
          } else if (value > 15 && value <= 18) {
            cellColor = darkOrange;
          } else if (value > 18 && value <= 21) {
            cellColor = orangeRed;
          } else if (value > 21) {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
    {
      name: 'invited_pourcent',
      label: 'Invité',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value === '100%') {
            cellColor = green;
          } else if (value === '0%') {
            cellColor = red;
          } else {
            cellColor = orange;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
    {
      name: 'invited_reinforcement',
      label: 'Renfort',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;

          if (data[rowIndex].invited_reinforcement === 1) {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              id={value.toString()}
              size="small"
              onClick={() => {
                handleReinforcement(tableMeta);
              }}
            >
              {value ? <CheckIcon /> : <ClearIcon />}
            </IconButton>
          );
        },
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'invite',
      label: 'Retirer',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: () => ({ align: 'center' }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                handleInvite(tableMeta);
              }}
            >
              <PersonAddDisabledIcon />
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    pagination: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [1, 5, 10, 20, 50],
    selectableRows: 'none',

    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
    },
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
  };

  useEffect(() => {
    const getInvitedTeacher = async () => {
      setErrorAPI(false);
      try {
        setIsLoading(true);
        const start = dayjs
          .tz(date, 'Europe/Paris')
          .hour(dayjs(selectedSlot.start).hour())
          .minute(dayjs(selectedSlot.start).minute())
          .second(dayjs(selectedSlot.start).second())
          .unix();
        const end = dayjs
          .tz(date, 'Europe/Paris')
          .hour(dayjs(selectedSlot.end).hour())
          .minute(dayjs(selectedSlot.end).minute())
          .second(dayjs(selectedSlot.end).second())
          .unix();
        const response = await api.getInvitedTeacher({
          start: start,
          end: end,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
        });
        if (response) {
          let teachersAvailable;
          let reinf = 0;
          teachersAvailable = response
            .filter(function (teacher) {
              if (teacher.subject_id === null) {
                return false; // skip
              }
              return true;
            })
            .map((teacherAvailable) => {
              teacherAvailable.teacher_info =
                capitalize(teacherAvailable.first_name) + ' ' + capitalize(teacherAvailable.last_name);
              teacherAvailable.reserved = teacherAvailable.status_id === 2 ? true : false;
              teacherAvailable.invited_pourcent = Math.floor(teacherAvailable.invited) + '%';
              teacherAvailable.active_hour_bloc = (teacherAvailable.active_hours / 3600 / 3).toFixed(2);
              if (teacherAvailable.invited_reinforcement === true) {
                reinf++;
              }
              return teacherAvailable;
            });

          setData(teachersAvailable);
          setReinforcementNb(reinf);
          setIsLoading(false);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getInvitedTeacher();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getInvitedTeacher();
  }, [refresh, date, subjectId, levelId, workzoneId, reload, localReload, selectedSlot]);

  const handleInvite = async (tableMeta) => {
    try {
      const response = await api.putEvent({
        id: tableMeta.rowData[0],
        subjectId: null,
        levelId: null,
        workzoneId: null,
        invited_reinforcement: null,
        title: null,
      });
      if (response) {
        setReload(!reload);
        setReloadEvents(!reloadEvents);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleInvite(tableMeta);
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
    //Gérer le cas de start/end event != start/end créneau
  };

  const handleReinforcement = async (tableMeta) => {
    let subject, level, workzone, title;

    if (subjectId !== 0) {
      subject = subjects.find((x) => x.id === subjectId).name;
    } else {
      subject = '';
    }

    if (levelId !== 0) {
      level = levels.find((x) => x.id === levelId).name;
    } else {
      level = '';
    }

    if (workzoneId !== 0) {
      workzone = workzones.find((x) => x.id === workzoneId).display_name;
    } else {
      workzone = '';
    }

    if (tableMeta.rowData[7] === true) {
      title = subject + ' ' + level + ' ' + workzone;
    } else {
      title = 'Renfort ' + subject + '/' + level + '/' + workzone + ' ';
    }

    try {
      const response = await api.putEvent({
        id: tableMeta.rowData[0],
        invited_reinforcement: !tableMeta.rowData[7],
        title: title,
      });
      if (response) {
        setLocalReload(!localReload);
        setReloadEvents(!reloadEvents);
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleReinforcement(tableMeta);
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  const handleUser = (tableMeta) => {
    setUserId(tableMeta.rowData[1]);
  };

  return (
    <>
      {data && !isLoading ? (
        <>
          <ErrorAPI
            errorAPI={errorAPI}
            setErrorAPI={setErrorAPI}
            message={
              'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
            }
          />
          <Typography variant="h6">
            {data.length} enseignants invités dont {reinforcementNb} renforts
          </Typography>
          <Table data={data} columns={columns} options={options} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
