import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Table } from '../globalComponents/Table';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import dayjs from 'dayjs';
import ModificationModal from './ModificationModal';
import { Loading } from '../globalComponents/Loading';

import {
  getUserMemo,
  createUserMemo,
  updateUserMemo,
  deleteUserMemo
} from './../../actions/memo';


const useStyles = makeStyles((theme) => ({
  button: {},
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

const TableMemo = (props) => {
  const classes = useStyles();
  const { memo } = props;
  const [selectedRow, setSelectedRow] = useState();
  const [openModificationModal, setOpenModificationModal] = useState(false);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'alarm_at',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'add',
      label: 'Retarder',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                  const memo = tableMeta.rowData;                  
                  props.updateUserMemo(
                    memo[0], 
                    dayjs.tz(memo[1]).add(5, 'minutes').toDate(), 
                    memo[2]
                  );
              }}
            >
              + 5 min
            </Button>
          );
        },
      },
    },
    {
      name: 'remove',
      label: 'Avancer',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                  const memo = tableMeta.rowData;
                  props.updateUserMemo(
                    memo[0], 
                    dayjs.tz(memo[1]).subtract(5, 'minutes').toDate(), 
                    memo[2]
                  );
              }}
            >
              - 5 min
            </Button>
          );
        },
      },
    },

    {
      name: 'modify',
      label: 'Modifier',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  handleModificationModal(tableMeta);
                }}
              >
                <EditIcon />
              </Button>
            </>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Supprimer',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                props.deleteUserMemo(tableMeta.rowData[0]);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          );
        },
      },
    },
  ];

  const handleModificationModal = (tableMeta) => {
    setSelectedRow(tableMeta.rowData);
    setOpenModificationModal(true);
  };

  const handleCloseModificationModal = (event) => {
    setOpenModificationModal(false);
    setSelectedRow();
  };

  return (
    <>
      {memo ? <Table title={'Mes mémos'} data={memo} columns={columns} /> : <Loading />}
      {selectedRow && (
        <ModificationModal
          openModal={openModificationModal}
          handleCloseModal={handleCloseModificationModal}
          memo={selectedRow}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ 
  memo: state.memo.data,
});

const mapDispatchToProps = dispatch => {
  return {
    getUserMemo: () => {
      return dispatch(getUserMemo());
    },
    createUserMemo: (date, description) => {
      return dispatch(createUserMemo(date, description));
    },
    updateUserMemo: (id, date, description) => {
      return dispatch(updateUserMemo(id, date, description));
    },
    deleteUserMemo: (id) => {
      return dispatch(deleteUserMemo(id));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableMemo);