import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    marginBottom: '0.5em',
    marginTop: '0.5em',
  },
}));

export const Table = (props) => {
  const {
    title,
    data,
    columns,
    options,
    pagination,
    setFilterUserSubject,
    setShowId
  } = props;

  const [filteredCount, setFilteredCount] = useState(0);

  const classes = useStyles();

  const optionsAll = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      if (setFilterUserSubject) {
        setFilterUserSubject(filterList[6]);
      }
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (setShowId && changedColumn === 'id') {
        if (action === 'add') {
          setShowId(true);
        } else setShowId(false);
      }
    },
    filterType: 'checkbox',
    download: true,
    print: false,
    pagination: pagination !== null ? pagination : true,
    rowsPerPage: 10,
    rowsPerPageOptions: [1, 5, 10, 20, 50, 100],
    selectableRows: 'none',
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      setFilteredCount(count);

      return ''
    },
    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
      toolbar: {
        search: 'Rechercher',
        viewColumns: 'Colonnes visibles',
        filterTable: 'Filtres',
      },
      filter: {
        all: 'Tous',
        title: 'Filtres',
        reset: 'Effacer tous les filtres',
      },
      viewColumns: {
        title: 'Colonnes visibles',
        titleAria: 'Montrer/Cacher les colonnes',
      },
    },

    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableHeadCell: {
          toolButton: {
            fontWeight: 'bold',
          },
          root: {
            fontWeight: 'bold',
          },
        },
      },
      palette: {
        primary: {
          main: '#213759',
        },
        secondary: {
          main: '#F8CC03',
        },
      },
    });

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className={classes.table}
        title={`${title} (${filteredCount})`}
        data={data}
        columns={columns}
        options={{...optionsAll, ...options}}
      />
    </MuiThemeProvider>
  );
};
