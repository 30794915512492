import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Auth from './../../services/Auth';

const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: '100%',
  },
}));

export const ActivityGroupSelect = (props) => {
  const classes = useStyles();

  const { board_user_groups } = Auth.basics();

  const { 
    activityGroupId, 
    handleActivityGroupId, 
    disabled, 
    option
  } = props;
 
  return (
    <FormControl variant="outlined" margin="dense" className={classes.selectInput}>
      <InputLabel id="activityGroupSelectLabel">Groupe d'activité</InputLabel>
      {board_user_groups && (
        <Select
          labelId="activityGroupSelectLabel"
          id="activityGroupSelect"
          value={activityGroupId}
          defaultValue=""
          onChange={handleActivityGroupId}
          disabled={disabled}
          label="Type d'activité"
        >
          {option === 'all' && (
            <MenuItem key={0} value={0}>
              Tous types
            </MenuItem>
          )}
          {board_user_groups.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
