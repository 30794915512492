import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableTreatedRequest, TableIngoingRequest } from '../../components/summary/TablesSummary';
import { Stats } from '../../components/summary/Stats';
import { UserSelect } from '../../components/globalComponents/UserSelect';
import { Divider, TextField, IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import dayjs from 'dayjs';
import BasicsContext from '../../context/BasicsContext';
import { connect } from 'react-redux';
import api from '../../api/api';

import {
  getSummaryProcessedInquiries
} from './../../actions/summary';

import {
  getDirectInquiries
} from './../../actions/inquiries';

// ------------------------------------------------------------ //
//        STYLES
// ------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  column: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  rowAboveTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    marginLeft: '1em',
    marginRight: '1em',
  },
}));

// ------------------------------------------------------------ //
//        COMPONENT
// ------------------------------------------------------------ //
const TabSummary = (props) => {
  
  const classes = useStyles();
  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [userId, setUserId] = useState(user.id);

  const [switchmanId, setSwitchmanId] = useState(null);
  const [teacherId, setTeacherId] = useState(null);

  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [reload, setReload] = useState(false);

  const {
    processed,
    direct
  } = props;

  let directInquiries = [];

  direct.waitingSwitchman.map((inquiry) => {
    if(inquiry.teacher_id === teacherId || inquiry.switchman_id === switchmanId) {
      directInquiries.push(inquiry);
    }

    return inquiry;
  });

  direct.withSwitchman.map((inquiry) => {
    if(inquiry.teacher_id === teacherId || inquiry.switchman_id === switchmanId) {
      directInquiries.push(inquiry);
    }

    return inquiry;
  });

  direct.waitingTeacher.map((inquiry) => {
    if(inquiry.teacher_id === teacherId || inquiry.switchman_id === switchmanId) {
      directInquiries.push(inquiry);
    }

    return inquiry;
  });

  direct.withTeacher.map((inquiry) => {
    if(inquiry.teacher_id === teacherId || inquiry.switchman_id === switchmanId) {
      directInquiries.push(inquiry);
    }

    return inquiry;
  });

  useEffect(() => {
      const from = dayjs(date, "YYYY-MM-DD").unix();

      api.getUserInfo(userId)
        .then(function(response) {
          const {role, user} = response;

          if(role === "teacher") {
            setTeacherId(user.switchman_id);
          }

          if(role === "switchman") {
            setSwitchmanId(user.switchman_id);
          }

          props.getSummaryProcessedInquiries(from, null, userId);
          props.getDirectInquiries();
        });

  }, [userId, date, reload]);

  return (
    <div className={classes.row}>
      <div className={classes.column}>

        <div className={classes.rowAboveTable}>
          <TextField
            id="date"
            label="Date à afficher"
            type="date"
            margin="dense"
            variant="outlined"
            value={date}
            onChange={(event) => {
              setDate(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <UserSelect userId={userId} setUserId={setUserId} />
          
          <Tooltip title="Rafraichir">
            <IconButton aria-label="refresh" onClick={() => {
                setReload(!reload);
              }}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>

        <TableTreatedRequest date={date} userId={userId} data={processed} />
        
        <TableIngoingRequest date={date} userId={userId} data={directInquiries} />
        
        {' '}
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      
      <div className={classes.column}>
        <Stats date={date} reload={reload} setReload={setReload} user={user} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ 
  processed: state.summary.processed,
  direct: state.inquiries.direct,
});

const mapDispatchToProps = dispatch => {
  return {
    getSummaryProcessedInquiries: (from, to, userId) => {
      return dispatch(getSummaryProcessedInquiries(from, to, userId));
    },
    getDirectInquiries: () => {
      return dispatch(getDirectInquiries());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabSummary);