export class Inquiry {

  // WAITING_SWITCHMAN
  // WITH_SWITCHMAN
  // WAITING_TEACHER
  // WITH_TEACHER

  constructor(attributes) {
    this.attributes = attributes;
  }

  getId() {
    return this.attributes.id;
  }

  get() {
    return this.attributes;
  }

  getStatusType() {

    if(this.getSwitchmanId() === 0) {
      return 'WAITING_SWITCHMAN';
    }

    if(this.getSwitchmanId() !== 0 && (this.getStatusId() === 4 || this.getStatusId() === 6)) {
      return 'WITH_SWITCHMAN';
    }

    if(this.getTeacherId() === 0 && this.getStatusId() === 7) {
      return 'WAITING_TEACHER';
    }

    if(this.getTeacherId() !== 0 && this.getStatusId() !== 12) {
      return 'WITH_TEACHER';
    }

    if(this.getTeacherId() !== 0 && this.getStatusId() === 12) {
      return 'SUCCESS';
    }

    // if(this.getStatusId() === 7) {
    //   if(this.getTeacherId() !== 0) {
    //     return 'WITH_TEACHER';
    //   } else {
    //     return 'WAITING_TEACHER';
    //   }
    // }

    // if(this.getStatusId() === 4 || this.getStatusId() === 6)  {
    //   if(this.getSwitchmanId() !== 0) {
    //     return 'WITH_SWITCHMAN';
    //   } else {
    //     return 'WAITING_SWITCHMAN';
    //   }
    // }
  }

  getTeacherId() {
    return this.attributes.teacher_id;
  }

  getSwitchmanId() {
    return this.attributes.switchman_id;
  }

  getStatusId() {
    return this.attributes.status_id;
  }
}

