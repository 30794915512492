import React, { useState, useEffect, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import api from '../../api/api';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import { Loading } from '../globalComponents/Loading';

export const WeekInvitationTable = (props) => {
  const { date, subjectId, setDate, levelId, workzoneId, reload } = props;
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const availablePrincipal = '#C8DF52';
  const availableNotPrincipal = '#E1C340';
  const unavailable = '#ff4e47';

  const [sam, setSam] = useState(0);
  const [dim, setDim] = useState(0);
  const [lun, setLun] = useState(0);
  const [mar, setMar] = useState(0);
  const [mer, setMer] = useState(0);
  const [jeu, setJeu] = useState(0);
  const [ven, setVen] = useState(0);

  const columns = [
    {
      name: 'user_id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'teacher_name',
      label: 'Enseignants',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'day_1',
      label: 'sam. ' + dayjs(date).weekday(0).format('DD/MM ') + '(' + sam + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_1 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_1 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_1;
        },
      },
    },
    {
      name: 'day_2',
      label: 'dim. ' + dayjs(date).weekday(1).format('DD/MM ') + '(' + dim + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_2 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_2 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_2;
        },
      },
    },
    {
      name: 'day_3',
      label: 'lun. ' + dayjs(date).weekday(2).format('DD/MM ') + '(' + lun + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_3 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_3 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_3;
        },
      },
    },
    {
      name: 'day_4',
      label: 'mar. ' + dayjs(date).weekday(3).format('DD/MM ') + '(' + mar + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_4 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_4 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_4;
        },
      },
    },
    {
      name: 'day_5',
      label: 'mer. ' + dayjs(date).weekday(4).format('DD/MM ') + '(' + mer + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_5 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_5 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_5;
        },
      },
    },
    {
      name: 'day_6',
      label: 'jeu. ' + dayjs(date).weekday(5).format('DD/MM ') + '(' + jeu + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_6 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_6 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_6;
        },
      },
    },
    {
      name: 'day_7',
      label: 'ven. ' + dayjs(date).weekday(6).format('DD/MM ') + '(' + ven + ')',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_available_day_7 && data[rowIndex].is_main_subject) {
            cellColor = availablePrincipal;
          } else if (data[rowIndex].is_available_day_7 && !data[rowIndex].is_main_subject) {
            cellColor = availableNotPrincipal;
          } else {
            cellColor = unavailable;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid grey',
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.tableData[tableMeta.rowIndex].is_invited_day_7;
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    pagination: true,
    search: false,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [1, 5, 10, 20, 50],
    selectableRows: 'none',

    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex > 1) {
        const num = cellMeta.colIndex - 2;
        setDate(dayjs(date).weekday(num));
      }
    },

    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
    },
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  useEffect(() => {
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const getWeekInvitation = async () => {
      try {
        setIsLoading(true);
        let startDate = dayjs(date).weekday(0).format('YYYY/MM/DD');
        const response = await api.getWeekInvitation(
          dayjs.tz(startDate, 'Europe/Paris').hour(0).minute(0).second(0).unix(),
          subjectId,
          levelId,
          workzoneId
        );
        if (response) {
          let sa = 0,
            di = 0,
            lu = 0,
            ma = 0,
            me = 0,
            je = 0,
            ve = 0;

          if (response !== []) {
            response.map((teacher) => {
              if (teacher.is_invited_day_1.length !== 0) {
                sa++;
              }
              if (teacher.is_invited_day_2.length !== 0) {
                di++;
              }
              if (teacher.is_invited_day_3.length !== 0) {
                lu++;
              }
              if (teacher.is_invited_day_4.length !== 0) {
                ma++;
              }
              if (teacher.is_invited_day_5.length !== 0) {
                me++;
              }
              if (teacher.is_invited_day_6.length !== 0) {
                je++;
              }
              if (teacher.is_invited_day_7.length !== 0) {
                ve++;
              }
              teacher.teacher_name = capitalize(teacher.first_name) + ' ' + capitalize(teacher.last_name);

              return teacher;
            });
          }

          setSam(sa);
          setDim(di);
          setLun(lu);
          setMar(ma);
          setMer(me);
          setJeu(je);
          setVen(ve);

          setData(response);
          setIsLoading(false);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getWeekInvitation();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    if (date && subjectId) {
      getWeekInvitation();
    }
  }, [refresh, date, subjectId, levelId, workzoneId, reload]);

  return (
    <>
      {data && !isLoading ? (
        <>
          <ErrorAPI
            errorAPI={errorAPI}
            setErrorAPI={setErrorAPI}
            message={
              'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
            }
          />
          <Table data={data} columns={columns} options={options} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
