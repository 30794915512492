
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
  inputDescription: {
    marginRight: '2em',
    flexGrow: 3,
  },
  inputDate: {
    marginRight: '2em',
    flexGrow: 0.5,
  },
}));

export default useStyles;