import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import Auth from './../../services/Auth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
  },
}));

export const UserSelect = (props) => {
  const { userId, setUserId } = props;
  const [users, setUsers] = useState();
  const currentUser = Auth.user();
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const classes = useStyles();

  useEffect(() => {
    const getUsers = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getUsers();
        if (response) {
          setUsers(response);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getUsers();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getUsers();
  }, [refresh]);

  const handleChange = (event) => {
    setUserId(event.target.value);
  };

  return (
    <>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          'Un problème est survenu lors du chargement des utilisateurs, veuillez recharger la page ou réessayer plus tard.'
        }
      />
      {users && users.length > 1 ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="userSelectLabel">Utilisateur</InputLabel>
          <Select
            labelId="userSelectLabel"
            id="rateSelect"
            margin="dense"
            value={userId}
            onChange={handleChange}
            label="Utilisateur"
          >
            {users.map((option) => (
              <MenuItem key={option.user_id} value={option.user_id}>
                {option.first_name} {option.last_name} /{' '} 
                { option.role.includes("teacher") ? 'Enseignant': option.role.includes("switchman") ? 'Aiguilleur' : 'Coordo' }
                { currentUser.user.id === option.user_id ? ' <-- (Moi) ' : '' }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : ''}
    </>
  );
};
