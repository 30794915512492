import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { TableProcessed } from '../../components/processed/TableProcessed';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import {
  getProcessedInquiries
} from './../../actions/inquiries';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  input: {
    width: 200,
  },
}));

const TabProcessed = (props) => {
  const classes = useStyles();
  const [date, setDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));

  const {
    inquiries
  } = props;

  return (
    <div className={classes.column}>
      <div className={classes.row}>
        <TextField
          type="date"
          id="dateFrom"
          margin="dense"
          value={date}
          variant="outlined"
          onChange={(event) => {
            const timestampFrom = dayjs(event.target.value, "YYYY-MM-DD").unix();
            setDate(event.target.value);
            props.getProcessedInquiries(timestampFrom);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          label="Requêtes depuis le"
          className={classes.input}
        />
      </div>
      <TableProcessed data={inquiries} date={date} />
    </div>
  );
};

const mapStateToProps = (state) => ({ 
  inquiries: state.inquiries.processed,
});

const mapDispatchToProps = dispatch => {
  return {
    getProcessedInquiries: (timestampFrom) => {
      return dispatch(getProcessedInquiries(timestampFrom));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabProcessed);
