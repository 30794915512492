import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Avatar,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TDBDrawer from './Drawer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { TimzezoneHourSelect } from './TimezoneHourSelect';
import { LogoutModal } from './LogoutModal';
import logoPE from '../../assets/logoPE.png';
import Auth from './../../services/Auth';

// ------------------------------------------------------------------------ //
// REDUX
// ------------------------------------------------------------------------ //
import {
  getActiveUsers
} from './../../actions/users';

// ------------------------------------------------------------------------ //
// STYLES
// ------------------------------------------------------------------------ //
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatarActive: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  avatarInactive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
  },
  paperMenu: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nestedMenuItem: {
    paddingLeft: theme.spacing(4),
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  logo: { height: '64px' },
  space: { flexGrow: 1 },
}));

// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
const TDBToolbar = (props) => {

  const {
    openDrawer,
    handleCloseDrawer,
    handleOpenDrawer,
    setSettings,
    activeHourSelected,
    setActiveHourSelected
  } = props;

  const user = Auth.user() ? Auth.user().user : null;

  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [isSignOut, setIsSignOut] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const manualLink = 'https://docs.google.com/document/d/1RXqPD0mtfmvrxXyZKnLl1zTUFom7qrT7WVk2BZXPb1I/edit#heading=h.6h6nipsoe2s';
  const profExpressLink = 'https://www.profexpress.com/';

  const anchorRef = useRef(null);

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  if(isSignOut) {
    return <Redirect push to={`/`}></Redirect>;
  }


  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}
      >
        <Toolbar color="primary">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpenDrawer}
            edge="start"
            className={clsx(classes.menuButton, openDrawer && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <img src={logoPE} alt="Logo" className={classes.logo} />
          <div className={classes.space} />

          <ScheduleIcon fontSize="large" />
          <TimzezoneHourSelect activeHourSelected={activeHourSelected} setActiveHourSelected={setActiveHourSelected} />

          {user && (
            <Tooltip title={user.first_name + ' ' + user.last_name + ' - ' + user.role}>
              <Avatar
                //className={activeUser ? classes.avatarActive : classes.avatarInactive}
                className={classes.avatarInactive}
                ref={anchorRef}
                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setOpenMenu((prevOpenMenu) => !prevOpenMenu);
                }}
              >
                {user.first_name.charAt(0)}
                {user.last_name.charAt(0)}
              </Avatar>
            </Tooltip>
          )}

          <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper elevation={3} className={classes.paperMenu}>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <List>
                      <ListItem
                          button
                          onClick={(event) => {
                            setSettings(true);
                            handleCloseMenu(event);
                          }}
                          className={classes.menuItem}
                        >
                        <ListItemIcon>
                          <SettingsIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Paramètres" />
                      </ListItem>

                      <ListItem
                          button
                          onClick={() => {
                            setOpenLink(!openLink);
                          }}
                          className={classes.menuItem}
                        >
                        <ListItemIcon>
                          <LinkIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Liens Utiles" />
                        {openLink ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openLink} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            onClick={() => window.open(profExpressLink, '_blank')}
                            className={classes.nestedMenuItem}
                          >
                            <ListItemIcon>
                              <ArrowRightAltIcon style={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Prof Express" />
                          </ListItem>

                          <ListItem
                            button
                            onClick={() => window.open(manualLink, '_blank')}
                            className={classes.nestedMenuItem}
                          >
                            <ListItemIcon>
                              <ArrowRightAltIcon style={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Manuel TDB" />
                          </ListItem>
                        </List>
                      </Collapse>
                      <ListItem
                        button
                        onClick={() => {
                          setOpenLogoutModal(true);
                        }}
                        className={classes.menuItem}
                        >
                        <ListItemIcon>
                          <ExitToAppIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Se déconnecter" />
                      </ListItem>
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6">Utilisateurs</Typography>
          <IconButton onClick={handleCloseDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <TDBDrawer />
      </Drawer>

      <LogoutModal
        openModal={openLogoutModal}
        handleCloseModal={() => {
          setOpenLogoutModal(false);
        }}
        logout={() => {
          Auth.logout();
          setIsSignOut(true);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeUsers: state.users.activeUsers
});

const mapDispatchToProps = dispatch => {
  return {
    getActiveUsers: () => dispatch(getActiveUsers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TDBToolbar);
