import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import dayjs from 'dayjs';
import Auth from "../../services/Auth";

// ------------------------------------------------------------------------ //
// STYLE
// ------------------------------------------------------------------------ //
const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  nestedMenuItem: {
    paddingLeft: theme.spacing(4),
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  userAvailable: {
    color: '#759824',
  },
  userUnavailable: {
    color: '#B73214',
  },
  paddingY3: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
  },
  paddingL15: {
    paddingLeft: '15px !important',
  }
}));

// ------------------------------------------------------------------------ //
// COMPONENT
// ------------------------------------------------------------------------ //
export const GroupUserList = (props) => {
  const {
    activeUsers,
    onUserClick
  } = props;
  const currentUser = Auth.user();
  const classes = useStyles();
  const [open, setOpen] = useState({});

  const handleClick = (i) => {
    setOpen(prevOpen => ({...prevOpen, [i]: !prevOpen[i]}));
  };

  const handleSubItemClick = (i, j) => {
    setOpen(prevOpen => ({...prevOpen, [`${i}_${j}`]: !prevOpen[`${i}_${j}`]}));
  };

  const displayUsers = (users) => users.map((user) => {
    return <ListItem
      key={'user_' + user.user_id}
      button
      onClick={() => {
        onUserClick(user.user_id)
      }}
      className={classes.nestedMenuItem}
    >
      <ListItemText
        primary={user.first_name + ' ' + user.last_name + ' ' + (user.available !== 1 ? ' - ' + dayjs(user.unavailable_ended_at).format('HH:mm') : '') + (currentUser.user.id === user.user_id ? ' <-- (Moi) ' : '')}
        className={user.available === 1 ? classes.userAvailable : classes.userUnavailable}
      />
    </ListItem>
  });

  const countUserType = (type) => {
    let counter = 0

    type.groups.forEach((group) => {
      counter += group.users.length
    })

    return counter
  }

  return (
    <List>
      {activeUsers.map((item, i) => {
        if(item.id != 0) return (
          <div key={i}>
            <ListItem className={classes.paddingY3} button onClick={() => handleClick(i)}>
              <ListItemText primary={item.name + " (" + countUserType(item) + ")"} />
              {open[i] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[i]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.groups.map((subItem, j) => {
                  if(subItem.users !== undefined && subItem.users.length)
                    return (
                      <div key={j}>
                        <ListItem className={classes.paddingY3} button onClick={() => handleSubItemClick(i, j)}>
                          <ListItemText className={classes.paddingL15} inset primary={subItem.name + " (" + subItem.users.length + ")"} />
                          {open[`${i}_${j}`] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open[`${i}_${j}`]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {subItem.users !== undefined ? displayUsers(subItem.users) : null}
                          </List>
                        </Collapse>
                      </div>
                    )})}
              </List>
            </Collapse>
          </div>
        )
        else
          return (
            <div key={i}>
              <ListItem className={classes.paddingY3} button onClick={() => handleClick(i)}>
                <ListItemText primary={item.name + " (" + item.users.length + ")"} />
                {open[i] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open[i]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.users.length ? displayUsers(item.users) : null}
                </List>
              </Collapse>
            </div>
          )
      })}
    </List>
  );
}
