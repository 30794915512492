import React, { useState, useEffect, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import api from '../../api/api';
import dayjs from 'dayjs';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Loading } from '../globalComponents/Loading';

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const AvailableTeacherTable = (props) => {
  const classes = useStyles();
  const {
    date,
    subjectId,
    selectedSlot,
    levelId,
    workzoneId,
    reload,
    setReload,
    subjects,
    levels,
    workzones,
    setUserId,
    setReloadEvents,
    reloadEvents,
  } = props;
  const refresh = useContext(refreshFunctionContext);
  const [errorAPI, setErrorAPI] = useState(false);
  const [data, setData] = useState();
  const [user, setUser] = useState();
  const [alertUserMessage, setAlertUserMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const green = '#C8DF52';
  const lightGreen = '#b5c94b';
  const mediumDarkGreen = '#c9c536';
  const orangeGreen = '#c9ac36';
  const lightOrange = '#c99636';
  const orange = '#c98736';
  const darkOrange = '#d67520';
  const orangeRed = '#e36110';
  const red = '#ff4e47';

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'user_id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'teacher_info',
      label: 'Enseignants',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { paddingLeft: '3px' } }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="body1"
            onClick={() => {
              handleUser(tableMeta);
            }}
          >
            {value}
          </Typography>
        ),
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
      },
    },
    {
      name: 'is_main_subject',
      label: 'Princi.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].is_main_subject === true) {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'reserved',
      label: 'Reserv.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (data[rowIndex].reserved === false) {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'active_hour_bloc',
      label: 'Activité',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value <= 0) {
            cellColor = green;
          } else if (value > 0 && value <= 3) {
            cellColor = lightGreen;
          } else if (value > 3 && value <= 6) {
            cellColor = mediumDarkGreen;
          } else if (value > 6 && value <= 9) {
            cellColor = orangeGreen;
          } else if (value > 9 && value <= 12) {
            cellColor = lightOrange;
          } else if (value > 12 && value <= 15) {
            cellColor = orange;
          } else if (value > 15 && value <= 18) {
            cellColor = darkOrange;
          } else if (value > 18 && value <= 21) {
            cellColor = orangeRed;
          } else if (value > 21) {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
    {
      name: 'available',
      label: 'Dispo.',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value === '100%') {
            cellColor = green;
          } else if (value === '0%') {
            cellColor = red;
          } else {
            cellColor = orange;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
      },
    },
    {
      name: 'reinforcement',
      label: 'Renfort',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: (value, rowIndex, columnIndex) => {
          let cellColor;
          if (value.type.type.render.displayName === 'CheckIcon') {
            cellColor = green;
          } else {
            cellColor = red;
          }
          return {
            style: {
              backgroundColor: cellColor,
              border: '1px solid' + cellColor,
            },
            align: 'center',
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => (value ? <CheckIcon /> : <ClearIcon />),
        filterOptions: {
          renderValue: (v) => (v ? 'oui' : 'non'),
        },
      },
    },
    {
      name: 'invite',
      label: 'Inviter',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({ style: { paddingLeft: '3px' } }),
        setCellProps: () => ({ align: 'center' }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={tableMeta.rowData[6] === '0%'}
              onClick={() => {
                handleInvite(tableMeta);
              }}
            >
              <PersonAddIcon />
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    pagination: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [1, 5, 10, 20, 50],
    selectableRows: 'none',

    textLabels: {
      body: {
        noMatch: 'Aucunes données disponibles',
        toolTip: 'Trier',
        columnHeaderTooltip: (column) => `Trier par ${column.label}`,
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
        rowsPerPage: 'Rangs par page:',
        displayRows: 'sur',
      },
    },
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
  };

  useEffect(() => {
    const getAvailableTeacher = async () => {
      setErrorAPI(false);
      try {
        setIsLoading(true);
        const slotDate = dayjs(date).format('YYYY-MM-DD');
        const start = dayjs
          .tz(slotDate, 'Europe/Paris')
          .hour(dayjs(selectedSlot.start).hour())
          .minute(dayjs(selectedSlot.start).minute())
          .second(dayjs(selectedSlot.start).second())
          .unix();
        const end = dayjs
          .tz(slotDate, 'Europe/Paris')
          .hour(dayjs(selectedSlot.end).hour())
          .minute(dayjs(selectedSlot.end).minute())
          .second(dayjs(selectedSlot.end).second())
          .unix();
        const response = await api.getAvailableTeacher({
          start: start,
          end: end,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
        });
        if (response) {
          let teachersAvailable;
          teachersAvailable = response.map((teacherAvailable) => {
            teacherAvailable.teacher_info =
              capitalize(teacherAvailable.first_name) + ' ' + capitalize(teacherAvailable.last_name);
            teacherAvailable.reserved = teacherAvailable.status_id === 2 ? true : false;
            teacherAvailable.available = Math.floor(teacherAvailable.availability) + '%';
            teacherAvailable.active_hour_bloc = (teacherAvailable.active_hours / 3600 / 3).toFixed(2);

            return teacherAvailable;
          });

          setData(teachersAvailable);
          setIsLoading(false);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getAvailableTeacher();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    getAvailableTeacher();
  }, [refresh, date, subjectId, levelId, workzoneId, reload, selectedSlot]);

  const handleInvite = async (tableMeta) => {
    let subject, level, workzone;
    const slotDate = dayjs(date).format('YYYY-MM-DD');
    const startSlot = dayjs
      .tz(slotDate, 'Europe/Paris')
      .hour(dayjs(selectedSlot.start).hour())
      .minute(dayjs(selectedSlot.start).minute())
      .second(dayjs(selectedSlot.start).second())
      .unix();
    const endSlot = dayjs
      .tz(slotDate, 'Europe/Paris')
      .hour(dayjs(selectedSlot.end).hour())
      .minute(dayjs(selectedSlot.end).minute())
      .second(dayjs(selectedSlot.end).second())
      .unix();

    const startEvent = dayjs
      .tz(slotDate, 'Europe/Paris')
      .hour(dayjs(data[tableMeta.rowIndex].started_at).hour())
      .minute(dayjs(data[tableMeta.rowIndex].started_at).minute())
      .second(dayjs(data[tableMeta.rowIndex].started_at).second())
      .unix();

    const endEvent = dayjs
      .tz(slotDate, 'Europe/Paris')
      .hour(dayjs(data[tableMeta.rowIndex].ended_at).hour())
      .minute(dayjs(data[tableMeta.rowIndex].ended_at).minute())
      .second(dayjs(data[tableMeta.rowIndex].ended_at).second())
      .unix();

    if (subjectId !== 0) {
      subject = subjects.find((x) => x.id === subjectId).name;
    } else {
      subject = '';
    }

    if (levelId !== 0) {
      level = '/' + levels.find((x) => x.id === levelId).name;
    } else {
      level = '';
    }

    if (workzoneId !== 0) {
      workzone = '/' + workzones.find((x) => x.id === workzoneId).display_name;
    } else {
      workzone = '';
    }
    const title = subject + level + workzone;

    //si dispo commence avant créneau et fini dedans -> créer dispo de start event à start créneau + put event start = start créneau + invit
    if (startEvent - startSlot < 0 && endEvent - endSlot <= 0) {
      console.log('début avant - fin dedans');
      try {
        const response = await api.putEvent({
          id: data[tableMeta.rowIndex].id,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
          title: title,
          startDate: startSlot,
        });
        if (response) {
          try {
            const response2 = await api.postEvent(
              data[tableMeta.rowIndex].user_id,
              startEvent,
              startSlot,
              data[tableMeta.rowIndex].status_id,
              data[tableMeta.rowIndex].reinforcement
            );
            if (response2) {
              setReload(!reload);
              setReloadEvents(!reloadEvents);
            } else {
              setErrorAPI(true);
            }
          } catch (error) {
            if (
              error.response !== undefined &&
              error.response.status === 401 &&
              error.response.data.message === 'Unauthenticated.'
            ) {
              await refresh();
              handleInvite(tableMeta);
            } else {
              console.log(error);
              setErrorAPI(true);
            }
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          handleInvite(tableMeta);
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    }
    //si dispo commence avant créneau et fini après
    else if (startEvent - startSlot < 0 && endEvent - endSlot > 0) {
      console.log('début avant - fin après');
      try {
        const response = await api.putEvent({
          id: data[tableMeta.rowIndex].id,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
          title: title,
          startDate: startSlot,
          endDate: endSlot,
        });
        if (response) {
          try {
            const response2 = await api.postEvent(
              data[tableMeta.rowIndex].user_id,
              startEvent,
              startSlot,
              data[tableMeta.rowIndex].status_id,
              data[tableMeta.rowIndex].reinforcement
            );
            if (response2) {
              try {
                const response3 = await api.postEvent(
                  data[tableMeta.rowIndex].user_id,
                  endSlot,
                  endEvent,
                  data[tableMeta.rowIndex].status_id,
                  data[tableMeta.rowIndex].reinforcement
                );
                if (response3) {
                  setReload(!reload);
                  setReloadEvents(!reloadEvents);
                } else {
                  setErrorAPI(true);
                }
              } catch (error) {
                if (
                  error.response !== undefined &&
                  error.response.status === 401 &&
                  error.response.data.message === 'Unauthenticated.'
                ) {
                  await refresh();
                  handleInvite(tableMeta);
                } else {
                  console.log(error);
                  setErrorAPI(true);
                }
              }
            } else {
              setErrorAPI(true);
            }
          } catch (error) {
            if (
              error.response !== undefined &&
              error.response.status === 401 &&
              error.response.data.message === 'Unauthenticated.'
            ) {
              await refresh();
              handleInvite(tableMeta);
            } else {
              console.log(error);
              setErrorAPI(true);
            }
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          handleInvite(tableMeta);
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    }
    //si dispo commence dans créneau et fini avant
    else if (startEvent - startSlot >= 0 && endEvent - endSlot <= 0) {
      console.log('début dedans - fin dedans');
      try {
        const response = await api.putEvent({
          id: data[tableMeta.rowIndex].id,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
          title: title,
        });
        if (response) {
          setReload(!reload);
          setReloadEvents(!reloadEvents);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          handleInvite(tableMeta);
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    }
    // si dispo commence dans créneau et fini après
    else if (startEvent - startSlot >= 0 && endEvent - endSlot > 0) {
      console.log('début dedans - fin après');
      try {
        const response = await api.putEvent({
          id: data[tableMeta.rowIndex].id,
          subjectId: subjectId,
          levelId: levelId,
          workzoneId: workzoneId,
          title: title,
          endDate: endSlot,
        });
        if (response) {
          try {
            const response2 = await api.postEvent(
              data[tableMeta.rowIndex].user_id,
              endSlot,
              endEvent,
              data[tableMeta.rowIndex].status_id,
              data[tableMeta.rowIndex].reinforcement
            );
            if (response2) {
              setReload(!reload);
              setReloadEvents(!reloadEvents);
            } else {
              setErrorAPI(true);
            }
          } catch (error) {
            if (
              error.response !== undefined &&
              error.response.status === 401 &&
              error.response.data.message === 'Unauthenticated.'
            ) {
              await refresh();
              handleInvite(tableMeta);
            } else {
              console.log(error);
              setErrorAPI(true);
            }
          }
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          handleInvite(tableMeta);
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    }
  };

  const handleUser = async (tableMeta) => {
    setAlertUserMessage(false);
    setUserId(tableMeta.rowData[1]);

    try {
      const response = await api.getUserInfo(tableMeta.rowData[1]);
      if (response) {
        setUser(response.user);
        if (response.user.invitation_comment !== null) {
          setAlertUserMessage(true);
        }
      } else {
        setErrorAPI(true);
      }
    } catch (error) {
      if (
        error.response !== undefined &&
        error.response.status === 401 &&
        error.response.data.message === 'Unauthenticated.'
      ) {
        await refresh();
        handleUser();
      } else {
        console.log(error);
        setErrorAPI(true);
      }
    }
  };

  return (
    <>
      {data && !isLoading ? (
        <>
          <ErrorAPI
            errorAPI={errorAPI}
            setErrorAPI={setErrorAPI}
            message={
              'Un problème est survenu lors du chargement des données, veuillez recharger la page ou réessayer plus tard.'
            }
          />
          <Typography variant="h6"> {data.length} enseignants disponibles</Typography>
          {alertUserMessage && (
            <Alert
              onClose={() => {
                setAlertUserMessage(false);
              }}
              severity="info"
            >
              <AlertTitle>
                Message de {user.first_name} {user.last_name}
              </AlertTitle>
              {user.invitation_comment}
            </Alert>
          )}
          <Table data={data} columns={columns} options={options} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
