import React, { useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const StopOutHourModal = (props) => {
  const { openModal, handleCloseModal, handleStopActivity } = props;
  const classes = useStyles();

  useEffect(() => {
    if (openModal) {
      const stopTimeOut = setTimeout(() => {
        handleStopActivity();
      }, 120000);
      return () => clearTimeout(stopTimeOut);
    }
  }, [openModal, handleStopActivity]);

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography>
          <Typography variant="h6" className={classes.title}>
            Arreter une activité hors horaire
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {/*Vous êtes dorénavant hors créneau d'ouverture du tableau de bord Prof Express. */}
            Voulez-vous continuer ou arrêter votre activité ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Continuer
          </Button>
          <Button onClick={handleStopActivity} color="primary">
            Arrêter l'activité
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
