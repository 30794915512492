import React, { useState, useContext } from 'react';
import { Table } from '../globalComponents/Table';
import dayjs from 'dayjs';
import { Loading } from '../globalComponents/Loading';
import BasicsContext from '../../context/BasicsContext';
import { SubjectTag } from '../globalComponents/SubjectTag';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const TableWaitingSwitchmanRequest = (props) => {
  const { waitingSwitchmanInquiries } = props;

  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [filterUserSubject, setFilterUserSubject] = useState(
    user.role.includes('teacher')
      ? user.subjects.map((subject) => subject.name)
      : null
  );

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: 'submitted_at',
      label: 'Soumis le',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        filterList: filterUserSubject,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <SubjectTag text={value} />,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
    {
      name: 'preferred_call_time',
      label: "Créneau d'appel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'expiration',
      label: 'Ancienneté/Délai',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <>
      {waitingSwitchmanInquiries ? (
        <Table
          title={`Requêtes en attente d'aiguillage`}
          data={waitingSwitchmanInquiries}
          columns={columns}
          pagination={false}
          setFilterUserSubject={setFilterUserSubject}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const TableSwitchmanRequest = (props) => {
  const { switchmanInquiries } = props;

  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const subjects = user.role.includes('teacher') ? user.subjects.map((subject) => subject.name) : null;
  const [filterUserSubject, setFilterUserSubject] = useState(subjects);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: 'submitted_at',
      label: 'Soumis le',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        filterList: filterUserSubject,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <SubjectTag text={value} />,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
    {
      name: 'switchmanInfo',
      label: 'Aiguilleur',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'preferred_call_time',
      label: "Créneau d'appel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'expiration',
      label: 'Ancienneté/Délai',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <>
      {switchmanInquiries ? (
        <Table
          title={`Requêtes en cours d'aiguillage`}
          data={switchmanInquiries}
          columns={columns}
          pagination={false}
          setFilterUserSubject={setFilterUserSubject}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const TableWaitingTeacherRequest = (props) => {
  const { waitingTeacherInquiries } = props;

  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [filterUserSubject, setFilterUserSubject] = useState(
    user.role.includes('teacher') ? user.subjects.map((subject) => subject.name) : null
  );

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: 'submitted_at',
      label: 'Soumis le',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        filterList: filterUserSubject,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <SubjectTag text={value} />,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
    {
      name: 'switchmanInfo',
      label: 'Aiguilleur',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'preferred_call_time',
      label: "Créneau d'appel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'expiration',
      label: 'Ancienneté/Délai',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      {waitingTeacherInquiries ? (
        <Table
          title={`Requêtes en attente de traitement`}
          data={waitingTeacherInquiries}
          columns={columns}
          pagination={false}
          setFilterUserSubject={setFilterUserSubject}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const TableTeacherRequest = (props) => {
  const { teacherInquiries } = props;

  const basics = useContext(BasicsContext);
  const user = basics ? basics.user : null;
  const [filterUserSubject, setFilterUserSubject] = useState(
    user.role.includes('teacher') ? user.subjects.map((subject) => subject.name) : null
  );

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: 'submitted_at',
      label: 'Soumis le',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => dayjs(value).format('DD/MM/YYYY HH:mm'),
      },
    },
    {
      name: 'level_name',
      label: 'Niveau',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'level_group',
      label: 'Niveau',
      options: {
        filter: true,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'studentInfo',
      label: 'Élève',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subject',
      label: 'Matière',
      options: {
        filter: true,
        filterList: filterUserSubject,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => <SubjectTag text={value} />,
      },
    },
    {
      name: 'special',
      label: 'Special',
      options: {
        filter: true,
        sort: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (value) => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          },
        },
        filterOptions: {
          renderValue: value => {
            if (value === 0) return '-';
            if (value === 1) return "Documentaliste";
            if (value === 2) return "Grand Oral";
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1)
            return <SubjectTag text="Documentaliste" />

          if (value === 2)
            return <SubjectTag text="Grand Oral" />

          return "-";
        }
      },
    },
    {
      name: 'switchmanInfo',
      label: 'Aiguilleur',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'teacherInfo',
      label: 'Enseignant',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'preferred_call_time',
      label: "Créneau d'appel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'expiration',
      label: 'Ancienneté/Délai',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      {teacherInquiries ? (
        <Table
          title={`Requêtes en cours de traitement`}
          data={teacherInquiries}
          columns={columns}
          pagination={false}
          setFilterUserSubject={setFilterUserSubject}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
