import React, { useEffect, useState, useContext } from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import api from '../../api/api';
import refreshFunctionContext from '../../context/refreshFunctionContext';
import BasicsContext from '../../context/BasicsContext';
import { ErrorAPI } from '../globalComponents/ErrorAPI';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: '1em',
    marginRight: '2em',
  },
  icon: {
    marginRight: '0.5em',
    verticalAlign: 'middle',
  },
  hourLocal: {
    verticalAlign: 'middle',
    paddingRight: '24px',
  },
  hour: {
    verticalAlign: 'middle',
  },
  selectInactiveItem: {
    color: theme.palette.common.white,
    fontSize: '16px',
  },
  selectActiveItem: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
  },
  iconSelect: {
    fill: theme.palette.common.white,
  },
}));

export const TimzezoneHourSelect = (props) => {
  const { activeHourSelected, setActiveHourSelected } = props;
  const [parisHour, setParisHour] = useState(dayjs().tz('Europe/Paris').format('DD/MM/YYYY HH:mm'));
  const [localHour, setLocalHour] = useState(dayjs().format('DD/MM/YYYY HH:mm'));
  const [guyaneHour, setGuyaneHour] = useState(dayjs().tz('America/Cayenne').format('DD/MM/YYYY HH:mm'));
  const [antilleHour, setAntilleHour] = useState(dayjs().tz('America/Martinique').format('DD/MM/YYYY HH:mm'));
  const [reunionHour, setReunionHour] = useState(dayjs().tz('Indian/Reunion').format('DD/MM/YYYY HH:mm'));
  const [mayotteHour, setMayotteHour] = useState(dayjs().tz('Indian/Mayotte').format('DD/MM/YYYY HH:mm'));
  const [errorAPI, setErrorAPI] = useState(false);
  const refresh = useContext(refreshFunctionContext);
  const basics = useContext(BasicsContext);
  const classes = useStyles();
  const [openSlots, setOpenSlots] = useState();

  const handleChange = (event) => {
    if (openSlots) {
      let i = 0;
      let start;
      let end;
      let isInOpen = false;
      let now = dayjs().tz('Europe/Paris').format('YYYY-MM-DD HH:mm');

      while (i < openSlots.length && isInOpen === false) {
        start = dayjs(openSlots[i].start).unix();
        end = dayjs(openSlots[i].end).unix();

        if (dayjs(now).unix() - start >= 0 && dayjs(now).unix() - end <= 0) {
          isInOpen = true;
        }
        i++;
      }

      if (isInOpen === true) {
        setActiveHourSelected(true);
      } else {
        setActiveHourSelected(false);
      }
    }
  };

  useEffect(() => {
    const getOpenSlots = async () => {
      setErrorAPI(false);
      try {
        const response = await api.getOpenSlots({
          startDate: dayjs.tz(dayjs().hour(0).minute(0).second(0), 'Europe/Paris').unix(),
          endDate: dayjs.tz(dayjs().hour(23).minute(59).second(59), 'Europe/Paris').unix(),
        });
        if (response) {
          const slots = response.map((slot) => {
            slot.start = dayjs(slot.started_at).toDate();
            slot.end = dayjs(slot.ended_at).toDate();
            return slot;
          });
          setOpenSlots(slots);
        } else {
          setErrorAPI(true);
        }
      } catch (error) {
        if (
          error.response !== undefined &&
          error.response.status === 401 &&
          error.response.data.message === 'Unauthenticated.'
        ) {
          await refresh();
          getOpenSlots();
        } else {
          console.log(error);
          setErrorAPI(true);
        }
      }
    };

    if (basics) {
      getOpenSlots();
    }
  }, [refresh, basics]);

  useEffect(() => {
    const interval = setInterval(() => {
      setParisHour(dayjs().tz('Europe/Paris').format('DD/MM/YYYY HH:mm'));
      setLocalHour(dayjs().format('DD/MM/YYYY HH:mm'));
      setGuyaneHour(dayjs().tz('America/Cayenne').format('DD/MM/YYYY HH:mm'));
      setAntilleHour(dayjs().tz('America/Martinique').format('DD/MM/YYYY HH:mm'));
      setReunionHour(dayjs().tz('Indian/Reunion').format('DD/MM/YYYY HH:mm'));
      setMayotteHour(dayjs().tz('Indian/Mayotte').format('DD/MM/YYYY HH:mm'));

      if (openSlots) {
        let i = 0;
        let start;
        let end;
        let isInOpen = false;
        let now = dayjs().tz('Europe/Paris').format('YYYY-MM-DD HH:mm');

        while (i < openSlots.length && isInOpen === false) {
          start = dayjs(openSlots[i].start).unix();
          end = dayjs(openSlots[i].end).unix();

          if (dayjs(now).unix() - start >= 0 && dayjs(now).unix() - end <= 0) {
            isInOpen = true;
          }
          i++;
        }

        if (isInOpen === true) {
          setActiveHourSelected(true);
        } else {
          setActiveHourSelected(false);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className={classes.column}>
      <ErrorAPI
        errorAPI={errorAPI}
        setErrorAPI={setErrorAPI}
        message={
          "Un problème est survenu lors du chargement des heures d'ouvertures, veuillez recharger la page ou réessayer plus tard. réessayer plus tard."
        }
      />
      <Typography variant="subtitle1" className={classes.hourLocal}>
        Local : {localHour}
      </Typography>

      <Typography variant="subtitle1" className={classes.hour}>
        <Select
          id="timezoneHourSelect"
          defaultValue="Europe/Paris"
          className={activeHourSelected ? classes.selectActiveItem : classes.selectInactiveItem}
          inputProps={{
            classes: {
              icon: classes.iconSelect,
            },
          }}
          onChange={handleChange}
        >
          <MenuItem key={4} value={'America/Martinique'}>
            Antilles : {antilleHour}
          </MenuItem>
          <MenuItem key={5} value={'America/Cayenne'} name={guyaneHour}>
            Guyane : {guyaneHour}
          </MenuItem>
          <MenuItem key={1} value={'Europe/Paris'} name={parisHour}>
            Métropole : {parisHour}
          </MenuItem>
          <MenuItem key={3} value={'Indian/Mayotte'} name={mayotteHour}>
            Mayotte : {mayotteHour}
          </MenuItem>
          <MenuItem key={2} value={'Indian/Reunion'} name={reunionHour}>
            Réunion : {reunionHour}
          </MenuItem>
        </Select>
      </Typography>
    </div>
  );
};
